import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Modal from '../../../components/Modal';

const RobotReserveModalRobot = ({ isOpen, closeModal, onConfirm, openConfirmCancle }) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={isOpen}
      onClose={closeModal}
      cancelText={t('ROBOT_RESERVE_CANCLE')}
      onCancel={() => {
        closeModal();
        openConfirmCancle();
      }}
    >
      <Container>
        <div className="payment-modal-title">
          {t('ROBOT_RESERVE_FIND')}
          <br></br>
          {t('ROBOT_RESERVE_WAIT')}
        </div>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.25rem 0 2.1875rem;
  display: flex;
  flex-direction: column;

  & > .payment-modal-title {
    margin: 0 auto 0.75rem;
    text-align: center;
    line-height: 130%;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 130%;
    color: #000000;
  }

  & > .payment-modal-comment {
    margin: 0 auto;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 140%;
    text-align: center;
    color: #666666;
  }
`;

export default React.memo(RobotReserveModalRobot);
