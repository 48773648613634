import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { customerAction } from './customerSlice';

import * as customerApiLib from '../lib/customerApi';

function* info({ payload }) {
  try {
    const customerInfo = yield call(customerApiLib.info, payload);

    yield put(customerAction.infoSuccess({ customerInfo }));
  } catch (error) {
    yield put(customerAction.infoFailure({ error }));
  }
}

function* update({ payload }) {
  try {
    yield call(customerApiLib.update, payload);

    yield put(customerAction.updateSuccess({ ...payload }));
  } catch (error) {
    yield put(customerAction.updateFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    yield call(customerApiLib.remove, payload);

    yield put(customerAction.removeSuccess({ ...payload }));
  } catch (error) {
    yield put(customerAction.removeFailure({ error }));
  }
}

function* passwordChange({ payload }) {
  try {
    const customerInfo = yield call(customerApiLib.passwordChange, payload);

    yield put(customerAction.passwordChangeSuccess({ customerInfo }));
  } catch (error) {
    switch (error.errCode) {
      case 1000:
        yield put(customerAction.passwordChangeFailure('현재 비밀번호를 확인해 주세요'));
        break;
      default:
        yield put(customerAction.passwordChangeFailure('Customer Error'));
        break;
    }
  }
}

export function* watchInfo() {
  yield takeLatest(customerAction.info, info);
}

export function* watchUpdate() {
  yield takeLatest(customerAction.update, update);
}

export function* watchRemove() {
  yield takeLatest(customerAction.remove, remove);
}

export function* watchPasswordChange() {
  yield takeLatest(customerAction.passwordChange, passwordChange);
}

function* rootSaga() {
  yield all([fork(watchInfo)]);
  yield all([fork(watchUpdate)]);
  yield all([fork(watchRemove)]);
  yield all([fork(watchPasswordChange)]);
}

export default rootSaga;
