import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface PushLogState {
  logInfo: Array[];
  cnt: number;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const authInitialState: PushLogState = {
  logInfo: [],
  cnt: 0,
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  list: (state: AuthState, { payload }: PayloadAction<LoginPayload>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
    state.logInfo = [];
  },
  listSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.logInfo = payload.pushLog;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: AuthState, { payload }: PayloadAction<string>) => {
    state.isLogin = false;
    state.logInfo = {};
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload;
  },
  cnt: (state: AuthState, { payload }: PayloadAction<LoginPayload>) => {
    state.isLoading = true;
    state.actionResult = 'CNT_REQ';
    state.error = '';
    state.cnt = 0;
  },
  cntSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.cnt = payload.pushLog;
    state.isLoading = false;
    state.actionResult = 'CNT_OK';
    state.error = '';
  },
  cntFailure: (state: AuthState, { payload }: PayloadAction<string>) => {
    state.isLogin = false;
    state.cnt = 0;
    state.isLoading = false;
    state.actionResult = 'CNT_ERR';
    state.error = payload;
  },
  update: (state: AuthState, { payload }: PayloadAction<LoginPayload>) => {
    state.isLoading = true;
    state.actionResult = 'UPDATE_REQ';
    state.error = '';
    state.logInfo = [];
  },
  updateSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.logInfo = payload.pushLog;
    state.isLoading = false;
    state.actionResult = 'UPDATE_OK';
    state.error = '';
  },
  updateFailure: (state: AuthState, { payload }: PayloadAction<string>) => {
    state.isLogin = false;
    state.logInfo = {};
    state.isLoading = false;
    state.actionResult = 'UPDATE_ERR';
    state.error = payload;
  },
};

const slice = createSlice({
  name: 'pushLog',
  initialState: authInitialState,
  reducers: reducers,
});

const selectPushLog = createDraftSafeSelector(
  (state: PushLogState) => state.logInfo,
  logInfo => logInfo,
);
const selectPushLogCnt = createDraftSafeSelector(
  (state: PushLogState) => state.cnt,
  cnt => cnt,
);

const selectStatus = createDraftSafeSelector(
  (state: PushLogState) => state.isLogin,
  (state: PushLogState) => state.actionResult,
  (state: PushLogState) => state.isLoading,
  (state: PushLogState) => state.error,
  (state: PushLogState) => state.cnt,
  (isLogin, actionResult, isLoading, error, cnt) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
    cnt,
  }),
);

export const pushLogSelector = {
  logInfo: state => selectPushLog(state[PUSHLOG]),
  cnt: state => selectPushLogCnt(state[PUSHLOG]),
  status: state => selectStatus(state[PUSHLOG]),
};

export const PUSHLOG = slice.name;
export const pushLogReducer = slice.reducer;
export const pushLogAction = slice.actions;
