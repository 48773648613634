import * as apiLib from './api';

export const list = async ({ customerId, shopId }) => {
  try {
    const data = {
      customerId,
      shopId,
    };
    const result = await apiLib.fetchStoreApi('/order-app/survey-log/list', data);
    if (result.resultFlag) {
      return result.customerSurveyLog;
    } else {
      throw Object.assign(new Error('App::Category List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async ({ customerId, updateInfo, tagId }) => {
  try {
    const data = {
      customerId,
      updateInfo,
      tagId,
    };
    const result = await apiLib.fetchStoreApi('/order-app/survey-log/update', data);
    if (result.resultFlag) {
      return result.resultFlag;
    } else {
      throw Object.assign(new Error('App::Category List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
