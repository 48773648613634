const palette = {
  white: '#FFFFFF',
  black: '#000000',
  gray50: '#F6F6F6',
  gray75: '#F5F5F5',
  gray100: '#F3F3F3',
  gray200: '#EEEEEE',
  gray250: '#EBEBEB',
  gray300: '#DDDDDD',
  gray400: '#C8C8C8',
  gray500: '#CCCCCC',
  gray600: '#999999',
  gray700: '#666666',
  gray800: '#333333',
  gray900: '#111111',
  red200: '#FF0000',
  red100: '#FF3317',
  red50: '#FF5A5A',
  pink50: '#FF5689',
  orange50: '#F87904',
  green50: '#22EC42',
  green100: '#51D285',
  blue100: '#00A3FF',
};

export default palette;
