import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import MainHeader from '../common/MainHeader';

import Background from '../background/Background';
// import Alert from '../../components/Alert';

const MainLayout = () => {
  return (
    <Wrap>
      <Background>
        <MainHeader />
        <Outlet />
      </Background>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: inherit;
`;

export default React.memo(MainLayout);
