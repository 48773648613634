import React, { Suspense } from 'react';
import { Navigate, useLocation, useNavigationType, useRoutes } from 'react-router-dom';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import BackgroundLoading from '../components/BackgroundLoading';
import { css } from 'styled-components';

import MainLayout from '../pages/layouts/MainLayout';
import ListLayout from '../pages/layouts/ListLayout';
import DefaultLayout from '../pages/layouts/DefaultLayout';
import GoodsDetailLayout from '../pages/layouts/GoodsDetailLayout';
import ErrorBoundary from '../pages/error/ErrorBoundary';
import PaymentRobot from '../pages/payment/PaymentRobot';
import PaymentRobotComplete from '../pages/payment/PaymentRobotComplete';
import OrderRobotDelivery from '../pages/order/OrderRobotDelivery';
import PushLog from '../pages/customer/PushLog';
import OrderListLayout from "../pages/layouts/OrderListLayout";

const Group = React.lazy(() => import('../pages/auth/Group'));
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Verify = React.lazy(() => import('../pages/auth/Verify'));
const PasswordFind = React.lazy(() => import('../pages/auth/PasswordFind'));
const Signup = React.lazy(() => import('../pages/auth/Signup'));
const Privacy = React.lazy(() => import('../pages/auth/Privacy'));

const Profile = React.lazy(() => import('../pages/customer/Profile'));
const PasswordChange = React.lazy(() => import('../pages/customer/PasswordChange'));
const Signout = React.lazy(() => import('../pages/customer/Signout'));
const TempPasswordChange = React.lazy(() => import('../pages/customer/TempPasswordChange'));

const KraftonLogin = React.lazy(() => import('../pages/auth/Login'));
const KraftonSso = React.lazy(() => import('../pages/krafton/Sso'));

const Main = React.lazy(() => import('../pages/main/Main'));
const FoodList = React.lazy(() => import('../pages/foods/FoodList'));
const FoodsDetail = React.lazy(() => import('../pages/foods/FoodsDetail'));
const FoodsCongestion = React.lazy(() => import('../pages/foods/FoodsCongestion'));
const FoodsBab = React.lazy(() => import('../pages/foods/FoodsBab'));

const ShopList = React.lazy(() => import('../pages/shop/ShopList'));

const GoodsDetail = React.lazy(() => import('../pages/goods/GoodsDetail'));

const BasketList = React.lazy(() => import('../pages/basket/BasketList'));

const Payment = React.lazy(() => import('../pages/payment/Payment'));
const PaymentComplete = React.lazy(() => import('../pages/payment/PaymentComplete'));

const OrderList = React.lazy(() => import('../pages/order/OrderList'));
const OrderDetail = React.lazy(() => import('../pages/order/OrderDetail'));
const OrderRating = React.lazy(() => import('../pages/order/OrderRating'));
const OrderSurvey = React.lazy(() => import('../pages/order/OrderSurvey'));

const Error = React.lazy(() => import('../pages/error/ErrorPage'));

const AlertCenter = React.lazy(() => import('../pages/customer/AlertCenter'));
const AlertSetting = React.lazy(() => import('../pages/customer/AlertSetting'));
const Routes = ({ location }) => {
  const rootRoute = {
    path: '/',
    element: <Navigate to="/main" />,
  };

  const authRoute = {
    path: '/auth',
    element: <DefaultLayout />,
    children: [
      {
        path: 'group',
        element: <Group />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
      {
        path: 'verify',
        element: <Verify />,
      },
      {
        path: 'password-find',
        element: <PasswordFind />,
      },
      {
        path: 'signup',
        element: <Signup />,
      },
      {
        path: 'privacy',
        element: <Privacy />,
      },
    ],
  };

  const customerRoute = {
    // 다른 레이아웃
    path: '/customer',
    element: <ListLayout />,
    children: [
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'password-change',
        element: <PasswordChange />,
      },
      {
        path: 'signout',
        element: <Signout />,
      },
      {
        path: 'temp-password-change', // 임시비밀번호 로그인시 비밀번호 변경
        element: <TempPasswordChange />,
      },
      {
        path: 'alert-center',
        element: <AlertCenter />,
      },
      {
        path: 'alert-setting',
        element: <AlertSetting />,
      },
      {
        path: 'push-log',
        element: <PushLog />,
      },
    ],
  };

  const kraftonRoute = {
    path: '/krafton',
    element: <DefaultLayout />,
    children: [
      {
        path: 'login',
        element: <KraftonLogin />,
      },
      {
        path: 'sso',
        element: <KraftonSso />,
      },
    ],
  };

  const mainRoute = {
    path: '/main',
    element: <MainLayout />,
    children: [{ index: true, element: <Main /> }],
  };

  const foodsRoute = {
    path: '/foods',
    element: <ListLayout />,
    children: [
      {
        path: 'menuList',
        element: <FoodList />,
      },
      {
        path: 'detail',
        element: <FoodsDetail />,
      },
      {
        path: 'congestion',
        element: <FoodsCongestion />,
      },
      {
        path: 'bab',
        element: <FoodsBab />,
      },
    ],
  };

  const shopRoute = {
    path: '/shop',
    element: <ListLayout />,
    children: [
      {
        path: 'list',
        element: <ShopList />,
      },
    ],
  };

  const goodsRoute = {
    path: '/goods',
    element: <GoodsDetailLayout />,
    children: [
      {
        path: 'detail',
        element: <GoodsDetail />,
      },
    ],
  };

  const basketRoute = {
    path: '/basket',
    element: <ListLayout />,
    children: [
      {
        path: 'list',
        element: <BasketList />,
      },
    ],
  };

  const orderRoute = {
    path: '/order',
    element: <OrderListLayout />,
    children: [
      {
        path: 'list',
        element: <OrderList />,
      },
      {
        path: 'detail',
        element: <OrderDetail />,
      },
      {
        path: 'robotDelivery',
        element: <OrderRobotDelivery />,
      },
      {
        path: 'rating',
        element: <OrderRating />,
      },
      {
        path: 'survey',
        element: <OrderSurvey />,
      },

    ],
  };

  const paymentRoute = {
    path: '/payment',
    element: <ListLayout />,
    children: [
      {
        index: true,
        element: <Payment />,
      },
      {
        path: 'complete',
        element: <PaymentComplete />,
      },
    ],
  };
  const paymentRobotRoute = {
    path: '/paymentRobot',
    element: <ListLayout />,
    children: [
      {
        index: true,
        element: <PaymentRobot />,
      },
      {
        path: 'complete',
        element: <PaymentRobotComplete />,
      },
    ],
  };

  const errorRoute = {
    path: '/error',
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Error />,
      },
    ],
  };

  const noMatchRoutes = {
    path: '*',
    element: <Navigate to="/" />,
  };

  const routes = [
    rootRoute,
    kraftonRoute,
    authRoute,
    customerRoute,
    mainRoute,
    shopRoute,
    goodsRoute,
    orderRoute,
    basketRoute,
    foodsRoute,
    paymentRoute,
    paymentRobotRoute,
    errorRoute,
    noMatchRoutes,
  ];

  return useRoutes(routes, location);
};

const Router = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  const getEffect = () => {
    if (location.pathname.indexOf('/main') < 0 && location.pathname.indexOf('/auth') < 0) {
      return 'slideLeft';
    } else {
      return '';
    }
  };

  return (
    <ErrorBoundary>
      <Wrap navigationType={navigationType} pathname={location.pathname}>
        <TransitionGroup component={null}>
          <CSSTransition key={location.key} timeout={location.pathname.indexOf('/auth') < 0 ? 300 : 0} classNames={getEffect()}>
            <Suspense fallback={loading()}>
              <Routes location={location} />
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      </Wrap>
    </ErrorBoundary>
  );
};

const Wrap = styled.div`
  ${({ navigationType, pathname }) =>
    navigationType === 'PUSH' && pathname.indexOf('/main') < 0
      ? css`
          .slideLeft-enter {
            transform: translateX(100%);
            z-index: 100;
          }

          .slideLeft-enter-active {
            transform: translateX(0%);
            transition: transform 300ms ease-in-out;
            z-index: 100;
          }
        `
      : css`
          .slideLeft-exit {
            transform: translateX(0%);
            z-index: 100;
          }

          .slideLeft-exit-active {
            transform: translateX(100%);
            transition: transform 300ms ease-in-out;
            z-index: 100;
          }
        `}
`;

const loading = () => <BackgroundLoading />;

export default Router;
