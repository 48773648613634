import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/Modal';

const WarnMaxDeliOrderCntModal = ({ isOpen, closeModal, deliveryOrderCnt }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={isOpen} onConfirm={() => closeModal()} onClose={() => closeModal()} hideXButton>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0.9375rem 0 1.1875rem' }}>
        {t('ORDER_CNT_MENT1')}
        {deliveryOrderCnt}
        {t('ORDER_CNT_MENT2')}
      </div>
    </Modal>
  );
};

export default React.memo(WarnMaxDeliOrderCntModal);
