import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface CategoryInfo {
  brandId: string;
  categoryId: string;
  categoryPid: string;
  categoryEngNm: string;
  categoryNm: string;
  categoryOrder: number;
  goodsCnt: number;
  delYn: string;
  delDt: null | string;
  regDt: string;
  modDt: string;
  useYn: string;
  spaceId: string;
  groupId: null | string;
  layoutType: null | string;
  originDetail: null | string;
  startTime: null | string;
  endTime: null | string;
  trafficLight: null | string;
}

interface CategoryState {
  categoryList: Array<CategoryInfo>;
  categoryInfo: CategoryInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: CategoryState = {
  categoryList: [],
  categoryInfo: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: CategoryState) => {
    state.actionResult = '';
  },
  categoryStateClear: (state: CategoryState) => {
    state.categoryList = [];
    state.actionResult = '';
    state.isLoading = false;
    state.error = null;
  },
  categoryList: (state: CategoryState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryListSuccess: (state: CategoryState, { payload }: PayloadAction<CategoryState>) => {
    state.categoryList = payload.categoryList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryListFailure: (state: CategoryState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryInfo: (state: CategoryState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryInfoSuccess: (state: CategoryState, { payload }: PayloadAction<CategoryState>) => {
    state.categoryInfo = payload.categoryInfo;
    state.actionResult = 'INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryInfoFailure: (state: CategoryState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'INFO_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  foodCategoryList: (state: CategoryState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  foodCategoryListSuccess: (state: CategoryState, { payload }: PayloadAction<CategoryState>) => {
    state.categoryList = payload.categoryList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  foodCategoryListFailure: (state: CategoryState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers,
});

const selectCategoryList = createDraftSafeSelector(
  (state: CategoryState) => state.categoryList,
  categoryList => {
    const filterCategoryList = categoryList.filter(category => category.useYn === 'Y');
    const sortCategoryList = [...filterCategoryList].sort((l, r) =>
      l.categoryOrder === r.categoryOrder ? 0 : l.categoryOrder < r.categoryOrder ? -1 : 1,
    );

    return sortCategoryList;
  },
);

const selectMobileCategoryList = createDraftSafeSelector(
  (state: CategoryState) => state.categoryList,
  categoryList => {

    const mobileCategoryList = categoryList.filter(category => category.useYn === 'Y' && category.useMobileYn === 'Y');
    mobileCategoryList.sort((l, r) => (l.categoryOrder === r.categoryOrder ? 0 : l.categoryOrder < r.categoryOrder ? -1 : 1));

    return mobileCategoryList;
  },
);


const selectCafeCategoryList = createDraftSafeSelector(
  (state: CategoryState) => state.categoryList,
  categoryList => {

    const cafeCategoryList = categoryList.filter(category => category.useYn === 'Y' && category.useMobileYn === 'Y');
    cafeCategoryList.sort((l, r) => (l.categoryOrder === r.categoryOrder ? 0 : l.categoryOrder < r.categoryOrder ? -1 : 1));

    let category = {
      categoryId : 'all',
      categoryNm : '추천',
      categoryEngNm : 'Recommend',
    };

    cafeCategoryList.unshift(category);

    return cafeCategoryList;
  },
);




const selectCategoryInfo = createDraftSafeSelector(
  (state: CategoryState) => state.categoryInfo,
  categoryInfo => categoryInfo,
)

const selectStatus = createDraftSafeSelector(
  (state: CategoryState) => state.actionResult,
  (state: CategoryState) => state.isLoading,
  (state: CategoryState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const categorySelector = {
  categoryList: state => selectCategoryList(state[CATEGORY]),
  mobileCategoryList: state => selectMobileCategoryList(state[CATEGORY]),
  cafeCategoryList: state => selectCafeCategoryList(state[CATEGORY]),
  categoryInfo: state => selectCategoryInfo(state[CATEGORY]),
  status: state => selectStatus(state[CATEGORY]),
};

export const CATEGORY = slice.name;
export const categoryReducer = slice.reducer;
export const categoryAction = slice.actions;
