import * as apiLib from './api';

export const info = async ({ customerId }) => {
  try {
    const data = {
      customerId,
    };

    const result = await apiLib.fetchStoreApi('/order-app/customer/info', data);
    if (result.resultFlag) {
      return result.customerInfo;
    } else {
      throw Object.assign(new Error('customer info error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getPaymentKey = async ({ spaceId, customerId }) => {
  try {
    const data = {
      spaceId,
      customerId,
    };

    const result = await apiLib.fetchStoreApi('/order-app/customer/getPaymentKey', data);
    if (result.resultFlag) {
      return result.paymentKey;
    } else {
      throw Object.assign(new Error('customer getPaymentKey error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async ({ customerId, updateInfo }) => {
  try {
    const data = {
      customerId,
      updateInfo,
    };

    const result = await apiLib.fetchStoreApi('/order-app/customer/update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('customer update error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/order-app/customer/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('customer remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const passwordChange = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/order-app/customer/password-change', data);
    if (result.resultFlag) {
      return result.customerInfo;
    } else {
      let errCode = 0;
      switch (result.resultMsg) {
        case 'Error: password diff':
          errCode = 1000;
          break;
        default:
          break;
      }
      throw Object.assign(new Error('password change error'), { status: 401, errCode });
    }
  } catch (err) {
    throw err;
  }
};
