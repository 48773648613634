import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LeftIcon } from '../../assets/images/navigation/icon-left.svg';

const ListHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const backButtonAction = useCallback(() => {
    const pathname = location.pathname;
    // 주문내역 주문하기 장바구니 에러는 무조건 main으로 돌아가기
    if (
      pathname.indexOf('/order/list') >= 0 ||
      pathname.indexOf('/payment/complete') >= 0 ||
      pathname.indexOf('/foods/congestion') >= 0 ||
      pathname.indexOf('/customer/profile') >= 0 ||
      pathname.indexOf('error') >= 0
    ) {
      navigate('/main');
    } else if (pathname.indexOf('/foods/menuList') >= 0) {
      sessionStorage.removeItem('sessionDayType');
      sessionStorage.removeItem('sessionStartDt');
      sessionStorage.removeItem('sessionEndEDt');
      navigate(-1);
    } else {
      navigate(-1);
    }
  }, [location.pathname, navigate]);

  const getTitle = useMemo(() => {
    const pathname = location.pathname;

    if (pathname.indexOf('/shop') >= 0) {
      return t('TITLE_SHOP_INFO');
    } else if (pathname.indexOf('/customer/password-change') >= 0) {
      return t('TITLE_CHANGE_PASSWORD');
    } else if (pathname.indexOf('/customer/name-change') >= 0) {
      return t('TITLE_CHANGE_NAME');
    } else if (pathname.indexOf('/customer/signout') >= 0) {
      return t('TITLE_SIGNOUT');
    } else if (pathname.indexOf('/customer/profile') >= 0) {
      return t('TITLE_MYPAGE');
    } else if (pathname.indexOf('/order/list') >= 0) {
      return t('TITLE_ORDER_HISTORY');
    } else if (pathname.indexOf('/order/detail') >= 0) {
      return t('TITLE_MORE');
    } else if (pathname.indexOf('/payment') >= 0) {
      return t('TITLE_ORDER');
    } else if (pathname.indexOf('/basket') >= 0) {
      return t('TITLE_CART');
    } else if (pathname.indexOf('/foods/menuList') >= 0) {
      return t('TITLE_MENU');
    } else if (pathname.indexOf('/foods/congestion') >= 0) {
      return t('TITLE_MENU_WAIT');
    } else if (pathname.indexOf('/foods/menuList') >= 0) {
      return t('TITLE_MENU');
    } else if (pathname.indexOf('/error') >= 0) {
      return t('TITLE_ORDER');
    } else if (pathname.indexOf('/foods/detail') >= 0) {
      return t('MENU_ONLY');
    } else if (pathname.indexOf('/customer/alert-center') >= 0) {
      return t('ALERT');
    } else if (pathname.indexOf('/customer/push-log') >= 0) {
      return t('ALERT');
    } else if (pathname.indexOf('/order/survey') >= 0) {
      return t('SURVEY');
    } else if (pathname.indexOf('/customer/alert-setting') >= 0) {
      return t('ALERT_SETTING');
    } else if (pathname.indexOf('/order/robotDelivery') >= 0) {
      return t('ROBOT_DELI_BTN');
    }
    return '';
  }, [location.pathname, t]);

  useEffect(() => {
    const _handleMessage = e => {
      let message = {};
      try {
        message = JSON.parse(e.data);
      } catch (err) {
        return;
      }

      if (message.type === 'RES_NAV_BACK') {
        backButtonAction();
      } else if (message.type === 'RES_PUSH_CLICK') {
        if (message.url === 'order') {
          navigate('/order/list');
        } else if (message.url.includes('rating')) {
          const url = message.url;
          let orderId = '';
          const subIndex = url.indexOf('_');

          if(subIndex!=-1) {
            orderId = url.substring(subIndex + 1);
          }

          navigate('/order/rating', {
            state: {orderId: orderId}
          });
        } else if (message.url === 'survey') {
          navigate('/order/survey');
        } else {
          navigate('/');
        }
      }
    };

    if (window.ReactNativeWebView && navigator.appVersion.includes('Android')) {
      document.addEventListener('message', _handleMessage, true);
    } else {
      window.addEventListener('message', _handleMessage, true);
    }

    return () => {
      if (window.ReactNativeWebView && navigator.appVersion.includes('Android')) {
        document.removeEventListener('message', _handleMessage, true);
      } else {
        window.removeEventListener('message', _handleMessage, true);
      }
    };
  }, [backButtonAction, navigate]);

  const handleSave = useCallback(() => {
    const pathname = location.pathname;
    if (pathname.indexOf('/customer/name-change') >= 0) {
      document.dispatchEvent(new CustomEvent('name-change'));
    } else if (location.pathname.indexOf('/customer/password-change') >= 0) {
      document.dispatchEvent(new CustomEvent('password-change'));
    }
  }, [location.pathname]);

  return (
    <HeaderBox>
      <Container>
        <button onClick={() => backButtonAction()}>
          <LeftIcon />
        </button>
        <div className="title">{getTitle}</div>
        {(location.pathname.indexOf('/customer/name-change') >= 0 || location.pathname.indexOf('/customer/password-change') >= 0) && (
          <SaveBtn onClick={() => handleSave()}>{t('SAVE')}</SaveBtn>
        )}
      </Container>
    </HeaderBox>
  );
};

const HeaderBox = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  height: 3.125rem;
  padding: 0 1.25rem;
  background: ${({ theme }) => theme.subBackground};
  border-bottom: 0.0625rem solid ${({ theme }) => theme.line};
  z-index: 10;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  & > button {
    background: none;
  }

  .title {
    color: #000000;
    font-style: normal;
    font-weight: bold;
    font-size: 1.0625rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SaveBtn = styled.div`
  position: absolute;
  right: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  color: #333333;
`;

export default React.memo(ListHeader);
