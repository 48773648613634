import * as apiLib from './api';

export const reserve = async data => {
  try {
    console.log(data);
    const result = await apiLib.fetchStoreApi('/robot/reserve-km-robot', data);

    return result;
  } catch (err) {
    return false;
  }
};
export const check = async data => {
  try {
    console.log(data);
    const result = await apiLib.fetchStoreApi('/robot/check-km-robot', data);

    return result;
  } catch (err) {
    return false;
  }
};
export const reserveCancle = async data => {
  try {
    const result = await apiLib.fetchStoreApi('/robot/cancle-km-robot', data);

    return result;
  } catch (err) {
    return false;
  }
};
export const accept = async data => {
  try {
    //결제 전 로봇배송 확정 요청
    const result = await apiLib.fetchStoreApi('/robot/pre_accept-km-robot', data);

    return result;
  } catch (err) {
    return false;
  }
};
export const addRobot = async ({ order }) => {
  try {
    const data = {
      ...order,
    };

    const result = await apiLib.fetchStoreApi('/order-app/order/addRobot', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('order error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};
