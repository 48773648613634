import React from 'react';
import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { pushLogAction } from '../../modules/pushLogSlice';
import { customerSelector } from '../../modules/customerSlice';
import { shopSelector } from '../../modules/shopSlice';
import { pushLogSelector } from '../../modules/pushLogSlice';
import dayjs from 'dayjs';
import { useState } from 'react';
import { t } from 'i18next';
import arrowImg from '../../assets/images/common/pushlog_arrow.png';
import PushLogDeleteModal from './modal/PushLogDeleteModal';

const PushLog = () => {
  const customerInfo = useSelector(customerSelector.customerInfo);
  const shopInfo = useSelector(shopSelector.shopInfo);
  const pushLog = useSelector(pushLogSelector.logInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (shopInfo && customerInfo) dispatch(pushLogAction.list({ customerId: customerInfo.customerId, shopId: shopInfo.shopId }));
  }, [customerInfo, customerInfo.customerId, dispatch, shopInfo, shopInfo.shopId]);

  function clickOrder(orderId, type) {
    if (type === 'RATING') {
      // sessionStorage.setItem('selectOrderId', orderId);
      navigate('/order/rating',{
        state: {orderId: orderId}
      });
    }
    if (type === 'SURVEY') {
      sessionStorage.setItem('selectSurveyId', orderId);
      navigate('/order/survey');
    }
  }
  return (
    <Container>
      <Header>
        <div className="title">알림 {pushLog?.length ?? 0}개</div>
        <StyledButton
          onClick={() => {
            setIsOpen(true);
          }}
        >
          전체삭제
        </StyledButton>
      </Header>
      {pushLog?.length === 0 ? (
        <NoData>
          <center>
            받은 알림 내역이 없습니다.
            <br />
            알림 내역은 30일까지만 보관됩니다.
          </center>
        </NoData>
      ) : (
        pushLog?.map((item, index) => {
          return (
            <React.Fragment key={'message' + index}>{shopInfo && <MessageComponent item={item} clickOrder={clickOrder} />}</React.Fragment>
          );
        })
      )}
      <PushLogDeleteModal isOpen={isOpen} customerId={customerInfo.customerId} shopId={shopInfo.shopId} setIsOpen={setIsOpen} />
    </Container>
  );
};

const MessageComponent = ({ item, clickOrder }) => {
  const today = dayjs();

  let timeText = '';
  const minute = today.diff(item.regDt, 'minute');
  const hour = today.diff(item.regDt, 'hour');
  if (minute <= 59) {
    timeText = minute + '분전';
  } else if (hour > 0 && hour <= 23) {
    timeText = hour + '시간 전';
  } else {
    timeText = dayjs(item.regDt).format('MM월 DD일 hh:mm');
  }

  return (
    <MessageContainer
      onClick={() => {
        item.pushType !== 'MSG' && clickOrder(item.pushId, item.pushType);
      }}
    >
      {item.viewYn === 'N' && <div className="recent"></div>}
      <div className="title">{item.pushTitle}</div>
      <div className="sub">{item.pushDesc}</div>
      <div className="time">{timeText}</div>
      {item.pushType !== 'MSG' && (
        <div className="arrowBtn">
          <img src={arrowImg} />
        </div>
      )}
    </MessageContainer>
  );
};

const NoData = styled.div`
  margin: auto;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
`;

const StyledButton = styled.button`
  height: 26px;
  background-color: white;
  padding: 7px 16px;
  border-radius: 20px;
  border: 1px solid #cccccc;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #666666;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 15px;
    font-weight: 500;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  gap: 10px;
  height: 100%;
  background-color: #e5e5e5;
  overflow: auto;
`;

const MessageContainer = styled.div`
  padding: 20px 20px;
  width: 100%;
  // min-height: 127px;
  background-color: white;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  .title {
    font-size: 15px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 20px;
  }
  .sub {
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    padding-left: 10px;
    padding-right: 20px;
  }
  .time {
    color: #aaaaaa;
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 20px;
  }
  .menu {
    font-weight: 700;
    font-size: 12px;
    color: #666666;
  }

  .recent {
    background-color: #ffd600;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 5px;
    margin: 16px;
    border-radius: 50%;
  }
  .arrowBtn {
    width: 8px;
    height: 8px;
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

export default React.memo(PushLog);
