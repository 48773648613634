import palette from './palette';

const baseTheme = {
  ...palette,
  mainBackground: palette.gray50,
  subBackground: palette.white,
  line: palette.gray200,
  shopCard: {
    background: palette.gray50,
    selected: palette.gray700,
    img: palette.white,
  },
  shopButton: {
    background: palette.white,
  },
  button: {
    disable: palette.gray500,
    unselect: palette.gray200,
    select: palette.gray800,
    ...palette,
  },
  checkbox: {
    background: palette.white,
    uncheck: palette.gray500,
    check: palette.gray800,
    radioCheck: palette.gray700,
    disabled: palette.gray500,
  },
  category: {
    background: palette.gray200,
    fontColor: palette.black,
  },
  orderDetailButton: {
    background: palette.white,
    border: palette.gray600,
    fontColor: palette.gray800,
  },
};

export default baseTheme;
