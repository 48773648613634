import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/Modal';
import { useDispatch } from 'react-redux';
import { pushLogAction } from '../../../modules/pushLogSlice';

const PushLogDeleteModal = ({ isOpen, customerId, shopId, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOk = useCallback(() => {
    dispatch(pushLogAction.update({ customerId, shopId }));
    setIsOpen(false);
  }, [dispatch, setIsOpen, customerId, shopId]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <Modal
      visible={isOpen}
      title=""
      onConfirm={() => handleOk()}
      onClose={() => handleCancel()}
      onCancel={() => handleCancel()}
      hideXButton
    >
      <Container>알림함의 모든 메시지를 삭제하시겠어요?</Container>
    </Modal>
  );
};

const Container = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 0.625rem;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  text-align: center;
  color: #000000;
`;

export default React.memo(PushLogDeleteModal);
