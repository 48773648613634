import i18next from 'i18next';
import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface LanguageState {
  langCd: 'KR' | 'EN';
  theme: 'DEFAULT';
}

const initialState: LanguageState = {
  langCd: localStorage.getItem('langCd') || 'KR',
  theme: localStorage.getItem('theme') || 'DEFAULT',
};

const reducers = {
  changeLangCd: (state: LanguageState, { payload: langCd }: PayloadAction<string>) => {
    i18next.changeLanguage(langCd);
    localStorage.setItem('langCd', langCd);
    state.langCd = langCd;
  },
  changeTheme: (state: LanguageState, { payload: theme }: PayloadAction<string>) => {
    localStorage.setItem('theme', theme);
    state.theme = theme;
  },
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers,
});

const selectLangCd = createDraftSafeSelector(
  (state: LanguageState) => state.langCd,
  langCd => langCd,
);

const selectTheme = createDraftSafeSelector(
  (state: LanguageState) => state.theme,
  theme => theme,
);

export const configSelector = {
  langCd: state => selectLangCd(state[CONFIG]),
  theme: state => selectTheme(state[CONFIG]),
};

export const CONFIG = slice.name;
export const configReducer = slice.reducer;
export const configAction = slice.actions;
