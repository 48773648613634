import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface ICategoryGroupInfo {
  categoryGroupId: string;
}
interface ICategoryList {
  categoryId: string;
}

interface CategoryGroupState {
  categoryGroupList: ICategoryGroupInfo[];
  categoryList: [];
  actionResult: string;
  isLoading: boolean;
  error: string | null;
  orderList: [];
}

const InitialState: CategoryGroupState = {
  categoryGroupList: [],
  categoryList: [],
  actionResult: '',
  isLoading: false,
  orderList: [],
  error: '',
};

const reducers = {
  stateClear: (state: CategoryGroupState, { payload }: PayloadAction<CategoryGroupState>) => {
    state.categoryGroupList = [];
    state.categoryGroupInfo = {};
    state.categoryList = [];
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  joinList: (state: CategoryGroupState, { payload }: PayloadAction<CategoryGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'JOIN_LIST_REQ';
    state.categoryGroupList = payload.categoryGroupList;
    state.categoryList = payload.categoryList;
    state.orderList = payload.orderList;
    state.error = '';
  },

  list: (state: CategoryGroupState, { payload }: PayloadAction<CategoryGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: CategoryGroupState, { payload }: PayloadAction<CategoryGroupState>) => {
    state.categoryGroupList = payload.categoryGroupList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: CategoryGroupState, { payload }: PayloadAction<CategoryGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  listEdit: (state: CategoryGroupState, { payload }: PayloadAction<CsutomerCategoryGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_EDIT_REQ';
    state.error = '';
  },
  listEditSuccess: (state: CategoryGroupState, { payload }: PayloadAction<CategoryGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_EDIT_OK';
    state.error = '';
  },
  listEditFailure: (state: CategoryGroupState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_EDIT_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: CategoryGroupState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'categoryGroup',
  initialState: InitialState,
  reducers: reducers,
});

const selectcategoryGroupList = createDraftSafeSelector(
  (state: CategoryGroupState) => state.categoryGroupList,
  categoryGroupList => [...categoryGroupList].sort((a, b) => a.categoryGroupOrder - b.categoryGroupOrder),
);

const selectJoinGroupList = createDraftSafeSelector(
  (state: CategoryGroupState) => state.categoryGroupList,
  (state: CategoryGroupState) => state.categoryList,
  (state: CategoryGroupState) => state.orderList,

  (categoryGroupList, categoryList, orderList) => {
    const mergeJson = [];
    orderList.forEach(order => {
      const goodsCategoryId = order.itemList[0].categoryId;
      if (goodsCategoryId) {
        const goods = categoryList.find(ctg => ctg.categoryId === goodsCategoryId);
        const course = categoryList.find(ctg => ctg.categoryId === goods?.categoryPid);
        const ctgGroup = categoryGroupList.find(ctg => ctg.categoryGroupId === goods?.categoryGroupId);

        const json = {
          coursInfo: course,
          goodsInfo: goods,
          mealInfo: ctgGroup,
          orderInfo: order,
        };

        mergeJson.push(json);
      }
    });
    return mergeJson;
  },
);

const selectStatus = createDraftSafeSelector(
  (state: CategoryGroupState) => state.actionResult,
  (state: CategoryGroupState) => state.isLoading,
  (state: CategoryGroupState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  }),
);

export const categoryGroupSelector = {
  categoryGroupList: state => selectcategoryGroupList(state[CATEGORYGROUP]),
  selectJoinGroupList: state => selectJoinGroupList(state[CATEGORYGROUP]),
  status: state => selectStatus(state[CATEGORYGROUP]),
};

export const CATEGORYGROUP = slice.name;
export const categoryGroupReducer = slice.reducer;
export const categoryGroupAction = slice.actions;
