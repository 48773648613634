import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/Modal';

const WarnPayReasonModal = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={isOpen} onConfirm={() => closeModal()} onClose={() => closeModal()} hideXButton>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0.9375rem 0 1.1875rem' }}>
        {t('GUIDE_PAY_REASON')}
      </div>
    </Modal>
  );
};

export default React.memo(WarnPayReasonModal);
