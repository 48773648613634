import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as pushLogApiLib from '../lib/pushLogApi';
import { pushLogAction } from './pushLogSlice';

function* pushLogList({ payload }) {
  try {
    const pushLog = yield call(pushLogApiLib.list, payload);
    const sort = pushLog.sort((a, b) => new Date(b.regDt) - new Date(a.regDt));

    yield put(pushLogAction.listSuccess({ pushLog: sort }));
  } catch (error) {}
}
function* pushLogCnt({ payload }) {
  try {
    const pushLog = yield call(pushLogApiLib.cnt, payload);
    yield put(pushLogAction.cntSuccess({ pushLog: pushLog }));
  } catch (error) {}
}

function* pushLogUpdate({ payload }) {
  try {
    const pushLog = yield call(pushLogApiLib.update, payload);
    yield put(pushLogAction.updateSuccess({ pushLog: pushLog })); //
  } catch (error) {}
}

export function* watchPushLogUpdate() {
  yield takeLatest(pushLogAction.update, pushLogUpdate);
}
export function* watchPushLogList() {
  yield takeLatest(pushLogAction.list, pushLogList);
}
export function* watchPushLogCnt() {
  yield takeLatest(pushLogAction.cnt, pushLogCnt);
}

function* rootSaga() {
  yield all([fork(watchPushLogList)]);
  yield all([fork(watchPushLogUpdate)]);
  yield all([fork(watchPushLogCnt)]);
}

export default rootSaga;
