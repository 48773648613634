import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import * as categoryApiLib from '../lib/categoryApi';

import { categoryAction } from './categorySlice';

function* categoryList({ payload }) {
  try {
    const categoryList = yield call(categoryApiLib.categoryList, payload);

    yield put(categoryAction.categoryListSuccess({ categoryList }));
  } catch (err) {
    yield put(categoryAction.categoryListFailure('Category cafeList Error'));
  }
}

function* categoryInfo({ payload }) {
  try {
    const categoryInfo = yield call(categoryApiLib.categoryInfo, payload);

    yield put(categoryAction.categoryInfoSuccess({ categoryInfo }));
  } catch (err) {
    yield put(categoryAction.categoryInfoFailure('Category cafeList Error'));
  }
}

function* foodCategoryList({ payload: { brandId, categoryType } }) {
  try {
    const categoryList = yield call(categoryApiLib.foodCategoryList,  { brandId, categoryType });

    yield put(categoryAction.foodCategoryListSuccess({ categoryList }));
  } catch (err) {
    yield put(categoryAction.foodCategoryListFailure('Category foodList Error'));
  }
}

export function* watchCategoryList() {
  yield takeLatest(categoryAction.categoryList, categoryList);
}

export function* watchFoodCategoryList() {
  yield takeLatest(categoryAction.foodCategoryList, foodCategoryList);
}

export function* watchCategoryInfo() {
  yield takeLatest(categoryAction.categoryInfo, categoryInfo);
}

function* rootSaga() {
  yield all([
    fork(watchCategoryList),
    fork(watchFoodCategoryList),
    fork(watchCategoryInfo),
  ]);
}

export default rootSaga;
