import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { customerSelector } from '../../modules/customerSlice';
import { shopAction, shopSelector } from '../../modules/shopSlice';
import { authAction, authSelector } from '../../modules/authSlice';

import BackgroundLoading from '../../components/BackgroundLoading';

const Background = ({ children }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const { actionResult: shopActionResult } = useSelector(shopSelector.status);
  const customerInfo = useSelector(customerSelector.customerInfo);
  const { isLogin, actionResult: authActionResult } = useSelector(authSelector.status);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLogin) {
      dispatch(authAction.token());
    }
  }, [dispatch, isLogin]);

  useEffect(() => {
    if (authActionResult === 'TOKEN_OK') {
      dispatch(authAction.actionResultClear());
    } else if (authActionResult === 'TOKEN_ERR') {
      dispatch(authAction.actionResultClear());
      localStorage.setItem('groupCode', '');
      navigate('/auth/group');
    }
  }, [authActionResult, dispatch, navigate]);

  useEffect(() => {
    if (isLogin && location.pathname !== '/customer/temp-password-change' && customerInfo.tempLoginYn === 'Y') {
      navigate('/customer/temp-password-change');
    }
  }, [customerInfo.tempLoginYn, isLogin, location.pathname, navigate]);

  useEffect(() => {
    if (isLogin && customerInfo.viewShopId) {
      dispatch(shopAction.info({ shopId: customerInfo.viewShopId }));
    } else {
      setIsLoading(true);
    }
  }, [dispatch, customerInfo.viewShopId, isLogin]);

  useEffect(() => {
    if (shopActionResult === 'INFO_OK') {
      dispatch(shopAction.actionResultClear());
      setIsLoading(true);
    }
  }, [dispatch, shopActionResult]);

  if (!customerInfo?.spaceId) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#ffffff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BackgroundLoading />
      </div>
    );
  }

  return <>{isLoading && children}</>;
};

export default React.memo(Background);
