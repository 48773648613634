import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface SurveyUserState {
  surveyUserList: surveyUserList[];
  surveyDetail: SurveyInfo;
  selectedOrderId: string;
  isOrderWithBasket: {};
  actionResult: string;
  isLoading: boolean;
  error: null | string;
}

const surveyUserInitialState: SurveyUserState = {
  surveyUserList: [],
  surveyDetail: {},
  selectedOrderId: '',
  isOrderWithBasket: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: SurveyUserState) => {
    state.actionResult = '';
  },
  list: (state: SurveyUserState, { payload }: PayloadAction<SurveyUserState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: SurveyUserState, { payload }: PayloadAction<SurveyUserState>) => {
    state.surveyUserList = payload.surveyUserList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: SurveyUserState, { payload }: PayloadAction<SurveyUserState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  rating: (state: SurveyUserState, { payload }: PayloadAction<SurveyUserState>) => {
    state.isLoading = true;
    state.actionResult = 'RATING_REQ';
    state.error = '';
  },
  ratingSuccess: (state: SurveyUserState, { payload }: PayloadAction<SurveyUserState>) => {
    state.isLoading = false;
    state.actionResult = 'RATING_OK';
    state.error = '';
  },
  ratingFailure: (state: SurveyUserState, { payload }: PayloadAction<SurveyUserState>) => {
    state.isLoading = false;
    state.actionResult = 'RATING_ERR';
    state.error = payload.error;
  },
};

const slice = createSlice({
  name: 'surveyUser',
  initialState: surveyUserInitialState,
  reducers: reducers,
});

const selectSurveyUserList = createDraftSafeSelector(
  (state: SurveyUserState) => state.surveyUserList,
  surveyUserList => surveyUserList,
);
const selectStatus = createDraftSafeSelector(
  (state: SurveyUserState) => state.actionResult,
  (state: SurveyUserState) => state.isLoading,
  (state: SurveyUserState) => state.error,
  (state: SurveyUserState) => state.isOrderWithBasket,
  (actionResult, isLoading, error, isOrderWithBasket) => ({ actionResult, isLoading, error, isOrderWithBasket }),
);

export const surveyUserSelector = {
  surveyUserList: state => selectSurveyUserList(state[SURVEYUSER]),
  status: state => selectStatus(state[SURVEYUSER]),
};

export const SURVEYUSER = slice.name;
export const surveyUserReducer = slice.reducer;
export const surveyUserAction = slice.actions;
