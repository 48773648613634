import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/order-app/category-group/list', data);

    if (result.resultFlag) {
      return result.categoryGroupList;
    } else {
      throw Object.assign(new Error('App::Category Group List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
