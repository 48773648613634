import React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

const buttonSizes = {
  large: {
    height: '3.125rem',
    fontSize: '1.0625rem',
    borderRadius: '0.625rem',
  },
  medium: {
    height: '2.8125rem',
    fontSize: '0.9375rem',
    borderRadius: '0.375rem',
  },
  small: {
    height: '2.5rem',
    fontSize: '0.875rem',
    borderRadius: '0.3125rem',
  }
};

const buttonStyles = css`
  /* 공통 스타일 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 0.625rem;
  color: white;
  font-weight: bold;

  & + & {
    margin-left: 0.625rem;
  }

  /* 색상 */
  ${({ theme, color, outline }) => {
    const buttonColor = theme.button[color];

      return css`
        background: ${buttonColor};
        &:active {
          background: ${darken(0.1, buttonColor)};
        }

        ${outline &&
          css`
            color: ${buttonColor};
            background: none;
            border: 0.0625rem solid ${buttonColor};
            &:active {
              background: ${darken(0.1, buttonColor)};
              color: white;
            }
          `}
      `;
  }}

  /* 크기 */
  ${({ size }) => css`
    height: ${buttonSizes[size].height};
    font-size: ${buttonSizes[size].fontSize};
    border-radius: ${buttonSizes[size].borderRadius};
  `}
`;

const StyledButton = styled.button`
  ${buttonStyles}
`;

const StyledLink = styled(Link)`
  ${buttonStyles}
`;

const Button = ({
  to,
  color,
  size,
  outline,
  ...rest
}) => {
  return to ? (
    <StyledLink to={to} color={color} size={size} outline={outline} {...rest} />
  ) : (
    <StyledButton color={color} size={size} outline={outline} {...rest} />
  )
};

Button.defaultProps = {
  color: 'blue100',
  size: 'medium'
};

export default Button;
