import I18n from "i18next";
import {isMatchRobot} from "./space";

export const printTotal = (order, itemList) => {
    const resultArray = [];

    if(itemList === undefined){
        itemList = order.itemList;
    }

    itemList?.map(item => {
        if(resultArray.find(object => {
            if(object.itemNm === item.itemNm && object.goodsId === item.goodsId) {
                let cnt = object.cnt;
                object.cnt = cnt++;
                return true;
            } else {
                return false;
            }
        })){
        } else {
            resultArray.push({
                cnt: 1,
                itemNm: item.itemNm
            });
        }
    });

    if(resultArray && resultArray.length>1){
        // 다른 종류 상품 있을 경우
        return ' '+I18n.t('TOTAL_WITH_OTHER_QTY', {other: (order.totalQty-1)})
    }else{
        if(order.totalQty>1){
            // 같은 상품 여러개 또는 상품 하나만 있을 경우
            return ' '+I18n.t('TOTAL_QTY', { totalQty: order.totalQty })
        }else{
            // 환불
            return '';
        }
    }
}

export const isPickupShop = (shopInfo) => {
    if(isMatchRobot(shopInfo?.shopId)){
        //로봇배달이 있는 shop의 경우 CMS에서 픽업유형을 체크할 수 있음
        return (shopInfo?.pickupTypeList?.reserve ==='Y');
    }else{
        //로봇배달이 없는 shop의 경우 픽업유형 데이터가 없음 -> 무조건 true로 넘김
        return true;
    }
}