import { all, fork, call, put, select, takeLatest } from 'redux-saga/effects';
import { orderAction } from './orderSlice';

import * as orderApiLib from '../lib/orderApi';
import * as robotApiLib from '../lib/robotApi';
import * as shopApiLib from '../lib/shopApi';

import { SHOP } from '../modules/shopSlice';
import { CUSTOMER } from './customerSlice';

import dayjs from 'dayjs';

function* list({ payload }) {
  try {
    const { spaceId } = yield select(state => state[SHOP].shopInfo);
    const { customerId } = yield select(state => state[CUSTOMER].customerInfo);

    let orderList = yield call(orderApiLib.list, { ...payload, customerId });
    const shopList = yield call(shopApiLib.list, { spaceId });

    orderList = orderList.map(order => ({
      ...order,
      shopNm: shopList.find(shop => shop.shopId === order.shopId)?.shopNm || '',
    }));

    yield put(orderAction.listSuccess({ orderList }));
  } catch (error) {
    yield put(orderAction.listFailure({ error }));
  }
}

function* listByShop({ payload }) {
  try {
    const { shopId, shopNm, currentOpenDt } = yield select(state => state[SHOP].shopInfo);

    let orderList = [];
    if (currentOpenDt) {
      orderList = yield call(orderApiLib.listByShopId, {
        ...payload,
        shopId,
        orderStartDt: dayjs(currentOpenDt).startOf('day').toISOString(),
        orderEndDt: dayjs().endOf('day').toISOString(),
      });
    }

    orderList = orderList.map(order => ({
      ...order,
      shopNm: shopNm || '',
    }));

    yield put(orderAction.listByShopSuccess({ orderList }));
  } catch (error) {
    yield put(orderAction.listByShopFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const { spaceId } = yield select(state => state[SHOP].shopInfo);
    let orderInfo = yield call(orderApiLib.detail, payload);

    const shopList = yield call(shopApiLib.list, { spaceId });
    orderInfo = { ...orderInfo, shopNm: shopList.find(shop => shop.shopId === orderInfo.shopId)?.shopNm || '' };

    yield put(orderAction.detailSuccess({ orderInfo }));
  } catch (error) {
    yield put(orderAction.detailFailure({ error }));
  }
}

function* payment({ payload }) {
  try {
    const paymentInfo = yield call(orderApiLib.payCustomer, payload);

    if (paymentInfo) {
      const order = {
        ...payload.order,
        totalAmt: paymentInfo.apprAmt,
        totalVat: paymentInfo.apprVat,
        customerId: paymentInfo.customerId,
        customerNo: paymentInfo.customerNo,
        customerNm: paymentInfo.customerNm,
        customerEngNm: paymentInfo?.customerEngNm || paymentInfo.customerNm,
        company: paymentInfo?.company || '',
        paymentList: [paymentInfo],
      };

      yield call(orderApiLib.add, { order });
      yield put(orderAction.paymentSuccess());
    } else {
      throw new Error('no payment info');
    }
  } catch (error) {
    yield put(orderAction.paymentFailure({ error }));
  }
}

function* paymentRobot({ payload }) {
  try {
    const paymentInfo = yield call(orderApiLib.payCustomer, payload);

    if (paymentInfo) {
      const order = {
        ...payload.order,
        totalAmt: paymentInfo.apprAmt,
        totalVat: paymentInfo.apprVat,
        customerId: paymentInfo.customerId,
        customerNo: paymentInfo.customerNo,
        customerNm: paymentInfo.customerNm,
        customerEngNm: paymentInfo?.customerEngNm || paymentInfo.customerNm,
        company: paymentInfo?.company || '',
        paymentList: [paymentInfo],
      };

      yield call(robotApiLib.addRobot, { order });
      yield put(orderAction.paymentSuccess());
    } else {
      throw new Error('no payment info');
    }
  } catch (error) {
    yield put(orderAction.paymentFailure({ error }));
  }
}
function* robotReserve({ payload }) {
  try {
    const reserveInfo = yield call(robotApiLib.reserve, payload);

    if (reserveInfo.resultFlag) {
      yield put(orderAction.robotReserveSuccess());
    } else {
      yield put(orderAction.robotReserveFailure({ error: reserveInfo?.error }));
    }
  } catch (error) {
    yield put(orderAction.robotReserveFailure({ error }));
  }
}
function* robotCheck({ payload }) {
  try {
    const result = yield call(robotApiLib.check, payload);

    if (result.resultFlag) {
      yield put(orderAction.robotCheckSuccess());
    } else {
      yield put(orderAction.robotCheckFailure({ error: result?.error }));
    }
  } catch (error) {
    yield put(orderAction.robotCheckFailure({ error }));
  }
}
function* robotAccept({ payload }) {
  try {
    const result = yield call(robotApiLib.accept, payload);

    if (result.resultFlag) {
      yield put(orderAction.robotAcceptSuccess());
    } else {
      yield put(orderAction.robotAcceptFailure({ error: result?.error }));
    }
  } catch (error) {
    yield put(orderAction.robotAcceptFailure({ error }));
  }
}
function* robotReserveCancle({ payload }) {
  try {
    const reserveInfo = yield call(robotApiLib.reserveCancle, payload);
    console.log('reserveInfo', reserveInfo);
    if (reserveInfo.resultFlag) {
      yield put(orderAction.robotReserveCancleSuccess());
    } else {
      throw new Error('no reserve info');
    }
  } catch (error) {
    yield put(orderAction.robotReserveCancleFailure({ error }));
  }
}

export function* watchOrderList() {
  yield takeLatest(orderAction.list, list);
}

export function* watchOrderListByShop() {
  yield takeLatest(orderAction.listByShop, listByShop);
}

export function* watchOrderDetail() {
  yield takeLatest(orderAction.detail, detail);
}

export function* watchOrderPayment() {
  yield takeLatest(orderAction.payment, payment);
}
export function* watchOrderPaymentRobot() {
  yield takeLatest(orderAction.paymentRobot, paymentRobot);
}
export function* watchOrderRobotReserve() {
  yield takeLatest(orderAction.robotReserve, robotReserve);
}
export function* watchOrderRobotCheck() {
  yield takeLatest(orderAction.robotCheck, robotCheck);
}
export function* watchOrderRobotAccept() {
  yield takeLatest(orderAction.robotAccept, robotAccept);
}
export function* watchOrderRobotReserveCancle() {
  yield takeLatest(orderAction.robotReserveCancle, robotReserveCancle);
}

function* rootSaga() {
  yield all([
    fork(watchOrderList),
    fork(watchOrderListByShop),
    fork(watchOrderDetail),
    fork(watchOrderPayment),
    fork(watchOrderPaymentRobot),
    fork(watchOrderRobotReserve),
    fork(watchOrderRobotCheck),
    fork(watchOrderRobotAccept),
    fork(watchOrderRobotReserveCancle),
  ]);
}

export default rootSaga;
