import { all, fork, call, put, select, takeLatest } from 'redux-saga/effects';
import { surveyAction } from './surveySlice';

import * as surveyApiLib from '../lib/surveyApi';

function* list({ payload }) {
  try {
    let surveyList = yield call(surveyApiLib.list, { ...payload });
    // console.log(surveyList);
    yield put(surveyAction.listSuccess({ surveyList }));
  } catch (error) {
    yield put(surveyAction.listFailure({ error }));
  }
}
function* item({ payload }) {
  // console.log('item',payload);
  try {
    let surveyItem = yield call(surveyApiLib.item, { ...payload });
    // console.log('item',surveyItem);
    yield put(surveyAction.itemSuccess({ surveyItem }));
  } catch (error) {
    yield put(surveyAction.itemFailure({ error }));
  }
}

function* rating({ payload }) {
  try {
    const surveyInfo = yield call(surveyApiLib.rating, payload);

    if (surveyInfo) {

      yield call(surveyApiLib.rating, { rating });
      yield put(surveyAction.ratingSuccess());
    } else {
      throw new Error('no payment info');
    }
  } catch (error) {
    yield put(surveyAction.ratingFailure({ error }));
  }
}

export function* watchSurveyList() {
  yield takeLatest(surveyAction.list, list);
}
export function* watchSurveyItem() {
  yield takeLatest(surveyAction.item, item);
}
export function* watchSurvey() {
  yield takeLatest(surveyAction.rating, rating);
}

function* rootSaga() {
  yield all([
    fork(watchSurveyList),
    fork(watchSurveyItem),
    fork(watchSurvey),
  ]);
}

export default rootSaga;
