import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface GoodsInfo {
  brandId: string;
  categoryId: string;
  goodsId: string;
  discountUnit: null | string;
  discountValue: number;
  discountYn: string;
}

interface GoodsState {
  goodsTagList: Array<>;
  goodsInfo: GoodsInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: GoodsState = {
  goodsList: [],
  goodsInfo: {},
  actionResult: '',
  currentActionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: GoodsState) => {
    state.actionResult = '';
  },
  goodsInfoClear: (state: GoodsState) => {
    state.goodsInfo = {};
  },
  goodsStateClear: (state: GoodsState) => {
    state.goodsList = [];
    state.goodsInfo = {};
    state.actionResult = '';
    state.currentActionResult = '';
    state.isLoading = false;
    state.error = null;
  },
  goodsTagList: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
    state.goodsTagList = [];
  },
  goodsTagListSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsTagList = payload.tagList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsTagListFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = payload;
    state.goodsTagList = [];
  },
};

const slice = createSlice({
  name: 'goodsTag',
  initialState,
  reducers,
});

const selectGoodsTagList = createDraftSafeSelector(
  (state: GoodsState) => state.goodsTagList,
  goodsTagList => goodsTagList,
);

const selectStatus = createDraftSafeSelector(
  (state: GoodsState) => state.actionResult,
  (state: GoodsState) => state.isLoading,
  (state: GoodsState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const goodsTagSelector = {
  goodsTagList: state => selectGoodsTagList(state[GOODSTAG]),
  status: state => selectStatus(state[GOODSTAG]),
};

export const GOODSTAG = slice.name;
export const goodsTagReducer = slice.reducer;
export const goodsTagAction = slice.actions;
