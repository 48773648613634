import * as apiLib from './api';

export const list = async ({ shopId, customerId}) => {
  try {
    const data = {
      shopId,
      customerId,
    };

    const result = await apiLib.fetchStoreApi('/rating/customer-list', data);

    if (result.resultFlag) {
      return result.ratingList;
    } else {
      throw Object.assign(new Error('list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const customerlist = async ({ customerId, shopId}) => {
  try {
    const data = {
      customerId,
      shopId,
    };

    const result = await apiLib.fetchStoreApi('/rating/customer-list', data);

    if (result.resultFlag) {
      return result.ratingList;
    } else {
      throw Object.assign(new Error('list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const rating = async ({ ratingData }) => {
  try {
    const data = {
      shopId : ratingData.shopId,
      ratingData
    };

    const result = await apiLib.fetchStoreApi('/rating/add', data);
    if (result.resultFlag) {
      return result.ratingList;
    } else {
      throw Object.assign(new Error('rating error'),{ status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};