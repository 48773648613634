import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { authAction } from './authSlice';

import * as authApiLib from '../lib/authApi';
import * as customerApiLib from '../lib/customerApi';

import dayjs from 'dayjs';

import { customerAction } from './customerSlice';

function* handleLogin({ payload }) {
  try {
    if (payload.idSave === 'Y') {
      localStorage.setItem('loginId', payload.loginId);
      localStorage.setItem('idSave', 'Y');
    } else {
      localStorage.setItem('loginId', '');
      localStorage.setItem('idSave', 'N');
    }

    const result = yield call(authApiLib.login, payload);

    const loginInfo = result;
    loginInfo.lastLoginDt = dayjs(loginInfo.lastLoginDt).format('YYYY-MM-DD HH:mm:ss');

    localStorage.setItem('accessToken', result.accessToken);

    const customerInfo = yield call(customerApiLib.info, { customerId: loginInfo.customerId });

    yield put(customerAction.infoSuccess({ customerInfo }));
    yield put(authAction.loginSuccess({ loginInfo }));
  } catch (error) {
    localStorage.removeItem('accessToken');
    switch (error.errCode) {
      case 1000:
        yield put(authAction.loginFailure('사용할 수 없는 토큰 입니다.'));
        break;
      case 1001:
        yield put(authAction.loginFailure('비밀번호를 확인해주세요'));
        break;
      case 1002:
        yield put(authAction.loginFailure('사용중인 이메일이 아닙니다.'));
        break;
      case 1003:
        yield put(authAction.loginFailure('회원정보가 존재하지 않습니다.'));
        break;
      case 1004:
        yield put(authAction.loginFailure('이메일 인증을 해주세요.'));
        break;
      default:
        yield put(authAction.loginFailure('Verify Error'));
        break;
    }
  }
}

function* handleToken() {
  try {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      throw Object.assign(new Error('token error'), { status: 401 });
    }

    const data = {
      accessToken: accessToken,
    };

    const result = yield call(authApiLib.token, data);

    const loginInfo = result;
    loginInfo.lastLoginDt = dayjs(loginInfo.lastLoginDt).format('YYYY-MM-DD HH:mm:ss');

    localStorage.setItem('accessToken', result.accessToken);

    const customerInfo = yield call(customerApiLib.info, { customerId: loginInfo.customerId });
    yield put(customerAction.infoSuccess({ customerInfo }));

    yield put(authAction.tokenSuccess({ loginInfo }));
  } catch (err) {
    localStorage.removeItem('accessToken');
    yield put(authAction.tokenFailure('Token Error'));
  }
}

function* handleKraftonToken({ payload }) {
  try {
    const result = yield call(authApiLib.kraftonToken, payload);

    const loginInfo = result;
    loginInfo.lastLoginDt = dayjs(loginInfo.lastLoginDt).format('YYYY-MM-DD HH:mm:ss');

    localStorage.setItem('accessToken', loginInfo.accessToken);

    const customerInfo = yield call(customerApiLib.info, { customerId: loginInfo.customerId });
    yield put(customerAction.infoSuccess({ customerInfo }));

    yield put(authAction.kraftonTokenSuccess({ loginInfo }));
  } catch (err) {
    localStorage.removeItem('accessToken');
    yield put(authAction.kraftonTokenFailure('Token Error'));
  }
}

function* handleLogout({ payload }) {
  try {
    localStorage.removeItem('accessToken');

    yield put(authAction.logoutSuccess());
  } catch (err) {
    yield put(authAction.logoutFailure('Logout Error'));
  }
}

function* signup({ payload }) {
  try {
    yield call(authApiLib.signup, payload);

    yield put(authAction.signupSuccess());
  } catch (err) {
    yield put(authAction.signupFailure('Logout Error'));
  }
}

function* verifyEmail({ payload }) {
  try {
    yield call(authApiLib.verifyEmail, payload);

    yield put(authAction.verifyEmailSuccess());
  } catch (err) {
    switch (err.errCode) {
      case 1000:
        yield put(authAction.verifyEmailFailure('사용할 수 없는 토큰 입니다.'));
        break;
      case 1001:
        yield put(authAction.verifyEmailFailure('가입된 계정입니다.'));
        break;
      case 1002:
        yield put(authAction.verifyEmailFailure('이메일이 존재하지 않습니다.'));
        break;
      default:
        yield put(authAction.verifyEmailFailure('Verify Email Error'));
        break;
    }
  }
}

function* verify({ payload }) {
  try {
    yield call(authApiLib.verify, payload);

    yield put(authAction.verifySuccess());
  } catch (err) {
    switch (err.errCode) {
      case 1000:
      case 1001:
        yield put(authAction.verifyFailure('사용할 수 없는 토큰 입니다.'));
        break;
      case 1002:
        yield put(authAction.verifyFailure('가입되지 않은 이메일 입니다.'));
        break;
      default:
        yield put(authAction.verifyFailure('Verify Error'));
        break;
    }
  }
}

function* sendTempPassword({ payload }) {
  try {
    yield call(authApiLib.sendTempPassword, payload);

    yield put(authAction.sendTempPasswordSuccess({ ...payload }));
  } catch (error) {
    yield put(authAction.sendTempPasswordFailure({ error }));
  }
}

export function* watchLogin() {
  yield takeLatest(authAction.login, handleLogin);
}

export function* watchToken() {
  yield takeLatest(authAction.token, handleToken);
}

export function* watchKraftonToken() {
  yield takeLatest(authAction.kraftonToken, handleKraftonToken);
}

export function* watchLogout() {
  yield takeLatest(authAction.logout, handleLogout);
}

export function* watchSendTempPassword() {
  yield takeLatest(authAction.sendTempPassword, sendTempPassword);
}

export function* watchSignup() {
  yield takeLatest(authAction.signup, signup);
}

export function* watchVerifyEmail() {
  yield takeLatest(authAction.verifyEmail, verifyEmail);
}

export function* watchVerify() {
  yield takeLatest(authAction.verify, verify);
}

function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchToken),
    fork(watchKraftonToken),
    fork(watchLogout),
    fork(watchVerifyEmail),
    fork(watchVerify),
    fork(watchSignup),
    fork(watchSendTempPassword),
  ]);
}

export default rootSaga;
