import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { GOODSTAG, goodsTagReducer } from './goodsTagSlice';
import { ALARMLOG, alarmLogReducer } from './alarmLogSlice';
import { SURVEYLOG, surveyLogReducer } from './customerSurveySlice';
import { AUTH, authReducer } from './authSlice';
import { SHOP, shopReducer } from './shopSlice';
import { CUSTOMER, customerReducer } from './customerSlice';
import { CATEGORY, categoryReducer } from './categorySlice';
import { GOODS, goodsReducer } from './goodsSlice';
import { ORDER, orderReducer } from './orderSlice';
import { CATEGORYGROUP, categoryGroupReducer } from './categoryGroupSlice';
import { RATINGTAG, ratingTagReducer } from './ratingTagSlice';
import { RATING, ratingReducer } from './ratingSlice';
import { PUSHLOG, pushLogReducer } from './pushLogSlice';
import { SURVEY, surveyReducer } from './surveySlice';
import { SURVEYUSER, surveyUserReducer } from './surveyUserSlice';

import goodsTagSaga from './goodsTagSaga';
import authSaga from './authSaga';
import shopSaga from './shopSaga';
import customerSaga from './customerSaga';
import categorySaga from './categorySaga';
import goodsSaga from './goodsSaga';
import orderSaga from './orderSaga';
import categoryGroupSaga from './categoryGroupSaga';
import alarmLogSaga from './alarmLogSaga';
import surveyAlarmSaga from './customerSurveySaga';
import { CONFIG, configReducer } from './configSlice';
import ratingTagSaga from './ratingTagSaga';
import ratingSaga from './ratingSaga';
import pushLogSaga from './pushLogSaga';
import surveySaga from './surveySaga';
import surveyUserSaga from './surveyUserSaga';

const createRootReducer = () =>
  combineReducers({
    [AUTH]: authReducer,
    [SHOP]: shopReducer,
    [CUSTOMER]: customerReducer,
    [CATEGORY]: categoryReducer,
    [GOODS]: goodsReducer,
    [ORDER]: orderReducer,
    [CONFIG]: configReducer,
    [CATEGORYGROUP]: categoryGroupReducer,
    [ALARMLOG]: alarmLogReducer,
    [SURVEYLOG]: surveyLogReducer,
    [GOODSTAG]: goodsTagReducer,
    [RATINGTAG]: ratingTagReducer,
    [RATING]: ratingReducer,
    [PUSHLOG]: pushLogReducer,
    [SURVEY]: surveyReducer,
    [SURVEYUSER]: surveyUserReducer,
  });

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    authSaga(),
    shopSaga(),
    customerSaga(),
    categorySaga(),
    goodsSaga(),
    orderSaga(),
    categoryGroupSaga(),
    alarmLogSaga(),
    surveyAlarmSaga(),
    goodsTagSaga(),
    ratingTagSaga(),
    ratingSaga(),
    pushLogSaga(),
    surveySaga(),
    surveyUserSaga(),
  ]);
}

export default function createStore() {
  const store = configureStore({
    reducer: createRootReducer(),
    devTools: true,
    middleware: getDefaultMiddleware => [...getDefaultMiddleware({ serializableCheck: false }), sagaMiddleware],
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
