import * as apiLib from './api';

export const goodsTagList = async ({ brandId, shopId }) => {
  try {
    const data = {
      brandId,
      shopId,
    };

    const result = await apiLib.fetchStoreApi('/goods-tag/list', data);

    if (result.resultFlag) {
      return result.tagList;
    } else {
      throw Object.assign(new Error('App::Goods Cafe List Api error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
