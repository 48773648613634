import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface GoodsInfo {
  brandId: string;
  categoryId: string;
  goodsId: string;
  discountUnit: null | string;
  discountValue: number;
  discountYn: string;
  goodsBillNm: string;
  goodsCode: string;
  goodsDetail: null | string;
  goodsDiscountPrice: number;
  goodsEngDetail: null | string;
  goodsEngNm: string;
  goodsNm: string;
  goodsOption: string;
  goodsOrder: number;
  goodsPid: string;
  goodsPrice: number;
  goodsType: string;
  imagePath: string;
  imageSize: number;
  mainYn: string;
  modDt: string;
  optionList: { optionId: string }[];
  regDt: string;
  saleDtYn: string;
  saleEndDt: null | string;
  saleStartDt: null | string;
  spaceId: string;
  specialOption: null | string;
}

interface GoodsState {
  goodsList: Array<GoodsInfo>;
  goodsInfo: GoodsInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: GoodsState = {
  goodsList: [],
  goodsInfo: {},
  actionResult: '',
  currentActionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: GoodsState) => {
    state.actionResult = '';
  },
  goodsInfoClear: (state: GoodsState) => {
    state.goodsInfo = {};
  },
  goodsStateClear: (state: GoodsState) => {
    state.goodsList = [];
    state.goodsInfo = {};
    state.actionResult = '';
    state.currentActionResult = '';
    state.isLoading = false;
    state.error = null;
  },
  cafeGoodsList: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  cafeGoodsListSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  cafeGoodsListFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  cafeGoodsDetail: (state: GoodsState, { payload }: PayloadAction<CsutomerState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  cafeGoodsDetailSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsInfo = payload.goodsInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  cafeGoodsDetailFailure: (state: GoodsState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = action.payload;
  },
  foodGoodsList: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  foodGoodsListSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  foodGoodsListFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  foodGoodsDetail: (state: GoodsState, { payload }: PayloadAction<CsutomerState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  foodGoodsDetailSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsInfo = payload.goodsInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  foodGoodsDetailFailure: (state: GoodsState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = action.payload;
  },

  currentGoodsList: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.currentActionResult = 'CURRENT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  currentGoodsListSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.currentGoodsList = payload.goodsList;
    state.currentActionResult = 'CURRENT_OK';
    state.isLoading = false;
    state.error = null;
  },
  currentGoodsListFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.isLoading = false;
    state.currentActionResult = 'CURRENT_ERR';
    state.error = payload;
  },
};

const slice = createSlice({
  name: 'goods',
  initialState,
  reducers,
});

const selectCafeGoodsList = createDraftSafeSelector(
  (state: GoodsState) => state.goods.goodsList,
  (state: any) => state.shop.shopInfo,
  (state: any) => state.category.categoryList,
  (goodsList, shopInfo, categoryList) => {
    const filterCategoryList = categoryList.filter(category => category.useYn === 'Y' && category.useMobileYn === 'Y');

    const mainGoodsList = goodsList.filter(
      goods =>
        goods.goodsPid === shopInfo.brandId &&
        goods.useYn === 'Y' &&
        filterCategoryList?.find(category => category.categoryId === goods.categoryId),
    );
    const subGoodsList = goodsList.filter(
      goods => goods.goodsPid !== shopInfo.brandId && filterCategoryList?.find(category => category.categoryId === goods.categoryId),
    );

    let joinGoodsList = [];
    for (let mainGoods of mainGoodsList) {
      joinGoodsList.push({
        ...mainGoods,
        goodsList: [],
      });
    }

    for (let joinGoods of joinGoodsList) {
      joinGoods.goodsList = subGoodsList.filter(subGoods => subGoods.goodsPid === joinGoods.goodsId);
    }

    // 대표상품이 없는 상품은 useYn을 N으로 변경
    for (const joinGoods of joinGoodsList) {
      if (joinGoods.goodsType === 'PACKAGE' && !joinGoods.goodsList.find(subGoods => subGoods.mainYn === 'Y')) {
        joinGoods.useYn = 'N';
      }
    }

    let filteredGoodsList = joinGoodsList.filter(joinGoods => joinGoods.useYn === 'Y');

    // filteredGoodsList = categoryList.find(category => category.categoryId === goods.categoryId)

    return filteredGoodsList;
  },
);
const selectFoodGoodsList = createDraftSafeSelector(
  (state: GoodsState) => state.goodsList,
  goodsList => {
    let foodGoodsList = {};

    goodsList.forEach(goods => {
      if (foodGoodsList[goods.orderDt] === undefined) {
        foodGoodsList[goods.orderDt] = [goods];
      } else {
        foodGoodsList[goods.orderDt].push(goods);
      }
    });
    return foodGoodsList;
  },
);

const selectCurrentGoodsList = createDraftSafeSelector(
  (state: GoodsState) => state.goods.currentGoodsList,
  (state: any) => state.category.categoryList,

  (currentGoodsList, categoryList) => {
    const filterCategoryList = categoryList?.filter(category => category.useYn === 'Y' && category.useMobileYn === 'Y');
    const filterCurrentGoodsList = currentGoodsList?.filter(goods =>
      filterCategoryList?.find(category => category.categoryId === goods.categoryId),
    );
    return filterCurrentGoodsList || [];
  },
);

const selectGoodsInfo = createDraftSafeSelector(
  (state: GoodsState) => state.goodsInfo,
  goodsInfo => goodsInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: GoodsState) => state.actionResult,
  (state: GoodsState) => state.isLoading,
  (state: GoodsState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const goodsSelector = {
  cafeGoodsList: state => selectCafeGoodsList(state),
  foodGoodsList: state => selectFoodGoodsList(state[GOODS]),
  currentGoodsList: state => selectCurrentGoodsList(state),
  goodsInfo: state => selectGoodsInfo(state[GOODS]),
  status: state => selectStatus(state[GOODS]),
};

export const GOODS = slice.name;
export const goodsReducer = slice.reducer;
export const goodsAction = slice.actions;
