import axios from 'axios';
import produce from 'immer';

const developmentUrl = 'https://faas.eligaws.com/store';
const productionUrl = 'https://faas.eligaspace.com/store';
const localUrl = 'http://localhost:8005/store';

const API_KEY = 'bCAoIUYQ8N1CZ4rMDPdvJ54HqeZwfgeF96NCYJhQ';

export const getUrlByLocation = function(){
  let apiUrl = '';
  if (window.location.href.includes('eligaws')) {
    apiUrl = developmentUrl;
  } else if (window.location.href.includes('eligaspace')) {
    apiUrl = productionUrl;
  } else {
    apiUrl = localUrl;
  }

  return apiUrl;
}

export const fetchEventSource = (url, data) => {
  const accessToken = localStorage.getItem('accessToken');

  data = produce(data, draft => {
    draft.accessToken = accessToken;
  });

  const queryString = Object.entries(data)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

  const apiUrl = getUrlByLocation();
  const eventSource = new EventSource(`${apiUrl}${url}?${queryString}`);

  return eventSource;
};

export const fetchStoreApi = async (url, data, options = {}) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    data = produce(data, draft => {
      draft.accessToken = accessToken;
    });

    const apiUrl = getUrlByLocation();

    const response = await axios({
      method: 'post',
      url: apiUrl + url,
      headers: {
        'X-Api-Key': API_KEY,
        'content-type': 'application/json',
        ...options.headers,
      },
      data: data,
    }).catch(err => {
      if (err.response.status === 401) {
        window.location.href = '/auth/group';
      } else {
        throw err;
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw Object.assign(new Error('api error'), {
        status: response.status,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const fetchStoreMultipartApi = async (url, data, options = {}) => {
  try {
    const deviceToken = localStorage.getItem('deviceToken');

    const apiUrl = getUrlByLocation();

    const response = await axios({
      method: 'post',
      url: apiUrl + url + `?deviceToken=${deviceToken}`,
      headers: {
        'X-Api-Key': API_KEY,
        'content-type': 'multipart/form-data',
        ...options.headers,
      },
      data: data,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw Object.assign(new Error('api error'), {
        status: response.status,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const fetchStoreFormDataApi = async (url, formData) => {
  try {
    const apiUrl = getUrlByLocation();

    const response = await axios({
      url: url,
      method: 'post',
      baseURL: apiUrl,
      headers: {
        'X-Api-Key': API_KEY,
        'content-type': 'multipart/form-data',
      },
      data: formData,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw Object.assign(new Error('api error'), {
        status: response.status,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const fetchUploadApi = async (url, file, onUploadProgress, i) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status < 400) {
        resolve(true);
      } else {
        const error = new Error(xhr.response);
        reject(error);
      }
    };
    xhr.upload.onprogress = evt => {
      if (evt.lengthComputable) {
        onUploadProgress && onUploadProgress(file, evt.loaded, evt.total, i);
      }
    };
    xhr.onerror = error => {
      reject(error);
    };
    xhr.onreadystatechange = evt => {
      if (xhr.readyState === 1) {
      } else if (xhr.readyState === 4) {
      }
    };

    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
};
