import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface AlarmSendState {
  logInfo: Array[];
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const authInitialState: AlarmSendState = {
  logInfo: [],
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  list: (state: AuthState, { payload }: PayloadAction<LoginPayload>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
    state.logInfo = [];
  },
  listSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.logInfo = payload.alarmSendLog;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: AuthState, { payload }: PayloadAction<string>) => {
    state.isLogin = false;
    state.logInfo = {};
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload;
  },
};

const slice = createSlice({
  name: 'alarmLog',
  initialState: authInitialState,
  reducers: reducers,
});

const selectAlarmLog = createDraftSafeSelector(
  (state: AlarmSendState) => state.logInfo,
  logInfo => logInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: AlarmSendState) => state.isLogin,
  (state: AlarmSendState) => state.actionResult,
  (state: AlarmSendState) => state.isLoading,
  (state: AlarmSendState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const alarmLogSelector = {
  logInfo: state => selectAlarmLog(state[ALARMLOG]),
  status: state => selectStatus(state[ALARMLOG]),
};

export const ALARMLOG = slice.name;
export const alarmLogReducer = slice.reducer;
export const alarmLogAction = slice.actions;
