import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { surveyUserAction } from './surveyUserSlice';

import * as surveyUserApiLib from '../lib/surveyUserApi';

function* list({ payload }) {
  // console.log('payload');
  try {
    let surveyUserList = yield call(surveyUserApiLib.list, { ...payload });
    // console.log('list',surveyUserList);
    yield put(surveyUserAction.listSuccess({ surveyUserList }));
  } catch (error) {
    yield put(surveyUserAction.listFailure({ error }));
  }
}

function* rating({ payload }) {
  try {
    const surveyUserInfo = yield call(surveyUserApiLib.rating, payload);
    if (surveyUserInfo) {
      yield call(surveyUserApiLib.rating, { rating });
      yield put(surveyUserAction.ratingSuccess());
    } else {
      throw new Error('no payment info');
    }
  } catch (error) {
    // console.log(error)
    yield put(surveyUserAction.ratingFailure({ error }));
  }
}

export function* watchSurveyUserList() {
  yield takeLatest(surveyUserAction.list, list);
}
export function* watchSurveyUser() {
  yield takeLatest(surveyUserAction.rating, rating);
}

function* rootSaga() {
  yield all([
    fork(watchSurveyUserList),
    fork(watchSurveyUser),
  ]);
}

export default rootSaga;
