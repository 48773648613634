import * as apiLib from './api';

export const categoryList = async ({ brandId }) => {
  try {
    const data = {
      brandId,
    };

    const result = await apiLib.fetchStoreApi('/order-app/category/list', data);

    if (result.resultFlag) {
      return result.categoryList;
    } else {
      throw Object.assign(new Error('App::Category List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const categoryInfo = async ({ categoryId }) => {
  try {
    const data = {
      categoryId,
    };

    const result = await apiLib.fetchStoreApi('/order-app/category/info', data);

    if (result.resultFlag) {
      return result.categoryInfo;
    } else {
      throw Object.assign(new Error('App::Category Info Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const foodCategoryList = async ({ brandId, categoryType }) => {
  try {
    const data = {
      brandId,
      categoryType,
    };

    const result = await apiLib.fetchStoreApi('/order-app/category/food-list', data);

    if (result.resultFlag) {
      return result.categoryList;
    } else {
      throw Object.assign(new Error('App::food ategory List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};



