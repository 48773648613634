import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as customerSurveyLogApiLib from '../lib/customerSurveyLogApi';
import { surveyLogAction } from './customerSurveySlice';

function* surveyLogList({ payload }) {
  try {
    const alarmSendLog = yield call(customerSurveyLogApiLib.list, payload);

    yield put(surveyLogAction.listSuccess({ alarmSendLog }));
  } catch (error) {}
}

function* surveyLogUpdate({ payload }) {
  try {
    yield call(customerSurveyLogApiLib.update, payload);
    const alarmSendLogtEST = yield call(customerSurveyLogApiLib.list, payload);
    yield put(surveyLogAction.updateSuccess({ alarmSendLog: alarmSendLogtEST })); //
  } catch (error) {}
}

export function* watchSurveyLogList() {
  yield takeLatest(surveyLogAction.list, surveyLogList);
}
export function* watchSurveyLogUpdate() {
  yield takeLatest(surveyLogAction.update, surveyLogUpdate);
}

function* rootSaga() {
  yield all([fork(watchSurveyLogList), fork(watchSurveyLogUpdate)]);
}

export default rootSaga;
