import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface SurveyState {
  surveyList: surveyList[];
  surveyItem: SurveyInfo;
  selectedOrderId: string;
  isOrderWithBasket: {};
  actionResult: string;
  isLoading: boolean;
  error: null | string;
}

const surveyInitialState: SurveyState = {
  surveyList: [],
  surveyItem: {},
  selectedOrderId: '',
  isOrderWithBasket: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: SurveyState) => {
    state.actionResult = '';
  },
  list: (state: SurveyState, { payload }: PayloadAction<SurveyState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: SurveyState, { payload }: PayloadAction<SurveyState>) => {
    state.surveyList = payload.surveyList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: SurveyState, { payload }: PayloadAction<SurveyState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  item: (state: SurveyState, { payload }: PayloadAction<SurveyState>) => {
    state.isLoading = true;
    state.actionResult = 'ITEM_REQ';
    state.error = '';
  },
  itemSuccess: (state: SurveyState, { payload }: PayloadAction<SurveyState>) => {
    state.surveyItem = payload.surveyItem;
    state.isLoading = false;
    state.actionResult = 'ITEM_OK';
    state.error = '';
  },
  itemFailure: (state: SurveyState, { payload }: PayloadAction<SurveyState>) => {
    state.isLoading = false;
    state.actionResult = 'ITEM_ERR';
    state.error = payload.error;
  },
  rating: (state: SurveyState, { payload }: PayloadAction<SurveyState>) => {
    state.isLoading = true;
    state.actionResult = 'RATING_REQ';
    state.error = '';
  },
  ratingSuccess: (state: SurveyState, { payload }: PayloadAction<SurveyState>) => {
    state.isLoading = false;
    state.actionResult = 'RATING_OK';
    state.error = '';
  },
  ratingFailure: (state: SurveyState, { payload }: PayloadAction<SurveyState>) => {
    state.isLoading = false;
    state.actionResult = 'RATING_ERR';
    state.error = payload.error;
  },
};

const slice = createSlice({
  name: 'survey',
  initialState: surveyInitialState,
  reducers: reducers,
});

const selectSurveyList = createDraftSafeSelector(
  (state: SurveyState) => state.surveyList,
  surveyList => surveyList,
);
const selectSurveyItem = createDraftSafeSelector(
  (state: SurveyState) => state.surveyItem,
  surveyItem => surveyItem,
);
const selectStatus = createDraftSafeSelector(
  (state: SurveyState) => state.actionResult,
  (state: SurveyState) => state.isLoading,
  (state: SurveyState) => state.error,
  (state: SurveyState) => state.isOrderWithBasket,
  (actionResult, isLoading, error, isOrderWithBasket) => ({ actionResult, isLoading, error, isOrderWithBasket }),
);

export const surveySelector = {
  surveyList: state => selectSurveyList(state[SURVEY]),
  surveyItem: state => selectSurveyItem(state[SURVEY]),
  status: state => selectStatus(state[SURVEY]),
};

export const SURVEY = slice.name;
export const surveyReducer = slice.reducer;
export const surveyAction = slice.actions;
