import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { printCustomerNm } from '../../utils/space';

import { customerSelector } from '../../modules/customerSlice';
import { configAction, configSelector } from '../../modules/configSlice';

import { ReactComponent as ClosedIcon } from '../../assets/images/navigation/icon-closed.svg';

const NavBar = ({ isMenuOpen, toggleMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customerInfo = useSelector(customerSelector.customerInfo);
  const langCd = useSelector(configSelector.langCd);
  const { t } = useTranslation();

  const changeLocation = useCallback(
    url => {
      navigate(url);
    },
    [navigate],
  );

  const changeLangCode = useCallback(() => {
    dispatch(configAction.changeLangCd(langCd === 'KR' ? 'EN' : 'KR'));
  }, [dispatch, langCd]);

  return (
    <>
      <Container isMenuOpen={isMenuOpen}>
        <div className="header">
          <button onClick={() => toggleMenu()}>
            <ClosedIcon />
          </button>
        </div>
        <div className="body">
          <div className="customer">
            <div className="customer-row">
              <div className="customer-name">
                {printCustomerNm(customerInfo.spaceId, customerInfo?.customerNm, customerInfo?.customerEngNm)}
              </div>
              <div className="customer-nim">{t('NIM')}</div>
            </div>
            <div className="customer-row">
              <div className="customer-page" onClick={() => changeLocation('/customer/profile')}>
                {t('TITLE_MYPAGE')} >
              </div>
            </div>
          </div>
          <ul className="menu">
            <li className="menu-item" onClick={() => changeLocation('/order/list')}>
              {t('ORDER_LIST')}
            </li>

            <li className="menu-item" onClick={() => changeLangCode()}>
              {t('LANGCODE_NM')}
            </li>
          </ul>
        </div>
        <div className="footer">
          <div className="logout" onClick={() => changeLocation('/auth/logout')}>
            {t('LOGOUT')}
          </div>
        </div>
      </Container>
      <Background style={isMenuOpen ? { display: 'block' } : { display: 'none' }} onClick={() => toggleMenu()} />
    </>
  );
};

const Container = styled.div`
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 73.34%;
  max-width: 21.875rem;
  display: flex;
  flex-direction: column;
  left: ${({ isMenuOpen }) => (isMenuOpen ? 0 : '-21.875rem')};
  transition: all 0.3s ease-out;
  position: fixed;
  background: ${({ theme }) => theme.subBackground};
  z-index: 40;

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 2.75rem;
    padding: 0 1.25rem;

    & > button {
      margin-left: auto;
      background: none;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding: 0 1.5rem;
    border-top: ;

    .customer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 2.1875rem 0.625rem 1.875rem;
      border-bottom: 0.0625rem solid ${({ theme }) => theme.line};
      overflow: hidden;
      &-row {
        display: flex;
        align-items: flex-end;
      }

      &-page {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 100%;
        color: #999999;
      }
      &-name {
        overflow: hidden;
        font-weight: 500;
        font-size: 1.75rem;
        color: #000000;
      }
      &-nim {
        font-weight: 500;
        font-size: 1.75rem;
        color: #000000;
      }
      &-setting {
        margin-left: 10px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px dashed #000000;
        background-color: #ccc;
      }
    }

    .menu {
      padding: 1.875rem 0.625rem;

      .menu-item + .menu-item {
        margin-top: 1.875rem;
      }

      &-item {
        font-weight: 500;
        font-size: 1.0625rem;
        color: #333333;
      }
    }
  }

  .footer {
    height: 6.25rem;
    padding: 0 1.5rem 0;
    .logout {
      padding-top: 1.875rem;
      padding-left: 0.625rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.0625rem;
      color: #333333;
      border-top: 0.0625rem solid ${({ theme }) => theme.line};
    }
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 39;
  background: rgba(0, 0, 0, 0.1);
`;

export default React.memo(NavBar);
