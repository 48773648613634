import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as alarmLogApiLib from '../lib/alarmLogApi';
import { alarmLogAction } from './alarmLogSlice';

function* alarmLogList({ payload }) {
  try {
    const alarmSendLog = yield call(alarmLogApiLib.list, payload);
    const sort = alarmSendLog.sort((a, b) => new Date(b.regDt) - new Date(a.regDt));

    yield put(alarmLogAction.listSuccess({ alarmSendLog: sort }));
  } catch (error) {}
}

export function* watchAlarmLogList() {
  yield takeLatest(alarmLogAction.list, alarmLogList);
}

function* rootSaga() {
  yield all([fork(watchAlarmLogList)]);
}

export default rootSaga;
