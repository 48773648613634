import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import BackgroundLoading from '../../components/BackgroundLoading';
import { orderAction, orderSelector } from '../../modules/orderSlice';
import { categoryGroupSelector } from '../../modules/categoryGroupSlice';
import { goodsAction, goodsSelector } from '../../modules/goodsSlice';
import { shopSelector } from '../../modules/shopSlice';
import RobotFail from '../../assets/images/robot/icon_robot_fail.png';
import RobotPrev from '../../assets/images/robot/icon_robot_prev.png';
import RobotCheck from '../../assets/images/robot/icon_robot_check.png';
import RobotStart from '../../assets/images/robot/icon_robot_start.png';
import RobotArrive from '../../assets/images/robot/icon_robot_arrive.png';
import RobotEnd from '../../assets/images/robot/icon_robot_end.png';

const OrderRobotDelivery = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const orderInfo = useSelector(orderSelector.orderDetail);
  const { actionResult: orderActionResult } = useSelector(orderSelector.status);
  useEffect(() => {
    const orderId = sessionStorage.getItem('selectOrderId');
    dispatch(orderAction.detail({ orderId }));
  }, [dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      const orderId = sessionStorage.getItem('selectOrderId');
      dispatch(orderAction.detail({ orderId }));
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const closeRobotDeli = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Container billType={orderInfo.billType}>
      {orderActionResult === 'DETAIL_REQ' ? (
        <BackgroundLoading position="absolute" bacgroundColor="inherit" />
      ) : (
        <>
          <div className="body">
            {(orderInfo?.billType === 'RETURN' ||
              orderInfo?.billType === 'CANCEL' ||
              orderInfo?.robotDeliveryStatus === 'canceled' ||
              orderInfo?.robotDeliveryStatus === 'aborted' ||
              orderInfo?.robotDeliveryInfo?.reasonType === 'NOSHOW' ||
              orderInfo?.robotDeliveryInfo?.reasonType === 'RETURN' ||
              orderInfo?.robotDeliveryInfo?.reasonType === 'TIMEOUT') && (
              <>
                <img src={RobotFail} width={120} />
                <div className="top">
                  <div className="title">로봇 배달 실패</div>
                  <div className="sub-title">배달이 정상적으로 완료되지 못하였습니다.</div>
                  <div className="desc">노쇼가 아닌 경우는 환불됩니다.</div>
                </div>
              </>
            )}
            {orderInfo?.billType !== 'RETURN' &&
              orderInfo?.billType !== 'CANCEL' &&
              orderInfo?.robotDeliveryStatus !== 'canceled' &&
              orderInfo?.robotDeliveryStatus !== 'aborted' &&
              (orderInfo.robotDeliveryStatus === 'waiting' ||
                orderInfo.robotDeliveryInfo?.actionStatus === 'accepted' ||
                !orderInfo.robotDeliveryInfo?.actionStatus) && (
                <>
                  <img src={RobotPrev} width={120} />
                  <div className="top">
                    <span className="title">{t('PROCCESSING')}</span>
                    <span className="sub-title">{t('PROCCESSING_MENT')}</span>
                  </div>
                  <div className="comment">
                    {t('PROCCESSING_SUBMENT1')} <br /> {t('PROCCESSING_SUBMENT2')}
                  </div>
                  <div className="robotWrapper">
                    <div className="robotTitle">{t('ROBOT_DELIVERY_TITLE')}</div>
                    <div className="robotDesc">{t('ROBOT_DELIVERY_DESC')}</div>
                  </div>
                </>
              )}
            {orderInfo?.billType !== 'RETURN' &&
              orderInfo?.billType !== 'CANCEL' &&
              orderInfo?.robotDeliveryStatus !== 'canceled' &&
              orderInfo?.robotDeliveryStatus !== 'aborted' &&
              (orderInfo.robotDeliveryInfo?.actionStatus === 'pickup_doing' ||
                orderInfo.robotDeliveryInfo?.actionStatus === 'pickup_wating' ||
                orderInfo.robotDeliveryInfo?.actionStatus === 'pickup_done' ||
                orderInfo.robotDeliveryInfo?.actionStatus === 'pickup_init') && (
                <>
                  <img src={RobotCheck} width={120} />
                  <div className="top">
                    <div className="title">주문 확인 완료</div>
                    <div className="desc">카페에서 음료 제조중입니다.</div>
                  </div>
                </>
              )}
            {orderInfo?.billType !== 'RETURN' &&
              orderInfo?.billType !== 'CANCEL' &&
              orderInfo?.robotDeliveryStatus !== 'canceled' &&
              orderInfo?.robotDeliveryStatus !== 'aborted' &&
              orderInfo.robotDeliveryInfo?.actionStatus === 'dropoff_doing' && (
                <>
                  <img src={RobotStart} width={120} />
                  <div className="top">
                    <div className="title">로봇 배달 출발</div>
                    <div className="sub-title">배달이 시작되었습니다.</div>
                    <div className="desc">
                      주문 취소는 불가능합니다.
                      <br />
                      배달까지 다소 시간이 소요될 수 있습니다.
                    </div>
                  </div>
                </>
              )}
            {orderInfo?.billType !== 'RETURN' &&
              orderInfo?.billType !== 'CANCEL' &&
              orderInfo?.robotDeliveryStatus !== 'canceled' &&
              orderInfo?.robotDeliveryStatus !== 'aborted' &&
              orderInfo.robotDeliveryInfo?.actionStatus === 'dropoff_waiting' && (
                <>
                  <img src={RobotArrive} width={120} />
                  <div className="top">
                    <div className="title">로봇 배달 도착</div>
                    <div className="sub-title">{orderInfo.takeZone}에서 기다리고 있습니다.</div>
                    <div className="desc">로봇에 발급받으신 비밀번호를 입력해주세요.</div>
                    <div className="deliveryInfoBox">
                      <div className="deliveryRow">배송장소 : {orderInfo.takeZone}</div>
                      <div className="deliveryRow">담당로봇 : {orderInfo.robotDeliveryInfo?.robotName ?? ''}</div>
                      <div className="deliveryRow">비밀번호 : {orderInfo.robotDeliveryInfo?.drawerPwd ?? ''}</div>
                    </div>
                  </div>
                </>
              )}
            {orderInfo?.billType !== 'RETURN' &&
              orderInfo?.billType !== 'CANCEL' &&
              orderInfo?.robotDeliveryStatus !== 'canceled' &&
              orderInfo?.robotDeliveryStatus !== 'aborted' &&
              orderInfo.robotDeliveryInfo?.actionStatus === 'dropoff_done' && (
                <>
                  <img src={RobotEnd} width={120} />
                  <div className="top">
                    <div className="title">배달 완료</div>
                    <div className="desc">
                      주문하신 음료를 받지 못하셨다면<br></br>카페에 문의해주세요.
                    </div>
                  </div>
                </>
              )}
          </div>
        </>
      )}
      <div className="bottomBtnWrapper">
        <div
          className="bottomBtn"
          onClick={() => {
            closeRobotDeli();
          }}
        >
          닫기
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background: inherit;
      .comment {
      font-size: 0.9375rem;
      line-height: 150%;
      text-align: center;
    }
.robotDesc{
    font-family: Apple SD Gothic Neo;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    padding: 10px 20px;
    color: #666666;
    width: 100%;
  }
  .robotTitle{
    margin-top: 40px;
    margin-bottom: 20px;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;  
    text-align: center; 
    color: #00C2DC;
  }
  .robotIconName{
    margin-left: 3px;
  }
  .robotWrapper {
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 315px;
    height: 165px;
    margin-top: 20px;
    background: #f9f9f9;
    border-radius: 20px;
  }
  .robotIconRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end
    width: 100px; 
  }
  .robotIconBox {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 15px;
    margin-right: 15px;
    width: 81px;
    height: 31px;
    background: #ffffff;
    border: 1px solid #c8f8ff;
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 100%;
    text-align: center;
    color: #00c2dc;
  }
  .deliveryInfoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 315px;
    height: 125px;
    background: #f9f9f9;
    border-radius: 20px;
    padding-left: 2rem;
    padding-top: 0.7rem;
    margin-top: 1rem;
  }
  .deliveryRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: #666666;
    font-weight: 500;
    font-size: 0.8675rem;
    margin-bottom: 0.8rem;
  }
  .bottomBtnWrapper {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .bottomBtn {
    width: 112px;
    height: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333333;
    border-radius: 30px;
  }
  .body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2.5rem;

    .top {
      display: flex;
      flex-direction: column;
      margin-bottom: 3.125rem;
      text-align: center;
      .title {
        color: #000000;
        font-weight: 700;
        font-size: 1.3625rem;
        margin-bottom: 0.625rem;
        margin-top: 1.5rem;
      }

      .sub-title {
        font-weight: 500;
        font-size: 0.9375rem;
        color: #666666;
      }
      .desc {
        font-weight: 500;
        font-size: 0.9675rem;
        color: #333333;
        line-height: 1.5;
        margin-top: 0.8rem;
      }
    }
  }
`;

export default React.memo(OrderRobotDelivery);
