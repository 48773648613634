import * as apiLib from './api';

export const cafeGoodsList = async ({ brandId, shopId }) => {
  try {
    const data = {
      brandId,
      shopId
    };

    const result = await apiLib.fetchStoreApi('/order-app/goods/cafe-list', data);

    if (result.resultFlag) {
      return result.goodsList;
    } else {
      throw Object.assign(new Error('App::Goods Cafe List Api error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const foodGoodsList = async ({ brandId, shopId,startDt,endDt,goodsType }) => {
  try {


    let result = [];
    if(goodsType==='DOSIRAK'){
      const data = {
        brandId,
        shopId
      };
      result = await apiLib.fetchStoreApi('/order-app/goods/dosirak-list', data);
    }else{
      const data = {
        brandId,
        shopId,
        startDt,
        endDt
      };
      result = await apiLib.fetchStoreApi('/order-app/goods/food-list', data);
    }


    if (result.resultFlag) {
      return result.goodsList;
    } else {
      throw Object.assign(new Error('App::Goods Food List Api error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};


export const currentGoodsList = async ({ brandId, shopId,goodsIdList }) => {
  try {
    const data = {
      shopId,
      goodsIdList
    };

    const result = await apiLib.fetchStoreApi('/order-app/goods/current-list', data);

    if (result.resultFlag) {
      return result.goodsList;
    } else {
      throw Object.assign(new Error('App::Current Goods List Api error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};


export const cafeGoodsDetail = async ({ goodsId, shopId, brandId }) => {
  try {
    const data = {
      goodsId,
      shopId,
      brandId,
    };

    const result = await apiLib.fetchStoreApi('/order-app/goods/cafe-detail', data);
    if (result.resultFlag) {
      return result.goodsInfo;
    } else {
      throw Object.assign(new Error('goods info error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const foodGoodsDetail = async ({ goodsId, shopId, brandId  }) => {
  try {
    const data = {
      brandId,
      shopId,
      goodsId
    };

    const result = await apiLib.fetchStoreApi('/order-app/goods/food-detail', data);
    if (result.resultFlag) {
      return result.goodsInfo;
    } else {
      throw Object.assign(new Error('Goods Detail Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
