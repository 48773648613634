import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface CustomerInfo {
  customerEmail: string;
  customerId: string;
  customerNm: string;
  customerNmEn: string;
  customerNo: string;
  customerType: string;
  delDt: null;
  delYn: string;
  email: string;
  empNo: string;
  jobClassCode: string;
  jobClassNameEn: string;
  jobClassNameKo: string;
  jobClassOrder: number;
  modDt: string;
  modId: string;
  orgCode: string;
  orgName: string;
  regDt: string;
  regId: string;
  rfidList: string[];
  rootOrgCode: string;
  rootOrgName: string;
  spaceId: string;
  upn: string;
  userName: string;
  userNameEn: string;
  userState: number;
  useYn: string;
  barcodeYn: string;
}

interface CustomerState {
  customerInfo: CustomerInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const customerInitialState: CustomerState = {
  customerInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  info: (state: CustomerState, { payload }: PayloadAction<CsutomerState>) => {
    state.isLoading = true;
    state.actionResult = 'INFO_REQ';
    state.error = '';
  },
  infoSuccess: (state: CustomerState, { payload }: PayloadAction<CustomerState>) => {
    state.customerInfo = payload.customerInfo;
    state.isLoading = false;
    state.actionResult = 'INFO_OK';
    state.error = '';
  },
  infoFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'INFO_ERR';
    state.error = action.payload;
  },
  update: (state: CustomerState, { payload }: PayloadAction<CsutomerState>) => {
    state.isLoading = true;
    state.actionResult = 'UPDATE_REQ';
    state.error = '';
  },
  updateSuccess: (state: CustomerState, { payload }: PayloadAction<CustomerState>) => {
    state.customerInfo = { ...state.customerInfo, ...payload.updateInfo };
    state.isLoading = false;
    state.actionResult = 'UPDATE_OK';
    state.error = '';
  },
  updateFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'UPDATE_ERR';
    state.error = action.payload;
  },
  remove: (state: CustomerState, { payload }: PayloadAction<CsutomerState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: CustomerState, { payload }: PayloadAction<CustomerState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  passwordChange: (state: CustomerState, { payload }: PayloadAction<CsutomerState>) => {
    state.isLoading = true;
    state.actionResult = 'PASSWORD_CHANGE_REQ';
    state.error = '';
  },
  passwordChangeSuccess: (state: CustomerState, { payload }: PayloadAction<CustomerState>) => {
    state.customerInfo = payload.customerInfo;
    state.isLoading = false;
    state.actionResult = 'PASSWORD_CHANGE_OK';
    state.error = '';
  },
  passwordChangeFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'PASSWORD_CHANGE_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: CustomerState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'customer',
  initialState: customerInitialState,
  reducers: reducers,
});

const selectCustomerInfo = createDraftSafeSelector(
  (state: CustomerState) => state.customerInfo,
  customerInfo => customerInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: CustomerState) => state.isLogin,
  (state: CustomerState) => state.actionResult,
  (state: CustomerState) => state.isLoading,
  (state: CustomerState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const customerSelector = {
  status: state => selectStatus(state[CUSTOMER]),
  customerInfo: state=> selectCustomerInfo(state[CUSTOMER])
};

export const CUSTOMER = slice.name;
export const customerReducer = slice.reducer;
export const customerAction = slice.actions;
