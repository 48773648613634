import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as ratingTagApiLib from '../lib/ratingTagApi';
import { ratingTagAction } from './ratingTagSlice';

function* ratingTagList({ payload }) {
  try {
    const tagList = yield call(ratingTagApiLib.ratingTagList, payload);
    
    yield put(ratingTagAction.ratingTagListSuccess({ tagList }));
  } catch (error) {}
}

export function* watchRatingTagList() {
  yield takeLatest(ratingTagAction.ratingTagList, ratingTagList);
}

function* rootSaga() {
  yield all([fork(watchRatingTagList)]);
}

export default rootSaga;
