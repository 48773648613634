import React from 'react';

import Modal from '../../../components/Modal';

const NoRezTimeModal = ({
  isOpen,
  closeModal,
}) => {
  return (
    <Modal
      visible={isOpen}
      onConfirm={() => closeModal()}
      onClose={() => closeModal()}
      hideXButton
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0.9375rem 0 1.1875rem' }}>예약이 가능한 시간대가 없습니다.</div>
    </Modal>
  );
};

export default React.memo(NoRezTimeModal);
