import { all, fork, call, put, select, takeLatest } from 'redux-saga/effects';
import { ratingAction } from './ratingSlice';

import * as ratingApiLib from '../lib/ratingApi';

function* list({ payload }) {
  try {
    let ratingList = yield call(ratingApiLib.list, { ...payload });

    ratingList = ratingList.map(rating => ({
      ...rating,
    }));
    yield put(ratingAction.listSuccess({ ratingList }));
  } catch (error) {
    yield put(ratingAction.listFailure({ error }));
  }
}

function* rating({ payload }) {
  try {
    const ratingInfo = yield call(ratingApiLib.rating, payload);

    if (ratingInfo) {

      yield call(ratingApiLib.rating, { rating });
      yield put(ratingAction.ratingSuccess());
    } else {
      throw new Error('no payment info');
    }
  } catch (error) {
    yield put(ratingAction.ratingFailure({ error }));
  }
}

export function* watchRatingList() {
  yield takeLatest(ratingAction.list, list);
}
export function* watchRating() {
  yield takeLatest(ratingAction.rating, rating);
}

function* rootSaga() {
  yield all([
    fork(watchRatingList),
    fork(watchRating),
  ]);
}

export default rootSaga;
