import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import lottie from 'lottie-web';
import loadingAnimation from '../assets/images/lotties/loading.json';

const BackgroundLoading = ({ className, position, text, bacgroundColor }) => {
  const lottieContainer = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingAnimation,
    });
  }, []);

  return (
    <Background className={className} position={position} bacgroundColor={bacgroundColor}>
      <div ref={lottieContainer} style={{ height: '5em', width: '5em' }} />
      {text && text}
    </Background>
  );
};

const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: ${({ position }) => (position ? position : 'fixed')};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ bacgroundColor }) => (bacgroundColor ? bacgroundColor : 'rgba(0, 0, 0, 0.4)')};
  z-index: 9999;
  font-weight: bold;
  font-size: 1.5625rem;
  color: #000000;
`;

export default BackgroundLoading;
