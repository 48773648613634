import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { shopAction } from './shopSlice';

import * as shopApiLib from '../lib/shopApi';

function* list({ payload }) {
  try {
    const shopList = yield call(shopApiLib.list, payload);

    yield put(shopAction.listSuccess({ shopList }));
  } catch (error) {
    yield put(shopAction.listFailure({ error }));
  }
}

function* info({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.info, payload);

    yield put(shopAction.infoSuccess({ shopInfo }));
  } catch (error) {
    yield put(shopAction.infoFailure({ error }));
  }
}

export function* watchShopList() {
  yield takeLatest(shopAction.list, list);
}

export function* watchShopInfo() {
  yield takeLatest(shopAction.info, info);
}

function* rootSaga() {
  yield all([fork(watchShopList), fork(watchShopInfo)]);
}

export default rootSaga;
