import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RobotIcon } from '../../assets/images/common/ico-robot.svg';
import Button from '../../components/Button';
import RobotPrev from '../../assets/images/robot/icon_robot_prev.png';

const PaymentRobotComplete = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const goHome = useCallback(() => {
    navigate('/main', { replace: true });
  }, [navigate]);

  const goOrderList = useCallback(() => {
    navigate('/order/list', { replace: true });
  }, [navigate]);

  return (
    <Container>
      {/*{shopInfo.shopType === 'CAFE' ? (*/}
      <div className="body">
        <img src={RobotPrev} width={120} />
        <div className="top">
          <span className="title">{t('PROCCESSING')}</span>
          <span className="sub-title">{t('PROCCESSING_MENT')}</span>
        </div>
        <div className="comment">
          {t('PROCCESSING_SUBMENT1')} <br /> {t('PROCCESSING_SUBMENT2')}
        </div>
        <div className="robotWrapper">
          <div className="robotTitle">{t('ROBOT_DELIVERY_TITLE')}</div>
          <div className="robotDesc">{t('ROBOT_DELIVERY_DESC')}</div>
        </div>
      </div>
      <div className="empty" />
      <div className="footer">
        <Button size="large" color="unselect" onClick={() => goOrderList()}>
          {t('ORDER_LIST')}
        </Button>
        <Button size="large" color="select" onClick={() => goHome()}>
          {t('GO_HOME')}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background: inherit;
  .robotDesc{
    font-family: Apple SD Gothic Neo;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    padding: 10px 20px;
    width: 100%;
  }
  .robotTitle{
    margin-top: 40px;
    margin-bottom: 30px;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;  
    text-align: center; 
    color: #00C2DC;
  }
  .robotIconName{
    margin-left: 3px;
  }
  .robotWrapper {
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 315px;
    height: 185px;
    margin-top: 20px;
    background: #f9f9f9;
    border-radius: 20px;
  }
  .robotIconRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end
    width: 100px; 
  }
  .robotIconBox {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 15px;
    margin-right: 15px;
    width: 81px;
    height: 31px;
    background: #ffffff;
    border: 1px solid #c8f8ff;
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 100%;
    text-align: center;
    color: #00c2dc;
  }
  .body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2.5rem;

    .top {
      display: flex;
      flex-direction: column;
      margin-bottom: 3.125rem;
      text-align: center;
      .title {
        color: #000000;
        font-weight: bold;
        font-size: 1.5625rem;
        margin-bottom: 0.625rem;
      }

      .sub-title {
        font-size: 0.9375rem;
        color: #666666;
      }
    }
    .comment {
      font-size: 0.9375rem;
      line-height: 150%;
      text-align: center;
    }
  }

  .empty {
    width: 100%;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.line};
    height: 5.625rem;
  }

  .footer {
    height: 5rem;
    padding: 0.9375rem 0.9375rem 0.9375rem;
    display: flex;

    & > button:first-child {
      color: #333333;
    }
    & > button {
      flex: 1;
    }
  }
`;

export default React.memo(PaymentRobotComplete);
