import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { shopSelector } from '../../modules/shopSlice';
import { orderAction, orderSelector } from '../../modules/orderSlice';
import { customerSelector } from '../../modules/customerSlice';
import { authSelector } from '../../modules/authSlice';

import CafePayment from './CafePayment';
import FoodPayment from './FoodPayment';

import Loading from '../../components/Loading';

import WarnMaxDeliOrderCntModal from './modal/WarnMaxDeliOrderCntModal';
import CafePaymentRobot from './CafePaymentRobot';
import {isMatchRobot} from "../../utils/space";

const PaymentRobot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const shopInfo = useSelector(shopSelector.shopInfo);
  const customerInfo = useSelector(customerSelector.customerInfo);
  const loginInfo = useSelector(authSelector.loginInfo);
  const { actionResult, error } = useSelector(orderSelector.status);

  const [isOpenWarnMaxDeliOrderCntModal, setIsOpenWarnMaxDeliOrderCntModal] = useState(false);

  const paymentOrderItemList = useRef(JSON.parse(sessionStorage.getItem('paymentOrderItemList')) || []);
  const paymentType = useRef(sessionStorage.getItem('paymentType') || 'SINGLE');
  const currentOrderItemList = useRef(JSON.parse(localStorage.getItem('currentOrderItemList')) || {});
  const [isShopUseRobot, setIsShopUseRobot] = useState('N');
  const [isOrderRobot, setIsOrderRobot] = useState(false);
  // const [currentOrderItemList, ] = useState(JSON.parse(localStorage.getItem('currentOrderItemList')) || {});
  // const [currentOrderItemList,setCurrentOrderItemList] = useState(JSON.parse(localStorage.getItem('currentOrderItemList')) || {});

  useEffect(() => {
    if (isMatchRobot(shopInfo?.shopId)) {
      setIsShopUseRobot(shopInfo?.pickupTypeList['robot']);
    }
  }, [shopInfo]);
  useEffect(() => {
    if (actionResult === 'PAYMENT_OK') {
      dispatch(orderAction.actionResultClear());
      if (paymentType.current === 'BASKET') {
        localStorage.removeItem('basketOrderItemList');
      }

      if (shopInfo.shopType === 'CAFE') {
        let currentList = [];
        if (currentOrderItemList.current[shopInfo.shopId] !== undefined) {
          currentList = currentOrderItemList.current[shopInfo.shopId];
        }

        paymentOrderItemList.current?.forEach(orderItem => {
          // currentList = currentList.filter(currentOrder => currentOrder.goodsId !== orderItem.goodsId);
          // currentList.unshift(orderItem);
          currentList = currentList.filter(currentOrder => currentOrder !== orderItem.goodsId);
          currentList.unshift(orderItem.goodsId);
        });

        if (currentList.length > 5) {
          currentOrderItemList.current[shopInfo.shopId] = currentList.slice(0, 5);
        } else {
          currentOrderItemList.current[shopInfo.shopId] = currentList;
        }
        localStorage.setItem('currentOrderItemList', JSON.stringify(currentOrderItemList.current));
      }
      if (isOrderRobot) {
        navigate('/paymentRobot/complete');
      } else {
        navigate('/payment/complete');
      }
    } else if (actionResult === 'PAYMENT_ERR') {
      navigate('/error');
    }
  }, [dispatch, navigate, actionResult, error, currentOrderItemList, shopInfo.shopId, shopInfo.shopType]);

  const openWarnMaxDeliOrderCntModal = useCallback(() => {
    setIsOpenWarnMaxDeliOrderCntModal(true);
  }, []);

  const closeWarnMaxDeliOrderCntModal = useCallback(() => {
    setIsOpenWarnMaxDeliOrderCntModal(false);
  }, []);

  const totalInfoObj = useMemo(() => {
    let totalAmt = 0;
    let totalQty = 0;
    let totalEnr = 0;
    let totalOrg = 0;

    paymentOrderItemList.current?.forEach(orderItem => {
      let calAmt = orderItem.totalAmt < 0 ? 0 : orderItem.totalAmt;
      totalAmt += calAmt;
      totalQty += orderItem.itemQty;
      totalEnr += orderItem.totalEnr;
      totalOrg += orderItem.totalOrg;
    });

    return { totalAmt, totalEnr, totalQty, totalOrg };
  }, []);

  const orderProcess = useCallback(
    ({ rezForm, deliForm, isPickup, goodsType, payReason, pickupSelect, robotOrderId }) => {
      const spaceId = shopInfo.spaceId;
      const placeId = shopInfo.placeId;
      const brandId = shopInfo.brandId;
      const shopId = shopInfo.shopId;

      const isCheckedSaveLocalStorageTakeMsg = isPickup
        ? rezForm?.isCheckedSaveLocalStorageTakeMsg
        : deliForm?.isCheckedSaveLocalStorageTakeMsg;
      const takeMsg = isPickup ? (rezForm?.pickupType === 'NOW' ? '' : rezForm.takeMsg) : deliForm?.takeMsg;

      if (!isPickup && totalInfoObj.totalQty < shopInfo.deliveryOrderCnt) {
        openWarnMaxDeliOrderCntModal();
        return;
      }

      //2024.01.16 optionList 없는 주문 방어 로직
      const itemList = paymentOrderItemList.current;
      const newItemList = [];
      itemList.forEach(item => {
        if (!Object.keys(item).includes('optionList')) {
          console.log('optionList key not include');
          item['optionList'] = [];
        } else {
          console.log('optionList key include');
        }

        newItemList.push(item);
      });
      // console.log('is Equal: ', JSON.stringify(itemList) === JSON.stringify(newItemList));

      const order = {
        spaceId,
        placeId,
        brandId,
        shopId,
        deviceId: customerInfo.customerId,
        deviceNm: 'Mobile',
        customerId: customerInfo.customerId,
        customerNm: customerInfo.customerNm,
        customerNo: customerInfo.customerNo,
        waitNm: customerInfo.customerNm,
        goodsType,
        //로봇배송시 오더아이디 추가
        robotOrderId: robotOrderId,
        orderType: pickupSelect,
        robotDeliveryStatus: 'waiting',
        //로봇타입 추가
        takeType:
          pickupSelect === 'robot'
            ? 'ROBOT'
            : isPickup
            ? rezForm?.pickupType === 'NOW'
              ? 'IN'
              : 'REZ'
            : deliForm?.deliType === 'NOW'
            ? 'DELI'
            : 'DELI_REZ',
        takeTime:
          pickupSelect === 'robot'
            ? ''
            : isPickup
            ? rezForm?.pickupType !== 'NOW'
              ? rezForm?.timeList?.[rezForm?.selectTimeIndex]
              : ''
            : deliForm?.deliType !== 'NOW'
            ? deliForm?.deliveryTimeList?.[deliForm?.selectTimeIndex]
            : '',
        takeMsg: !(isPickup && rezForm?.pickupType === 'NOW') && takeMsg,
        takeZone:
          pickupSelect === 'robot'
            ? deliForm?.robotDeliveryZoneList[deliForm?.selectZoneIndex]?.name
            : !isPickup && deliForm?.deliveryZoneList[deliForm?.selectZoneIndex],
        orderDt: dayjs(shopInfo.currentOpenDt).format('YYYYMMDD'),
        totalOrg: totalInfoObj.totalOrg,
        totalEnr: totalInfoObj.totalEnr,
        totalQty: totalInfoObj.totalQty,
        totalSer: 0,
        itemList: newItemList,
        payType: 'EMP',
        paymentType: 'APP',

        payReason: payReason || 'NORMAL',
        company: customerInfo.company || '',
        robotDeliveryInfo: pickupSelect === 'robot' ? { poi: deliForm?.robotDeliveryZoneList[deliForm?.selectZoneIndex]?.poi } : {},
      };

      const payment = {
        spaceId,
        placeId,
        brandId,
        shopId,
        customerId: customerInfo.customerId,
        paymentAmt: totalInfoObj.totalAmt,
        paymentKey: customerInfo.customerId,
        fcmToken: loginInfo.fcmToken,
      };

      if (pickupSelect === 'robot') {
        setIsOrderRobot(true);
        dispatch(orderAction.paymentRobot({ order, payment }));
      } else {
        setIsOrderRobot(false);
        dispatch(orderAction.payment({ order, payment }));
      }

      if ((isPickup && rezForm?.pickupType === 'REZ') || !isPickup) {
        if (isCheckedSaveLocalStorageTakeMsg) {
          localStorage.setItem('isCheckedSaveLocalStorageTakeMsg', isCheckedSaveLocalStorageTakeMsg);
          localStorage.setItem('takeMsg', takeMsg);
        } else {
          localStorage.removeItem('isCheckedSaveLocalStorageTakeMsg');
          localStorage.removeItem('takeMsg');
        }
      }

      sessionStorage.removeItem('paymentOrderItemList');
      sessionStorage.removeItem('paymentType');
      sessionStorage.removeItem('selectedCategoryId');
    },
    [dispatch, openWarnMaxDeliOrderCntModal, shopInfo, customerInfo, loginInfo, totalInfoObj],
  );

  return (
    <>
      {actionResult === 'PAYMENT_REQ' || actionResult === 'PAYMENT_ROBOT_REQ' ? (
        <Loading text={shopInfo.shopType === 'CAFE' ? t('CAFEPAY') : t('SHOPPAY')} />
      ) : (
        <>
          {shopInfo.shopType === 'CAFE' && isShopUseRobot === 'Y' ? (
            <CafePaymentRobot orderProcess={orderProcess} totalInfoObj={totalInfoObj} />
          ) : (
            <CafePayment orderProcess={orderProcess} totalInfoObj={totalInfoObj} />
          )}
          {shopInfo.shopType === 'FOODCOURT' && <FoodPayment orderProcess={orderProcess} totalInfoObj={totalInfoObj} />}
        </>
      )}
      <WarnMaxDeliOrderCntModal
        isOpen={isOpenWarnMaxDeliOrderCntModal}
        closeModal={closeWarnMaxDeliOrderCntModal}
        deliveryOrderCnt={shopInfo.deliveryOrderCnt}
      />
    </>
  );
};

export default React.memo(PaymentRobot);
