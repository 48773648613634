import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import { ReactComponent as CheckIcon } from '../../../assets/images/common/icon-check.svg';

const DeliTimeListModal = ({
  isOpen,
  changeDeliForm,
  closeModal,
  timeList,
  selectTimeIndex,
}) => {
  const timeRef = useRef();

  const [selectIndex, setSelectIndex] = useState(selectTimeIndex);

  const clickTime = useCallback(index => {
    setSelectIndex(index);
  }, []);

  useEffect(() => {
    clickTime(selectTimeIndex);
    const selectTime = timeRef.current?.getElementsByClassName('select')?.[0];
    timeRef.current.scrollTo({ top: selectTime.scrollIntoView() });
  }, [clickTime, selectTimeIndex]);

  const clickOk = useCallback(() => {
    changeDeliForm('selectTimeIndex', selectIndex);
    changeDeliForm('deliType', 'REZ');
    closeModal();
  }, [selectIndex, changeDeliForm, closeModal]);

  return (
    <Modal
      visible={isOpen}
      onConfirm={() => clickOk()}
      onClose={() => closeModal()}
      onCancel={() => closeModal()}
      hideXButton
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1.5625rem 0 2rem', fontWeight: 'bold'}}>가지러올 시간을 선택해주세요</div>
      <Container ref={timeRef}>
        {timeList?.map((time, index) => (
          <div
            key={`time-${index}`}
            className={selectIndex === index ? 'time select' : 'time'}
            onClick={() => clickTime(index)}
          >
            {selectIndex === index && <CheckIcon />}
            {time}
          </div>
        ))}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden overlay;
  align-items: center;
  height: 12.5rem;

  .time {
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 100%;
    text-align: center;
    color: #666666;
  }

  .select {
    position: relative;
    font-weight: bold;
    font-size: 0.9375rem;
    color: #000000;
    & > svg {
      position: absolute;
      left: -1.25rem;
      & > path {
        stroke: #000000;
      }
    }
  }
  .time + .time {
    margin-top: 1.25rem;
  }
`;

export default React.memo(DeliTimeListModal);
