import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as goodsTagApiLib from '../lib/goodsTagApi';
import { goodsTagAction } from './goodsTagSlice';

function* goodsTagList({ payload }) {
  try {
    const tagList = yield call(goodsTagApiLib.goodsTagList, payload);
    console.log('tagList', tagList);

    yield put(goodsTagAction.goodsTagListSuccess({ tagList }));
  } catch (error) {}
}

export function* watchGoodsTagList() {
  yield takeLatest(goodsTagAction.goodsTagList, goodsTagList);
}

function* rootSaga() {
  yield all([fork(watchGoodsTagList)]);
}

export default rootSaga;
