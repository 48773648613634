import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../components/Checkbox';
import RadioCheckbox from '../../components/RadioCheckbox';
import SelectInput from '../../components/SelectInput';

import DeliTimeListModal from './modal/DeliTimeListModal';
import NoRezTimeModal from './modal/NoRezTimeModal';

const PaymentDeli = ({ deliForm, changeDeliForm }) => {
  const { t } = useTranslation();

  const deliveryZoneList = useRef(
    deliForm.deliveryZoneList && deliForm.deliveryZoneList?.length > 0
      ? deliForm.deliveryZoneList.map((zone, index) => ({
          value: `zone-${index}`,
          title: zone,
        }))
      : [{ value: 0, title: 'none' }],
  );

  const [selectedZone, setSelectedZone] = useState(deliveryZoneList.current[0].value);

  useEffect(() => {
    setSelectedZone(deliveryZoneList.current[deliForm.selectZoneIndex]?.value);
  }, [deliForm.selectZoneIndex]);

  const [isOpenDeliTimeListModal, setIsOpenDeliTimeListModal] = useState(false);
  const [isOpenNoRezTimeModal, setIsOpenNoRezTimeModal] = useState(false);

  const changeDeliveryZone = useCallback(
    value => {
      changeDeliForm(
        'selectZoneIndex',
        deliveryZoneList.current?.findIndex(zone => zone.value === value),
      );
    },
    [changeDeliForm],
  );

  const openNoRezTimeModal = useCallback(() => {
    setIsOpenNoRezTimeModal(true);
  }, []);

  const closeNoRezTimeModal = useCallback(() => {
    setIsOpenNoRezTimeModal(false);
  }, []);

  const openDeliTimeListModal = useCallback(() => {
    setIsOpenDeliTimeListModal(true);
  }, []);

  const closeDeliTimeListModal = useCallback(() => {
    setIsOpenDeliTimeListModal(false);
  }, []);

  // const checkRezDeliveryRadioCheckbox = useCallback(
  //   e => {
  //     if (e.target.checked) {
  //       if (deliForm.deliveryTimeList.length <= 0) {
  //         openNoRezTimeModal();
  //       } else {
  //         openDeliTimeListModal();
  //       }
  //     }
  //   },
  //   [deliForm.deliveryTimeList, openNoRezTimeModal, openDeliTimeListModal],
  // );

  return (
    <Container>
      <div className="row">
        <span className="title">{t('DELIVERY_PLACE')}</span>
        <SelectInput optionList={deliveryZoneList.current} selectedValue={selectedZone} onSelectChange={changeDeliveryZone} />
      </div>
      <div className="row">
        <span className="title">{t('MEMO')}</span>
        <Input value={deliForm.takeMsg} onChange={e => changeDeliForm('takeMsg', e.target.value)} />
        <label className="next-checkbox-label">
          <Checkbox
            checked={deliForm.isCheckedSaveLocalStorageTakeMsg}
            onChange={e => changeDeliForm('isCheckedSaveLocalStorageTakeMsg', e.target.checked)}
          />
          <span>{t('MEMO_AGAIN')}</span>
        </label>
      </div>
      <div className="row">
        <span className="title">{t('DELIVERY_TIME')}</span>
        {/* <label className="radio-label">
          <RadioCheckbox checked={deliForm.deliType === 'NOW'} onChange={() => changeDeliForm('deliType', 'NOW')} />
          <span className="pickup">바로 배달</span>
        </label> */}
        <label className="radio-label">
          <RadioCheckbox checked={deliForm.deliType === 'REZ'} onChange={e => {}} />
          <span className="pickup">{t('DELIVERY_SCHEDULED_TIME')} </span>
          <button
            className="pickup-button"
            onClick={() => {
              if (deliForm.deliveryTimeList.length <= 0) {
                openNoRezTimeModal();
              } else {
                openDeliTimeListModal();
              }
            }}
          >
            {t('TIME_CHANGE')}
          </button>
          <span className="pickup-desc pickup-desc-morebottom">{t('DELIVERY_SCHEDULED_MENT')}</span>
        </label>
      </div>
      {deliForm.deliveryTimeList.length > 0 ? (
        <DeliTimeListModal
          isOpen={isOpenDeliTimeListModal}
          closeModal={closeDeliTimeListModal}
          timeList={deliForm.deliveryTimeList}
          selectTimeIndex={deliForm.selectTimeIndex}
          changeDeliForm={changeDeliForm}
        />
      ) : (
        <NoRezTimeModal isOpen={isOpenNoRezTimeModal} closeModal={closeNoRezTimeModal} />
      )}
    </Container>
  );
};

const Container = styled.div`
  .row {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: bold;
      font-size: 0.9375rem;
      color: #333333;
      margin-bottom: 0.625rem;
    }

    .next-checkbox-label {
      display: flex;
      align-items: center;
      margin-top: 0.625rem;
      & > span {
        margin-left: 0.5rem;
        font-size: 0.9375rem;
        color: #333333;
      }
    }
  }
  .pickup {
    width: 100%;

    & > span {
      line-height: 1.875rem;
    }
  }

  .pickup-button {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 4.375rem;
    height: 1.875rem;
    background: #eeeeee;
    border-radius: 0.9375rem;
    font-weight: bold;
    font-size: 0.8125rem;
    color: #666666;
  }

  .row + .row {
    margin-top: 1.5625rem;
  }
  .radio-label + .radio-label {
    margin-top: 0.9375rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5625rem;
    font-weight: bold;
    font-size: 1.0625rem;
    color: #000000;
  }

  .body {
    padding: 0.3125rem 0 0.8125rem;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 2.5rem;
  background: #ffffff;
  border: 0.0625rem solid #cccccc;
  border-radius: 0.3125rem;
`;

export default React.memo(PaymentDeli);
