import { Component, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import lottie from 'lottie-web';

import errorIcon from '../../assets/images/lotties/errorIcon.json';
import Button from '../../components/Button';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMsg: '',
      isNetworkError: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState(state => ({
      ...state,
      error: true,
      isNetworkError: error.message.indexOf('Loading') >= 0,
      errorMsg: error.message,
    }));
  }

  initErrorState() {
    this.setState(state => ({
      ...state,
      error: false,
      errorMsg: '',
      isNetworkError: false,
    }));
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorCompoent
          isNetworkError={this.state.isNetworkError}
          errorMsg={this.state.errorMsg}
          initErrorState={this.initErrorState.bind(this)}
        />
      );
    }

    return this.props.children;
  }
}

const ErrorCompoent = ({ isNetworkError, errorMsg, initErrorState }) => {
  const lottieContainer = useRef();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [showErrorMsgCnt, setShowErrorMsgCnt] = useState(0); // !아이콘 20번 클릭 에러 메세지 보이기

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: errorIcon,
    });
  }, []);

  const reStart = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQ_RESTART' }));
    } else if (window.FlutterWebView) {
      window.FlutterWebView.postMessage(JSON.stringify({ type: 'REQ_RESTART' }));
    } else {
      navigate('/');
    }
    initErrorState();
  };

  const plusShowErrorMsgCnt = () => {
    setShowErrorMsgCnt(showErrorMsgCnt + 1);
  };

  return (
    <Error>
      <div className="body">
        <div onClick={() => plusShowErrorMsgCnt()}>
          <div ref={lottieContainer} style={{ height: '2.75rem', width: '2.75rem' }} />
        </div>
        <div className="error-title">{t('ERROR_DEFAULT_TITLE')}</div>
        <div className="error-body">{t('ERROR_DEFAULT_BODY')}</div>
        <div className="error-body">{isNetworkError ? t('ERROR_NETWORK_CHK') : t('ERROR_UNDER_RESTART')}</div>
        <div className="error-body">{showErrorMsgCnt > 20 && errorMsg}</div>
      </div>
      <div className="footer">
        <Button size="large" color="select" onClick={() => reStart()}>
          {t('RESTART')}
        </Button>
      </div>
    </Error>
  );
};

const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background: #ffffff;
  .body {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 3.125rem;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .error-title {
    margin-top: 1.5625rem;
    margin-bottom: 0.9375rem;
    font-size: 1.0625rem;

    color: ${({ theme }) => theme.black};
  }
  .error-body {
    display: flex;
    font-size: 0.9375rem;
    color: ${({ theme }) => theme.gray600};
    line-height: 130%;
  }

  .footer {
    height: 5rem;
    padding: 0.9375rem 0.9375rem 0.9375rem;
    display: flex;
    width: 100%;
    & > button {
      flex: 1;
    }
  }
`;

export default ErrorBoundary;
