import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { shopSelector } from '../../modules/shopSlice';

import Button from '../../components/Button';

import FoodPaymentDeli from './FoodPaymentDeli';

import PaymentConfirmModal from './modal/PaymentConfirmModal';
import {printTotal} from "../../utils/orderCommon";

const FoodPayment = ({ totalInfoObj, orderProcess }) => {
  const goodsType = 'DOSIRAK';

  const shopInfo = useSelector(shopSelector.shopInfo);

  const { t } = useTranslation();

  const [isPickup, setIsPickup] = useState(false);
  const [rezForm, setRezForm] = useState({
    pickupType: 'DELI_REZ',
  });
  const [deliForm, setDeliForm] = useState({
    deliType: 'REZ',
    takeMsg: '',
  });
  const [isOpenPaymentConfirmModal, setIsOpenPaymentConfirmModal] = useState(false);

  const paymentOrderItemList = useRef(JSON.parse(sessionStorage.getItem('paymentOrderItemList')));

  useEffect(() => {
    const isCheckedSaveLocalStorageTakeMsg = localStorage.getItem('isCheckedSaveLocalStorageTakeMsg') || false;
    const takeMsg = localStorage.getItem('takeMsg') || '';
    setDeliForm(deliForm => ({
      ...deliForm,
      deliveryTimeList: shopInfo.deliveryTimeList,
      deliveryZoneList: shopInfo.deliveryZoneList,
      selectTimeIndex: 0,
      selectZoneIndex: 0,
      isCheckedSaveLocalStorageTakeMsg,
      takeMsg,
    }));
  }, [shopInfo]);

  const changeDeliForm = useCallback((key, value) => {
    setDeliForm(deliForm => ({ ...deliForm, [key]: value }));
  }, []);

  const openPaymentConfirmModal = useCallback(() => {
    setIsOpenPaymentConfirmModal(true);
  }, []);

  const closePaymentConfirmModal = useCallback(() => {
    setIsOpenPaymentConfirmModal(false);
  }, []);

  return (
    <Container>
      <div className="header">
        <div className="shop-name">{shopInfo.shopNm}</div>
        <div className="goods-name">
          {t('NAME', { krNm: paymentOrderItemList.current?.[0].itemFNm, enNm: paymentOrderItemList.current?.[0].itemEngFNm })}{' '}
          {printTotal(totalInfoObj, paymentOrderItemList.current)}
        </div>
        <div className="header-desc-wrapper">
          <div className="header-desc-title">{t('DELIBERY_RESERVATION')}</div>
          <div className="header-desc-body">{t('DELIBERY_RESERVATION_MENT')}</div>
        </div>
      </div>
      <div className="body">
        {(deliForm?.selectTimeIndex || deliForm?.selectTimeIndex === 0) && (
          <FoodPaymentDeli deliForm={deliForm} changeDeliForm={changeDeliForm} openDt={shopInfo.currentOpenDt} />
        )}
      </div>
      <div className="footer">
        {shopInfo.openState !== 'Y' ? (
          <Button size="large" color="disable" disable>
            {t('SHOP_CLOSE')}
          </Button>
        ) : (
          <Button size="large" color="select" onClick={() => openPaymentConfirmModal()}>
            <span className="item-length-circle">{totalInfoObj.totalQty}</span>
            {t('ORDER')}
          </Button>
        )}
      </div>
      <PaymentConfirmModal
        isOpen={isOpenPaymentConfirmModal}
        closeModal={closePaymentConfirmModal}
        orderProcess={() => orderProcess({ deliForm, rezForm, isPickup, goodsType })}
        comment={`${t('NAME', { krNm: paymentOrderItemList.current?.[0].itemFNm, enNm: paymentOrderItemList.current?.[0].itemEngFNm })} ${
          printTotal(totalInfoObj, paymentOrderItemList.current)
        }`}
        deliveryTime="11:00"
        deliveryZone={deliForm?.deliveryZoneList?.[deliForm?.selectZoneIndex]}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background: inherit;

  .loading {
    background-color: inherit;
  }

  & > .header {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.25rem;
    border-bottom: 0.5rem solid ${({ theme }) => theme.line};
    .shop-name {
      font-weight: bold;
      font-size: 1.375rem;
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 0.625rem;
    }
    .goods-name {
      font-weight: 500;
      font-size: 0.9375rem;
      color: #000000;
      margin-bottom: 0.9375rem;
    }
    .header-desc-wrapper {
      margin-top: auto;
      background-color: ${({ theme }) => theme.gray50};
      border-radius: 0.8125rem;
      padding: 0.9375rem;
      .header-desc-title {
        font-size: 0.9375rem;
        margin-bottom: 0.3125rem;
      }
      .header-desc-body {
        font-size: 0.8125rem;
        color: ${({ theme }) => theme.gray700};
        line-height: 160%;
      }
    }
  }

  & > .body {
    flex: 1;
    padding: 1.25rem;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.line};
    overflow: hidden overlay;
  }
  & > .footer {
    height: 5rem;
    padding: 0.9375rem 0.9375rem 0.9375rem;
    display: flex;
    & > button {
      flex: 1;
    }
    .item-length-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 50%;
      background: #ffffff;
      color: #333333;
      margin-right: 0.3125rem;
    }
  }
`;

export default React.memo(FoodPayment);
