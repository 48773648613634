import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import Background from '../background/Background';

const GoodsDetailLayout = () => {
  return (
    <Wrap>
      <Background>
        <Outlet />
      </Background>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.mainBackground};
  z-index: 99;
`;

export default React.memo(GoodsDetailLayout);
