import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';

import * as goodsApiLib from '../lib/goodsApi';

import { goodsAction } from './goodsSlice';
import { SHOP } from './shopSlice';

function* cafeGoodsList({ payload }) {
  try {
    const { shopId } = yield select(state => state[SHOP].shopInfo);
    const goodsList = yield call(goodsApiLib.cafeGoodsList, { ...payload, shopId });

    yield put(goodsAction.cafeGoodsListSuccess({ goodsList }));
  } catch (err) {
    yield put(goodsAction.cafeGoodsListFailure('Goods List Error'));
  }
}

function* cafeGoodsDetail({ payload }) {
  try {
    const { shopId, brandId } = yield select(state => state[SHOP].shopInfo);

    const goodsInfo = yield call(goodsApiLib.cafeGoodsDetail, { ...payload, shopId, brandId });

    yield put(goodsAction.cafeGoodsDetailSuccess({ goodsInfo }));
  } catch (error) {
    yield put(goodsAction.cafeGoodsDetailFailure({ error }));
  }
}

function* foodGoodsList({ payload }) {
  try {
    const goodsList = yield call(goodsApiLib.foodGoodsList, payload);

    yield put(goodsAction.foodGoodsListSuccess({ goodsList }));
  } catch (err) {
    yield put(goodsAction.foodGoodsListFailure('Goods List Error'));
  }
}

function* foodGoodsDetail({ payload }) {
  try {
    const { shopId, brandId } = yield select(state => state[SHOP].shopInfo);
    const goodsInfo = yield call(goodsApiLib.foodGoodsDetail, { ...payload, shopId, brandId });

    yield put(goodsAction.foodGoodsDetailSuccess({ goodsInfo }));
  } catch (error) {
    yield put(goodsAction.foodGoodsDetailFailure({ error }));
  }
}

function* currentGoodsList({ payload }) {
  try {
    const goodsList = yield call(goodsApiLib.currentGoodsList, payload);

    yield put(goodsAction.currentGoodsListSuccess({ goodsList }));
  } catch (err) {
    yield put(goodsAction.currentGoodsListFailure('Goods List Error'));
  }
}

export function* watchCafeGoodsList() {
  yield takeLatest(goodsAction.cafeGoodsList, cafeGoodsList);
}

export function* watchCafeGoodsDetail() {
  yield takeLatest(goodsAction.cafeGoodsDetail, cafeGoodsDetail);
}

export function* watchFoodGoodsDetail() {
  yield takeLatest(goodsAction.foodGoodsDetail, foodGoodsDetail);
}

export function* watchFoodGoodsList() {
  yield takeLatest(goodsAction.foodGoodsList, foodGoodsList);
}

export function* watchCurrentGoodsList() {
  yield takeLatest(goodsAction.currentGoodsList, currentGoodsList);
}

function* rootSaga() {
  yield all([
    fork(watchCafeGoodsList),
    fork(watchCafeGoodsDetail),
    fork(watchFoodGoodsDetail),
    fork(watchFoodGoodsList),
    fork(watchCurrentGoodsList),
  ]);
}

export default rootSaga;
