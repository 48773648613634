import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { shopSelector } from '../../modules/shopSlice';
import { useNavigate } from 'react-router-dom';
import RadioCheckbox from '../../components/RadioCheckbox';
import Button from '../../components/Button';

// import CafePaymentRez from './CafePaymentRez';
import CafePaymentDeli from './CafePaymentDeli';

import { orderAction, orderSelector } from '../../modules/orderSlice';

import WarnPayReasonModal from './modal/WarnPayReasonModal';
import NoRezTimeModal from './modal/NoRezTimeModal';
import { customerSelector } from '../../modules/customerSlice';
import { isKakaoSpace, isPayReasonEnabled } from '../../utils/space';
import CafePaymentDeliRobot from './CafePaymentDeliRobot';
import PaymentConfirmModalRobot from './modal/PaymentConfirmModalRobot';
import RobotReserveModalRobot from './modal/RobotReserveModalRobot';
import RobotReserveCancleModalRobot from './modal/RobotReserveCancleModalRobot';
import RobotAlertModal from './modal/RobotAlertModal';
import {isPickupShop, printTotal} from '../../utils/orderCommon';
import Loading from '../../components/Loading';

const CafePaymentRobot = ({ totalInfoObj, orderProcess }) => {
  const goodsType = 'CAFE';

  const shopInfo = useSelector(shopSelector.shopInfo);
  const dispatch = useDispatch();
  const { actionResult, error } = useSelector(orderSelector.status);
  const { t } = useTranslation();

  const [isPickup, setIsPickup] = useState(true);
  const [isDisableRobot, setIsDisableRobot] = useState(true);
  const [robotDisableStr, setRobotDisableStr] = useState('');
  const [pickupSelect, setPickupSelect] = useState('pickup');
  const [payReason, setPayReason] = useState('NORMAL');
  const [rezForm, setRezForm] = useState({
    pickupType: 'NOW',
    takeMsg: '',
  });
  const [deliForm, setDeliForm] = useState({
    deliType: 'REZ',
    takeMsg: '',
  });
  const [isOpenPaymentConfirmModal, setIsOpenPaymentConfirmModal] = useState(false);
  const [isOpenNoRezTimeModal, setIsOpenNoRezTimeModal] = useState(false);
  const [isOpenWarnPayReasonModal, setIsOpenWarnPayReasonModal] = useState(false);

  const paymentOrderItemList = useRef(JSON.parse(sessionStorage.getItem('paymentOrderItemList')));
  const customerInfo = useSelector(customerSelector.customerInfo);

  const [isOpenReserveRobotModal, setIsOpenReserveRobotModal] = useState(false);
  const [isOpenReserveCancleRobotModal, setIsOpenReserveCancleRobotModal] = useState(false);
  const [isOpenAlertRobotModal, setIsOpenAlertRobotModal] = useState(false);
  const [isRobotReserve, setIsRobotReserve] = useState(false); // 로봇 배송 완료 여부
  const [isRobotReserveCancle, setIsRobotReserveCancle] = useState(true); // 로봇 배송 완료 여부
  const [isRobotPayClick, setIsRobotPayClick] = useState(false); // 로봇 배송 완료 여부
  const [robotOrderId, setRobotOrderId] = useState(''); // 로봇 배송 오더 아이디
  const [commentFlag, setCommentFlag] = useState('');
  const [timeoutCnt, setTimeoutCnt] = useState(0);
  const [robotPoi, setRobotPoi] = useState('');

  useEffect(() => {
    //배차 예약 및 취소
    console.log(actionResult);
    dispatch(orderAction.actionResultClear());
    if (actionResult === 'ROBOT_RESERVE_OK') {
      setIsRobotReserve(true);
      setTimeoutCnt(0);
      setIsOpenReserveRobotModal(false);
    } else if (actionResult === 'ROBOT_RESERVE_ERR') {
      setPickupSelect('pickup');
      setCommentFlag(error);
      setIsRobotReserve(false);
      setIsOpenReserveRobotModal(false);
      disableRobot(false);
      openAlertRobotModal();
    } else if (actionResult === 'ROBOT_CHECK_OK') {
      setIsOpenPaymentConfirmModal(true);
    } else if (actionResult === 'ROBOT_CHECK_ERR') {
      setPickupSelect('pickup');
      setIsRobotReserve(false);
      reserveCancle();
      setCommentFlag('time');
      openAlertRobotModal();
    } else if (actionResult === 'ROBOT_ACCEPT_OK') {
      setIsRobotReserveCancle(false);
      setIsRobotPayClick(false);
      orderProcess({ deliForm, rezForm, isPickup, goodsType, payReason, pickupSelect, robotOrderId });
    } else if (actionResult === 'ROBOT_ACCEPT_ERR') {
      setIsOpenPaymentConfirmModal(false);
      setPickupSelect('pickup');
      setIsRobotReserve(false);
      reserveCancle();
      setCommentFlag('accept');
      setIsRobotPayClick(false);
      openAlertRobotModal();
    } else if (actionResult === 'ROBOT_RESERVE_CANCLE_OK') {
      if (robotPoi === '') {
        disableRobot(false);
      } else {
        setIsRobotReserve(false);
        setTimeoutCnt(0);
      }
      closePaymentConfirmModal();
    } else if (actionResult === 'ROBOT_RESERVE_CANCLE_ERR') {
      disableRobot(false);
    } else if (actionResult === 'ROBOT_CHECK_ERR') {
      disableRobot(false);
    }
  }, [dispatch, actionResult, error]);

  useEffect(() => {
    console.log('배차 취소');
    console.log('robotOrderId', robotOrderId);
    if (isRobotReserveCancle) {
      setPickupSelect('pickup');
      if (robotOrderId !== '') {
        console.log('배차 취소 요청', robotOrderId);
        dispatch(orderAction.robotReserveCancle({ robotOrderId }));
      }
    }
    let startTime = dayjs().startOf('day');

    const nowTime = dayjs();

    const nowHour = Number(dayjs(nowTime).format('HH'));
    const nowMinute = Number(dayjs(nowTime).format('mm'));

    const celHour = nowHour * 2;
    const celMin = 0 <= nowMinute && nowMinute < 15 ? 0 : 15 <= nowMinute && nowMinute < 30 ? 1 : 30 <= nowMinute && nowMinute < 45 ? 2 : 3;

    const selectRezTimeIndex = celHour + celMin;

    let timeList = [];
    const division = 15; // 30분 마다 (정각으로 끊을꺼 예상)

    let addTime = 0;

    while (addTime < 1440) {
      timeList.push(startTime.add(addTime, 'minute').format('HH:mm'));

      addTime += division;
    }

    const isCheckedSaveLocalStorageTakeMsg = localStorage.getItem('isCheckedSaveLocalStorageTakeMsg') || false;
    const takeMsg = localStorage.getItem('takeMsg') || '';

    setRezForm(rezForm => ({ ...rezForm, timeList, selectTimeIndex: selectRezTimeIndex, isCheckedSaveLocalStorageTakeMsg, takeMsg }));

    const currentTime = dayjs().add(15, 'minute').format('HH:mm');
    console.log(shopInfo.robotDeliveryZoneList);
    setDeliForm(deliForm => ({
      ...deliForm,
      deliveryTimeList: shopInfo.deliveryTimeList?.filter(time => time >= currentTime),
      deliveryZoneList: shopInfo.deliveryZoneList,
      robotDeliveryZoneList: shopInfo.robotDeliveryZoneList ?? [],
      robotDeliveryOrderCnt: shopInfo.robotDeliveryOrderCnt ?? 0,
      robotDeliveryTimeout: shopInfo.robotDeliveryTimeout ?? 3,
      selectTimeIndex: 0,
      selectZoneIndex: 0,
      isCheckedSaveLocalStorageTakeMsg,
      takeMsg,
    }));
  }, [shopInfo]);

  useEffect(() => {
    //로봇배송 가능 여부 체크
    if (paymentOrderItemList.current?.filter(item => item?.useRobotYn === 'N').length > 0) {
      setIsDisableRobot(true);
      setRobotDisableStr('goods');
    } else if (shopInfo?.robotDeliveryOrderCnt < totalInfoObj.totalQty) {
      setIsDisableRobot(true);
      setRobotDisableStr('cnt');
    } else {
      setIsDisableRobot(false);
    }
  }, [shopInfo, totalInfoObj.totalQty]);

  //로봇 배송 배차 취소 체크
  useEffect(() => {
    console.log(timeoutCnt);
    if (timeoutCnt >= deliForm.robotDeliveryTimeout && isRobotReserve) {
      setPickupSelect('pickup');
      setIsRobotReserve(false);
      reserveCancle();
      setCommentFlag('time');
      openAlertRobotModal();
    }
  }, [isRobotReserve, deliForm.robotDeliveryTimeout, timeoutCnt]);

  //로봇 배송 배차 취소 체크
  useEffect(() => {
    const timer = setInterval(() => {
      //로봇 배송 예약을 했는지 여부
      if (isRobotReserve) {
        setTimeoutCnt(prev => prev + 1);
      }
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [isRobotReserve, timeoutCnt]);

  //로봇 배송 배차 취소 체크
  useEffect(() => {
    return () => {
      if (isRobotReserveCancle) {
        console.log('배차 취소 시작');
        console.log('robotOrderId', robotOrderId);
        reserveCancle();
      }
    };
  }, [isRobotReserveCancle]);

  useEffect(() => {
    /*
    1. 로봇 배송 예약했을때
      - 로봇외 선택시 취소할건지 팝업 -> 확인 시 배차 취소
    2. 로봇 배송 예약안했을때
      - 로봇 배송 선택시 예약하기 -> 배차없거나 실패시 비활성화 및 팝업
    */
    console.log('pickupSelect : ' + deliForm?.selectZoneIndex);
    setIsPickup(pickupSelect === 'pickup' ? true : false);
    if (pickupSelect === 'robot') {
      if (isRobotReserve && deliForm?.robotDeliveryZoneList?.[deliForm?.selectZoneIndex].poi !== robotPoi) {
        reserveCancle();
      }
      if (!isRobotReserve) {
        openReserveRobotModal();
        const orderId = changeRobotOrderId();
        const orderInfo = {};
        orderInfo.goods_list = [];

        for (const orderItem of paymentOrderItemList.current) {
          let item: any = {};
          item.goods_name = orderItem.itemFNm;
          item.count = orderItem.itemQty;
          orderInfo.goods_list.push(item);
        }
        orderInfo.destination_poi = deliForm?.robotDeliveryZoneList?.[deliForm?.selectZoneIndex].poi;
        setRobotPoi(deliForm?.robotDeliveryZoneList?.[deliForm?.selectZoneIndex].poi);
        setRobotOrderId(orderId);
        dispatch(orderAction.robotReserve({ orderId, orderInfo }));
      }
    } else {
      //로봇 배송 예약을 했을 경우 취소 api 호출
      if (isRobotReserve) {
        setIsRobotReserve(false);
        setRobotPoi('');
        reserveCancle();
      }
    }
  }, [pickupSelect, isRobotReserve, deliForm?.selectZoneIndex]);

  const changeRobotOrderId = () => {
    //로봇배송 오더아이디 만들기
    const timestamp = Date.now();
    const orderId = customerInfo.customerId + '-' + timestamp;
    return orderId;
  };

  const disableRobot = value => {
    setIsDisableRobot(value);
    setIsRobotReserve(false);
    //pickupSelect === 'robot' && setPickupSelect('pickup');
    setIsPickup(true);
    setRobotOrderId('');
    setTimeoutCnt(0);
    setRobotPoi('');
  };
  const changeDeliForm = useCallback((key, value) => {
    setDeliForm(deliForm => ({ ...deliForm, [key]: value }));
  }, []);

  const setFailedRobotDeli = useCallback(() => {
    setIsOpenPaymentConfirmModal(false);
    setPickupSelect('pickup');
    setIsRobotReserve(false);
    reserveCancle();
    setCommentFlag('accept');
    openAlertRobotModal();
  }, []);

  const openReserveRobotModal = useCallback(() => {
    setIsOpenReserveRobotModal(true);
  }, []);
  const closeReserveRobotModal = useCallback(() => {
    setIsOpenReserveRobotModal(false);
    setIsOpenReserveCancleRobotModal(false);
  }, []);
  const openAlertRobotModal = useCallback(() => {
    setIsOpenAlertRobotModal(true);
  }, []);
  const closeAlertRobotModal = useCallback(() => {
    setIsOpenAlertRobotModal(false);
    isRobotReserve && disableRobot(false);
  }, [disableRobot, robotPoi]);
  const openReserveRobotCancleModal = useCallback(() => {
    setIsOpenReserveCancleRobotModal(true);
  }, []);
  const closeReserveRobotCancleModal = useCallback(() => {
    setIsOpenReserveCancleRobotModal(false);
  }, []);

  const reserveCancle = useCallback(() => {
    console.log('배차 취소');
    console.log('robotOrderId', robotOrderId);
    if (robotOrderId !== '') {
      console.log('배차 취소 요청', robotOrderId);
      dispatch(orderAction.robotReserveCancle({ robotOrderId }));
    }
    closeReserveRobotModal();
  }, [robotOrderId]);

  const openPaymentConfirmModal = useCallback(() => {
    if (isPayReasonEnabled(shopInfo) && !payReason) {
      setIsOpenWarnPayReasonModal(true);
    } else {
      if (!isRobotReserve && pickupSelect === 'robot') {
        reserveCancle();
        setCommentFlag('poi');
        openAlertRobotModal();
      } else {
        if (pickupSelect === 'robot') {
          dispatch(orderAction.robotCheck({ robotOrderId }));
        } else {
          setIsOpenPaymentConfirmModal(true);
        }
      }
    }
  }, [payReason, shopInfo?.spaceId, pickupSelect, isRobotReserve]);

  const closePaymentConfirmModal = useCallback(() => {
    setIsOpenPaymentConfirmModal(false);
  }, []);

  const openNoRezTimeModal = useCallback(() => {
    setIsOpenNoRezTimeModal(true);
  }, []);

  const closeNoRezTimeModal = useCallback(() => {
    setIsOpenNoRezTimeModal(false);
  }, []);

  const clickDeliveryRadio = useCallback(() => {
    if (deliForm.deliveryTimeList.length <= 0) {
      openNoRezTimeModal();
    } else {
      setIsPickup(false);
    }
  }, [openNoRezTimeModal, deliForm]);

  return (
    <>
      {isRobotPayClick && <Loading style={{ display: 'none' }} text={'로봇 배차 확인중입니다.'} />}
      <Container style={{ display: !isRobotPayClick ? '' : 'none' }}>
        <>
          <div className="header">
            <div className="shop-name">{shopInfo.shopNm}</div>
            <div className="goods-name">
              {t('NAME', { krNm: paymentOrderItemList.current?.[0].itemFNm, enNm: paymentOrderItemList.current?.[0].itemEngFNm })}{' '}
              {printTotal(totalInfoObj, paymentOrderItemList.current)}
            </div>
          </div>
          <div className="body">
            <div className="take-type">
              <label className="radio-label">
                <RadioCheckbox checked={pickupSelect === 'pickup'} onChange={() => setPickupSelect('pickup')} />
                <span className="pickup">{t('STORE_PICK')}</span>
                <span className="pickup-desc">{t('STORE_PICK_MENT')}</span>
              </label>
              {isPickupShop(shopInfo) && deliForm?.deliveryZoneList?.length > 0 && (
                <label className="radio-label">
                  <RadioCheckbox
                    checked={pickupSelect === 'reserve'}
                    onChange={() => {
                      clickDeliveryRadio();
                      setPickupSelect('reserve');
                    }}
                  />
                  <span className="pickup">{t('STORE_DELIVERY')}</span>
                  <span className="pickup-desc">{t('STORE_DELIVERY_MENT')}</span>
                </label>
              )}
              {shopInfo?.pickupTypeList?.robot === 'Y' && deliForm?.robotDeliveryZoneList?.length > 0 && (
                <label className="radio-label">
                  <RadioCheckbox checked={pickupSelect === 'robot'} disabled={isDisableRobot} onChange={() => setPickupSelect('robot')} />
                  <span className={isDisableRobot ? 'pickup-disabled' : 'pickup'}>{t('STORE_ROBOT_DELIVERY')}</span>
                  <span className="robot-reserve-end">{isRobotReserve ? t('ROBOT_RESERVE_END') : ''}</span>
                  <span className={isDisableRobot ? 'pickup-desc-disabled' : 'pickup-desc'}>{t('STORE_ROBOT_DELIVERY_MENT')}</span>
                  <span className="pickup-alert">
                    {robotDisableStr === 'goods'
                      ? `${t('ROBOT_DISABLE_ANOTHER_GOODS')}`
                      : robotDisableStr === 'cnt'
                      ? `${t('ROBOT_DISABLE_ORDER_CNT_FIRST')} ${deliForm?.robotDeliveryOrderCnt}${t('ROBOT_DISABLE_ORDER_CNT_LAST')}`
                      : ''}
                  </span>
                </label>
              )}
            </div>
            {isPayReasonEnabled(shopInfo) && (
              <div className="take-type">
                <span className="payreason">{t('PAY_REASON')}</span>
                <label className="radio-label">
                  <RadioCheckbox checked={payReason === 'NORMAL'} onChange={() => setPayReason('NORMAL')} />
                  <span style={{ textDecoration: 'underline' }} className="pickup">
                    {t('PERSONAL_RFID')}
                  </span>
                </label>
                {shopInfo?.shopId === '167272477358b8ed2b' && (
                  <label className="radio-label">
                    <RadioCheckbox checked={payReason === 'FOREIGN'} onChange={() => setPayReason('FOREIGN')} />
                    <span className="pickup">{t('COMMERCIAL_RFID')}</span>
                  </label>
                )}
                <label className="radio-label">
                  <RadioCheckbox checked={payReason === 'INTERVIEW'} onChange={() => setPayReason('INTERVIEW')} />
                  <span className="pickup">{t('ONEONONE_RFID')}</span>
                </label>
              </div>
            )}
            <div className="take-type-detail">
              {/* {isPickup
            ? (rezForm?.selectTimeIndex || rezForm?.selectTimeIndex === 0) && (
                <CafePaymentRez rezForm={rezForm} changeRezForm={changeRezForm} />
              )
            : (deliForm?.selectTimeIndex || deliForm?.selectTimeIndex === 0) && (
                <CafePaymentDeli deliForm={deliForm} changeDeliForm={changeDeliForm} />
              )} */}
              {pickupSelect === 'reserve' && (deliForm?.selectTimeIndex || deliForm?.selectTimeIndex === 0) && (
                <CafePaymentDeli deliForm={deliForm} changeDeliForm={changeDeliForm} />
              )}
              {pickupSelect === 'robot' && <CafePaymentDeliRobot deliForm={deliForm} changeDeliForm={changeDeliForm} />}
            </div>
          </div>
          <div className="footer">
            {shopInfo.openState !== 'Y' ? (
              <Button size="large" color="disable" disable>
                {t('SHOP_CLOSE')}
              </Button>
            ) : (
              <Button size="large" color="select" onClick={() => openPaymentConfirmModal()}>
                <span className="item-length-circle">{totalInfoObj.totalQty}</span>
                {totalInfoObj.totalAmt?.toLocaleString()}
                {t('WON')} {t('STORE_CHECK_OUT')}
              </Button>
            )}
          </div>
          <PaymentConfirmModalRobot
            isOpen={isOpenPaymentConfirmModal}
            closeModal={closePaymentConfirmModal}
            orderProcess={() => {
              if (pickupSelect === 'robot') {
                const orderInfo = {};
                orderInfo.goods_list = [];
                for (const orderItem of paymentOrderItemList.current) {
                  let item: any = {};
                  item.goods_name = orderItem.itemFNm;
                  item.count = orderItem.itemQty;
                  orderInfo.goods_list.push(item);
                }
                orderInfo.orderId = robotOrderId;
                orderInfo.destination_poi = deliForm?.robotDeliveryZoneList?.[deliForm?.selectZoneIndex].poi;
                if (
                  deliForm?.robotDeliveryZoneList?.[deliForm?.selectZoneIndex].poi === '' ||
                  robotOrderId === '' ||
                  orderInfo.goods_list.length <= 0
                ) {
                  setFailedRobotDeli();
                } else {
                  console.log({
                    orderId: robotOrderId,
                    destination_poi: deliForm?.robotDeliveryZoneList?.[deliForm?.selectZoneIndex].poi,
                    orderInfo,
                  });
                  if (!isRobotPayClick) {
                    setIsRobotPayClick(true);
                    dispatch(
                      orderAction.robotAccept({
                        orderId: robotOrderId,
                        destination_poi: deliForm?.robotDeliveryZoneList?.[deliForm?.selectZoneIndex].poi,
                        orderInfo,
                      }),
                    );
                  }
                }
              } else {
                orderProcess({ deliForm, rezForm, isPickup, goodsType, payReason, pickupSelect, robotOrderId });
              }
            }}
            comment={`${t('NAME', {
              krNm: paymentOrderItemList.current?.[0].itemFNm,
              enNm: paymentOrderItemList.current?.[0].itemEngFNm,
            })} ${printTotal(totalInfoObj, paymentOrderItemList.current)}`}
            pickupSelect={pickupSelect}
            deliveryTime={deliForm?.deliveryTimeList?.[deliForm?.selectTimeIndex]}
            deliveryZone={deliForm?.deliveryZoneList?.[deliForm?.selectZoneIndex]}
            robotDeliveryZone={deliForm?.robotDeliveryZoneList?.[deliForm?.selectZoneIndex].name}
          />
          <RobotReserveModalRobot
            isOpen={isOpenReserveRobotModal}
            closeModal={closeReserveRobotModal}
            openConfirmCancle={openReserveRobotCancleModal}
          />
          <RobotReserveCancleModalRobot
            isOpen={isOpenReserveCancleRobotModal}
            closeModal={closeReserveRobotCancleModal}
            openConfirm={() => {
              reserveCancle();
            }}
          />
          <RobotAlertModal
            isOpen={isOpenAlertRobotModal}
            closeModal={closeAlertRobotModal}
            openConfirm={() => {
              closeAlertRobotModal();
            }}
            commentFlag={commentFlag}
          />
          {isPayReasonEnabled(shopInfo) && (
            <WarnPayReasonModal isOpen={isOpenWarnPayReasonModal} closeModal={() => setIsOpenWarnPayReasonModal(false)} />
          )}
          {deliForm.deliveryTimeList?.length <= 0 && <NoRezTimeModal isOpen={isOpenNoRezTimeModal} closeModal={closeNoRezTimeModal} />}
        </>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background: inherit;

  & > .header {
    display: flex;
    flex-direction: column;
    min-height: 5.9375rem;
    max-height: 5.9375rem;
    padding: 1.25rem 1.25rem;
    border-bottom: 0.5rem solid ${({ theme }) => theme.line};
    .shop-name {
      font-weight: bold;
      font-size: 1.375rem;
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .goods-name {
      margin-top: auto;
      font-weight: 500;
      font-size: 0.9375rem;
      color: #000000;
    }
  }

  & > .body {
    flex: 1;
    padding: 1.25rem;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.line};
    overflow: hidden overlay;
    .take-type {
      display: flex;
      flex-direction: column;
      border-bottom: 0.0625rem solid ${({ theme }) => theme.line};
      padding-bottom: 2.5rem;

      .radio-label + .radio-label {
        margin-top: 2.1875rem;
      }
    }

    .take-type + .take-type {
      margin-top: 20px;
    }

    .payreason {
      font-size: 15px;
      line-height: 100%;
      display: flex;
      align-items: center;
      color: #333333;
      margin-bottom: 20px;
    }
    .robot-reserve-end {
      color: #00c2dc;
      margin-left: 5px;
      font-size: 13px;
    }
    .radio-label {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      .pickup {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 0.625rem;
        font-size: 0.9375rem;
        color: #333333;
      }
      .pickup-disabled {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 0.625rem;
        font-size: 0.9375rem;
        color: #cccccc;
      }

      .pickup-desc {
        /* text-overflow: ellipsis;
        overflow: hidden; */
        // white-space: nowrap;
        width: 100%;
        position: absolute;
        bottom: -0.9375rem;
        // left: 32px;
        padding-left: 2rem;
        font-size: 0.8125rem;
        color: #999999;
      }
      .pickup-desc-disabled {
        /* text-overflow: ellipsis;
        overflow: hidden; */
        // white-space: nowrap;
        width: 100%;
        position: absolute;
        bottom: -0.9375rem;
        // left: 32px;
        padding-left: 2rem;
        font-size: 0.8125rem;
        color: #cccccc;
      }
      .pickup-alert {
        /* text-overflow: ellipsis;
        overflow: hidden; */
        // white-space: nowrap;
        width: 100%;
        position: absolute;
        bottom: -2.2375rem;
        // left: 32px;
        padding-left: 2rem;
        font-size: 0.8125rem;
        color: #ff0000;
      }

      .pickup-desc-morebottom {
        bottom: -1.875rem;
      }
    }

    .take-type-detail {
      padding: 1.5625rem 0;
    }
  }

  & > .footer {
    height: 5rem;
    padding: 0.9375rem 0.9375rem 0.9375rem;
    display: flex;
    & > button {
      flex: 1;
    }
    .item-length-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 50%;
      background: #ffffff;
      color: #333333;
      margin-right: 0.3125rem;
    }
  }
`;

export default React.memo(CafePaymentRobot);
