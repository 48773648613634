import * as apiLib from './api';

export const list = async ({ spaceId }) => {
  try {
    const data = {
      spaceId
    };

    const result = await apiLib.fetchStoreApi('/order-app/shop/list', data);
    if (result.resultFlag) {
      return result.shopList;
    } else {
      throw Object.assign(new Error('shop list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const info = async ({ shopId }) => {
  try {
    const data = {
      shopId
    };

    const result = await apiLib.fetchStoreApi('/order-app/shop/info', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop info error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

