export const spaceObject = {
  'SQISOFT': '17203971062fefb1a5', //신규 SQISOFT SPACEID
  'TEST': '16220146343ec1d28b', //기존 SQISOFT spaceID
  'KRAFTON': '16220146678072d15d',
  'SQISOFT-KRAFTON': '16220146678072d15d',
  'KAKAO': '16528612054edce023',
  'KAKAOENT': '16768718951fa54fe7',
  'APR': '1690869332c7b6c2af',
  'LPCAFE': '1712920960f6cd2569',
  'TORRIDEN': '1715231654bb8f6653',
}

/**
 * @param {String} groupCode 그룹코드
 * @returns {String} spaceId
 */

export const getSpaceId = groupCode => {
  const spaceCode = groupCode.toUpperCase().replace('-QA', '');

  if(spaceObject.hasOwnProperty(spaceCode)){
    return spaceObject[spaceCode];
  }else{
    return '';
  }
};

/**
 * @param {String} spaceId spaceId
 * @returns {boolean}
 */

export const isKraftonSpace = spaceId => spaceId === '16220146678072d15d';

/**
 * @param {String} spaceId spaceId
 * @returns {boolean}
 */

export const isKakaoSpace = spaceId => spaceId === '16528612054edce023';

/**
 * 스페이스 판단
 * @param {string} spaceId -현재 spaceId
 * @param {'SQI' | 'KAKAO' | 'KRAFTON' | 'KAKAOENT' | 'APR'} spaceNm - 판별하고자하는 스페이스 이름
 * @returns {boolean}
 */
export const isMatchSpace = (spaceId = '16220146343ec1d28b', spaceNm = 'TEST') => {
  const key = spaceNm.toUpperCase();
  const spaceObj = {
    TEST: '16220146343ec1d28b',
    KAKAO: '16528612054edce023',
    KAKAOENT: '16768718951fa54fe7',
    KRAFTON: '16220146678072d15d',
    APR: '1690869332c7b6c2af',
  };

  return spaceObj[key] === spaceId;
};

export const isPayReasonEnabled = (shopInfo) => {
  // 16546721126028e794	kafé 3F
  // 1654672129b00cd14b	kafé 5F
  // 1683508905676c04f2	kafé 5F b
  // 167272477358b8ed2b	모빌리티 카페
  const shopList = [
      '16546721126028e794',
      '1654672129b00cd14b',
      '1683508905676c04f2',
      '167272477358b8ed2b'
  ]; //결제 사유 노출되는 shopID
  if(shopList.includes(shopInfo?.shopId)){
    return true;
  }else{
    return false;
  }
}

export const isWaitNumberExpose = (shopInfo) => {
  // 16546721126028e794	kafé 3F
  // 1654672129b00cd14b	kafé 5F
  // 1683508905676c04f2	kafé 5F b
  // 16233018423cc44720 SQI 문래사옥 카페
  // 1713254695c574d443 Cafe ELIGA(SQI)
  const shopList = [
      '16546721126028e794',
      '1654672129b00cd14b',
      '1683508905676c04f2',
      '16233018423cc44720',
      '1713254695c574d443'
  ]; //대기 번호 노출 shopID
  if(shopList.includes(shopInfo?.shopId)){
    return true;
  }else{
    return false;
  }
}

/**
 * 영어 이름 판단
 * @param {string} spaceId -현재 spaceId
 * @returns {string}
 */
export const printCustomerNm = (spaceId = '16220146343ec1d28b', customerNm, customerEngNm) => {
  if (isMatchSpace(spaceId, 'KAKAO') || isMatchSpace(spaceId, 'KAKAOENT')) {
    return customerEngNm || customerNm;
  } else {
    return customerNm;
  }
};

/**
 * 로봇배송 사용여부 판단
 */
export const isMatchRobot = (shopId = '', customerEmail = '') => {
  const shopObj = ['16233018423cc44720', '167272477358b8ed2b'];
  const emailObj = [
    'bangmincheol@gmail.com',
    'ares.g12@kakaomobility.com',
    'ares.g12@kakaomobility.com',
    'leon.oh@kakaomobility.com',
    'lucas.t@kakaomobility.com',
    'spike.hh@kakaomobility.com',
    'sean.you@kakaomobility.com',
    'haydn.33@kakaomobility.com',
    'cloi.c@kakaomobility.com',
    'linji.13@kakaomobility.com',
    'tj.24@kakaomobility.com',
    'roy.me@kakaomobility.com',
    'dulcie.nea@kakaomobility.com',
    'dulcie.nea@kakaomobility.com',
    'luke.kw@kakaomobility.com',
    'bingbong.lol@kakaomobility.com',
    'bingbong.lol@kakaomobility.com',
    'christopher.sw@kakaomobility.com',
    'christopher.sw@kakaomobility.com',
    'luna.rim@kakaomobility.com',
    'willie.77@kakaomobility.com',
    'willie.77@kakaomobility.com',
    'christina.move@kakaomobility.com',
    'christina.move@kakaomobility.com',
    'russel.ht@kakaomobility.com',
    'russel.ht@kakaomobility.com',
    'sunny.up@kakaomobility.com',
  ];
  return shopObj.includes(shopId);
};
