import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as DownArrowIcon } from '../assets/images/common/icon-chevron-down.svg';
import { ReactComponent as UpArrowIcon } from '../assets/images/common/icon-chevron-up.svg';

import ClickOutside from '../components/ClickOutside';

const SelectInput = ({
  defaultTitle = '',
  selectedValue,
  optionList,
  size,
  onSelectChange,
  disabled,
  isValidate,
  className,
  up,
}) => {
  const [isShow, setIsShow] = useState(false);

  const ContainerRef = useRef(null);

  const closeSelectInput = useCallback(() => {
    if (isShow) {
      setIsShow(false);
    }
  }, [isShow]);

  const getSelectedTitle = useCallback(value => {
    const findItem = optionList.find(option =>
      option.value === value ? option : false,
    );
    return findItem ? findItem.title : defaultTitle;
  }, [optionList, defaultTitle]);

  const handleChangeOption = useCallback(option => {
    onSelectChange(option.value);
    setIsShow(false);
  }, [onSelectChange]);

  return (
    <ClickOutside onClickOutside={closeSelectInput}>
      <Container
        ref={ContainerRef}
        size={size}
        className={className}
        isValidate={isValidate}
        disabled={disabled}
      >
        <SelectBtn isShow={isShow} onClick={() => setIsShow(!isShow)}>
          <SelectTitle isSelect={selectedValue ? true : false} disabled={disabled}>
            {getSelectedTitle(selectedValue)}
          </SelectTitle>
          {isShow ? <UpArrowIcon /> : <DownArrowIcon />}
        </SelectBtn>
        <OptionWrapper
          style={isShow ? { display: 'block' } : { display: 'none' }}
          height={ContainerRef.current?.offsetHeight}
          up={up}
        >
          <OptionList>
            {optionList.map((option, index) => (
              <OptionItem
                key={`option-${index}`}
                onClick={() => onSelectChange && handleChangeOption(option)}
                className={selectedValue === option.value && 'active'}
                height={ContainerRef.current?.offsetHeight}
                index={index}
              >
                <span>
                  {option.title}
                </span>
              </OptionItem>
            ))}
          </OptionList>
        </OptionWrapper>
      </Container>
    </ClickOutside>
  );
};

const Container = styled.div`
  flex: 1;
  position: relative;
  box-sizing: border-box;
  background: ${({ disabled, theme }) => disabled ? theme.disabled : '#FFFFFF'};
  width: 100%;
  height: 2.375rem;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const SelectBtn = styled.button`
  width: 100%;
  height: inherit;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 0;
  background: transparent;
  border: 0.0625rem solid
    ${({ isValidate }) =>
      isValidate === undefined || isValidate ? '#CCCCCC' : '#F05B5B'};
  border-radius: ${({ isShow }) => isShow ? '0.3125rem 0.3125rem 0 0' : '0.3125rem'};

  padding: 0 0.625rem 0 0.9375rem;
  outline: 0;
  & > svg {
    margin-left: auto;
  }
`;

const SelectTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-weight: 500;
  font-size: 0.8125rem;
  color: ${({ isSelect, disabled, theme }) => disabled ? theme.gray700 : isSelect ? '#111111' : '#999999'} ;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden overlay;
  position: absolute;
  top: ${({ height }) => (height ? height : 36) / 16}rem;
  background: #ffffff;
  width: inherit;
  max-height: ${({ height }) => (height ? height * 5 + 2 : 192) / 16}rem;
  border-radius: 0 0 0.3125rem 0.3125rem;
  border: 0.0625rem solid #CFCFCF;
  z-index: 1;

  ${({ up }) => up && css`
    top: ${({ height }) => (height ? -(height * 5 + 2 - height): -154) / 16}rem;
    border-radius: 0.3125rem 0.3125rem 0 0;
  `}
`;

const OptionList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const OptionItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  height: ${({ height }) => (height ? height : 38) / 16}rem;
  padding: 0 0.625rem 0 0.9375rem;
  font-size: 0.8125rem;
  cursor: pointer;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:not(:first-child) {
    border-top: 0.0625rem solid #EEEEEE;
  }

  background: #FFFFFF;
  color: #555555;

  &.active {
    color: #666666;
    font-weight: bold;
    background: #EEEEEE;;
  }
`;

export default React.memo(SelectInput);
