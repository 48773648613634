import * as apiLib from './api';

export const list = async ({ customerId, surveyId}) => {
  try {
    const data = {
      customerId,
      surveyId,
    };
    const result = await apiLib.fetchStoreApi('/survey-user/list', data);
    if (result.resultFlag) {
      return result.surveyUserList;
    } else {
      throw Object.assign(new Error('list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const rating = async ( ratingData ) => {
  try {
    const data = {
      surveyUserId : ratingData.surveyUserId,
      ratingData
    };
    // console.log('rating',data);
    const result = await apiLib.fetchStoreApi('/survey-user/add', ratingData);
    if (result.resultFlag) {
      return result.surveyUserList;
    } else {
      throw Object.assign(new Error('rating error'),{ status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};