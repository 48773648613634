import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import Background from '../background/Background';

import ListHeader from '../common/ListHeader';

const ListLayout = () => {
  return (
    <Wrap>
      <Background>
        <ListHeader />
        <Body>
          <Outlet />
        </Body>
      </Background>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.subBackground};
  z-index: 99;
`;

const Body = styled.div`
  width: 100%;
  flex: 1;
  padding-top: 3.125rem;
  display: flex;
  flex-direction: column;
  overflow: hidden overlay;
`;

export default React.memo(ListLayout);
