import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './i18n_en.json';
import translationKR from './i18n_kr.json';

const resources = {
  KR: {
    translation: translationKR,
  },
  EN: {
    translation: translationEN,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: i18n.options.lng,
    fallbackLng: localStorage.getItem('langCd') || 'KR',
    debug: true,
    supportedLngs: ['KR', 'EN'],
    ns: ['translation'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
