import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';

import loadingAnimation from '../assets/images/lotties/loading.json';

const Loading = ({ className, position, text, bacgroundColor }) => {
  const lottieContainer = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingAnimation,
    });
  }, []);

  return (
    <Background className={className} position={position} bacgroundColor={bacgroundColor}>
      {text && text}
      <div ref={lottieContainer} style={{ height: '5em', width: '5em' }} />
    </Background>
  );
};

const Background = styled.div`
  position: ${({ position }) => (position ? position : 'relative')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ bacgroundColor }) => (bacgroundColor ? bacgroundColor : 'inherit')};
  font-weight: bold;
  font-size: 1.5625rem;
  color: #000000;
`;

export default Loading;
