import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ItemOption {
  optFNm: string;
  optId: string;
  optNm: string;
  optPNm: string;
  optQty: number;
  optType: string;
  totalAmt: numebr;
  unitAmt: numebr;
}

interface OrderItem {
  categoryId: string;
  categoryNm: string;
  goodsId: string;
  goodsOpt: string;
  itemFNm: string;
  itemNm: string;
  itemPrice: number;
  itemQty: number;
  optionList: ItemOption[];
  stockId: string;
  totalAmt: number;
  totalEnr: number;
  totalOrg: number;
  totalSer: number;
  totalVat: number;
  unitAmt: number;
  unitEnr: number;
  unitOrg: number;
  unitSer: number;
  unitVat: number;
}

interface OrderPayment {
  aPoint: number;
  apprAmt: number;
  apprCd: string;
  apprDt: string;
  apprNet: number;
  apprNm: string;
  apprNo: string;
  apprSer: number;
  apprTm: string;
  apprVat: number;
  cardNm: string;
  cardNo: string;
  chnAmt: number;
  cPoint: number;
  customerId: string;
  customerNm: string;
  customerNo: string;
  maeipCd: string;
  maeipNm: string;
  orgAmt: number;
  payAmt: number;
  paymentDt: string;
  paymentId: string;
  paymentKey: string;
  paymentNm: string;
  paymentType: string;
  rmnAmt: number;
  tPoint: number;
}

interface OrderInfo {
  beforeBillNo: null | string;
  billDt: string;
  billNo: number;
  billTm: string;
  billType: 'WAIT' | 'REG' | 'TAKE_REQ' | 'SALE' | 'CANCEL' | 'RETURN';
  brandId: string;
  calcDoneYn: string;
  customerId: string;
  customerNm: string;
  customerNo: string;
  deviceId: string;
  deviceNm: string;
  itemList: OrderItem[];
  orderCancelDt: null | string;
  orderDt: string;
  orderId: string;
  orderRegDt: null | string;
  orderReturnDt: null | string;
  orderSaleDt: null | string;
  orderTakeReqDt: null | string;
  orderWaitDt: null | string;
  paymentList: OrderPayment[];
  placeId: string;
  regDt: string;
  shopId: string;
  spaceId: string;
  takeMsg: null | string;
  takeType: 'IN' | 'OUT' | 'DELI' | 'REZ' | 'DELI_REZ' | 'ROBOT';
  totalAmt: number;
  totalEnr: number;
  totalOrg: number;
  totalQty: number;
  totalSer: number;
  totalVat: number;
  waitNm: string;
  waitNo: number;
  orderType: string;
  robotDeliveryStatus: string;
}

interface OrderState {
  orderList: OrderInfo[];
  orderListByShop: OrderInfo[];
  orderDetail: OrderInfo;
  selectedOrderId: string;
  isOrderWithBasket: {};
  actionResult: string;
  isLoading: boolean;
  error: null | string;
}

const orderInitialState: OrderState = {
  orderList: [],
  orderListByShop: [],
  orderDetail: {},
  selectedOrderId: '',
  isOrderWithBasket: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: OrderState) => {
    state.actionResult = '';
  },
  list: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.orderList = payload.orderList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  listByShop: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_BY_SHOP_REQ';
    state.error = '';
  },
  listByShopSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.orderListByShop = payload.orderList;
    state.isLoading = false;
    state.actionResult = 'LIST_BY_SHOP_OK';
    state.error = '';
  },
  listByShopFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_BY_SHOP_ERR';
    state.error = payload.error;
  },
  detail: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.orderDetail = payload.orderInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  payment: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = true;
    state.actionResult = 'PAYMENT_REQ';
    state.error = '';
  },
  paymentSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'PAYMENT_OK';
    state.error = '';
  },
  paymentFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'PAYMENT_ERR';
    state.error = payload.error;
  },
  paymentRobot: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = true;
    state.actionResult = 'PAYMENT_ROBOT_REQ';
    state.error = '';
  },
  paymentRobotSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'PAYMENT_ROBOT_OK';
    state.error = '';
  },
  paymentRobotFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'PAYMENT_ROBOT_ERR';
    state.error = payload.error;
  },
  robotReserve: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = true;
    state.actionResult = 'ROBOT_RESERVE_REQ';
    state.error = '';
  },
  robotReserveSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'ROBOT_RESERVE_OK';
    state.error = '';
  },
  robotReserveFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'ROBOT_RESERVE_ERR';
    state.error = payload.error;
  },
  robotCheck: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = true;
    state.actionResult = 'ROBOT_CHECK_REQ';
    state.error = '';
  },
  robotCheckSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'ROBOT_CHECK_OK';
    state.error = '';
  },
  robotCheckFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'ROBOT_CHECK_ERR';
    state.error = payload.error;
  },
  robotAccept: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = true;
    state.actionResult = 'ROBOT_ACCEPT_REQ';
    state.error = '';
  },
  robotAcceptSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'ROBOT_ACCEPT_OK';
    state.error = '';
  },
  robotAcceptFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'ROBOT_ACCEPT_ERR';
    state.error = payload.error;
  },
  robotReserveCancle: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = true;
    state.actionResult = 'ROBOT_RESERVE_CANCLE_REQ';
    state.error = '';
  },
  robotReserveCancleSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'ROBOT_RESERVE_CANCLE_OK';
    state.error = '';
  },
  robotReserveCancleFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isLoading = false;
    state.actionResult = 'ROBOT_RESERVE_CANCLE_ERR';
    state.error = payload.error;
  },
  setIsOrderWithBasket: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.isOrderWithBasket = payload;
    state.actionResult = 'BASKET_OK';
    state.error = '';
  },
};

const slice = createSlice({
  name: 'order',
  initialState: orderInitialState,
  reducers: reducers,
});

const selectOrderList = createDraftSafeSelector(
  (state: OrderState) => state.orderList,

  orderList => {
    let orderingList = orderList.filter(
      order =>
        order.deviceId !== '1624526978e29aab84' && order.deviceId !== '16245269796158913f' && order.deviceId !== '1624526980b9993ab3',
    );
    return orderingList;
  },
);

const selectOrderingList = createDraftSafeSelector(
  (state: OrderState) => state.orderList,
  orderList => {
    let orderingList = orderList.filter(
      order =>
        !(
          order.billType === 'SALE' &&
          !(
            (order.takeType === 'DELI' || order.takeType === 'DELI_REZ') &&
            dayjs(dayjs().diff(dayjs(order.orderSaleDt)))
              .utc()
              .format('HH') < '01'
          )
        ) &&
        order.billType !== 'CANCEL' &&
        order.billType !== 'RETURN',
    );

    return orderingList;
  },
);

const selectOrderListByShop = createDraftSafeSelector(
  (state: OrderState) => state.orderListByShop,
  orderListByShop => orderListByShop,
);

const selectOrderDetail = createDraftSafeSelector(
  (state: OrderState) => state.orderDetail,
  orderDetail => orderDetail,
);
/*
const selectIsOrderWithBasket = createDraftSafeSelector(
  (state: OrderState) => state.isOrderWithBasket,
  isOrderWithBasket => isOrderWithBasket,
);
*/

const selectStatus = createDraftSafeSelector(
  (state: OrderState) => state.actionResult,
  (state: OrderState) => state.isLoading,
  (state: OrderState) => state.error,
  (state: OrderState) => state.isOrderWithBasket,
  (actionResult, isLoading, error, isOrderWithBasket) => ({ actionResult, isLoading, error, isOrderWithBasket }),
);

export const orderSelector = {
  orderList: state => selectOrderList(state[ORDER]),
  orderingList: state => selectOrderingList(state[ORDER]),
  orderListByShop: state => selectOrderListByShop(state[ORDER]),
  orderDetail: state => selectOrderDetail(state[ORDER]),
  // isOrderWithBasket: state => selectIsOrderWithBasket(state[ORDER]),
  status: state => selectStatus(state[ORDER]),
};

export const ORDER = slice.name;
export const orderReducer = slice.reducer;
export const orderAction = slice.actions;
