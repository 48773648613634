import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { categoryGroupAction } from './categoryGroupSlice';

import * as categoryGroupApiLib from '../lib/categoryGroupApi';

function* list({ payload }) {
  try {
    const categoryGroupList = yield call(categoryGroupApiLib.list, payload);

    yield put(categoryGroupAction.listSuccess({ categoryGroupList }));
  } catch (error) {
    yield put(categoryGroupAction.listFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(categoryGroupAction.list, list);
}

function* rootSaga() {
  yield all([fork(watchList)]);
}

export default rootSaga;
