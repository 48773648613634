import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import { getPaymentKey } from '../../../lib/customerApi';
import { customerSelector } from '../../../modules/customerSlice';
import { printCustomerNm } from '../../../utils/space';

const BarcodeModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const customerInfo = useSelector(customerSelector.customerInfo);

  const [paymentKey, setPaymentKey] = useState('');
 

  useEffect(() => {
    if (customerInfo.spaceId && customerInfo.customerId) {
      fetchPaymentKey(customerInfo.spaceId, customerInfo.customerId);
    }
  }, [customerInfo.spaceId, customerInfo.customerId]);

  const fetchPaymentKey = async(spaceId, customerId) => {
    try {
      const paymentKey = await getPaymentKey({ spaceId: spaceId, customerId: customerId });
      setPaymentKey(paymentKey);
    } catch (err) {
      console.error('Error fetching payment key:', err);
    }
  }

  console.log(customerInfo.pamentKey);
  return (
    <Modal visible={isOpen} title="" onClose={() => setIsOpen(false)}>
      <Container>
        <NameLabel>
          {printCustomerNm(customerInfo.spaceId, customerInfo?.customerNm, customerInfo?.customerEngNm)}
          {t('NIM')}
        </NameLabel>
        <DescLabel>{t('TODAY_HELLO')}</DescLabel>
        <BarcodeLabel>
          {paymentKey ? <Barcode value={paymentKey} height={80} /> : <EmptyDiv>사원번호가 존재하지 않습니다.</EmptyDiv>}
        </BarcodeLabel>
        <Bottom />
      </Container>
    </Modal>
  );
};

const NameLabel = styled.div`
  margin-top: 20px;
  font-size: 22px;
  font-weight: 700;
`;

const DescLabel = styled.div`
  margin-top: 20px;
  color: #666666;
`;

const BarcodeLabel = styled.div`
  margin-top: 30px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
  height: 300px;
  width: 100%;
  margin-bottom: 0.625rem;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  color: #000000;
`;

const Bottom = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 25px;
  background-color: #ff5689;
`;

const EmptyDiv = styled.div`
  border: 1px solid gray;
  color: gray;
  font-size: 13px;
  height: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
`;

export default React.memo(BarcodeModal);
