import * as apiLib from './api';

export const ratingTagList = async ({ brandId }) => {
  try {
    const data = {
      brandId : '16226096852605dd20',
    };

    const result = await apiLib.fetchStoreApi('/rating-tag/list', data);

    if (result.resultFlag) {
      return result.tagList;
    } else {
      throw Object.assign(new Error('App::Goods Cafe List Api error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
