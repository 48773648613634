import * as apiLib from './api';

export const login = async ({ fcmToken, spaceId, loginType, loginId, loginPw }) => {
  try {
    const data = {
      fcmToken: fcmToken,
      spaceId: spaceId,
      loginType: loginType,
      loginId: loginId,
      loginPw: loginPw,
    };

    const result = await apiLib.fetchStoreApi('/order-app/auth/create-token', data);
    if (result.resultFlag) {
      return { ...result.loginInfo, accessToken: result.accessToken };
    } else {
      let errCode = 0;
      switch (result.resultMsg) {
        case 'JsonWebTokenError: invalid signature':
          errCode = 1000;
          break;
        case 'Error: password incorrect':
          errCode = 1001;
          break;
        case 'Error: email is not use':
          errCode = 1002;
          break;
        case 'Error: email is deleted':
        case 'Error: customer is not exist':
          errCode = 1003;
          break;
        case 'Error: email is not verify.':
          errCode = 1004;
          break;
        default:
          break;
      }
      throw Object.assign(new Error('verify error'), { status: 401, errCode });
    }
  } catch (err) {
    throw err;
  }
};

export const token = async ({ accessToken }) => {
  try {
    const data = {
      accessToken: accessToken,
    };

    const result = await apiLib.fetchStoreApi('/order-app/auth/confirm-token', data);
    if (result.resultFlag) {
      return { ...result.loginInfo, accessToken: result.accessToken };
    } else {
      throw Object.assign(new Error('token error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const kraftonToken = async ({ fcmToken, loginType, ssoToken }) => {
  try {
    const data = {
      fcmToken: fcmToken,
      loginType: loginType,
      ssoToken: ssoToken,
    };

    const result = await apiLib.fetchStoreApi('/krafton/mobile-sso', data);
    if (result.resultFlag) {
      return { ...result.loginInfo, accessToken: result.accessToken };
    } else {
      throw Object.assign(new Error('token error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const sendTempPassword = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/order-app/auth/send-temp-password', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('customer update error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const signup = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/order-app/auth/signup', data);
    if (result.resultFlag) {
      return { ...result.loginInfo, accessToken: result.accessToken };
    } else {
      throw result.resultMsg;
    }
  } catch (err) {
    throw err;
  }
};

export const verifyEmail = async payload => {
  try {
    const data = { ...payload };
    const result = await apiLib.fetchStoreApi('/order-app/auth/verify-email', data);
    if (result.resultFlag) {
      return true;
    } else {
      let errCode = 0;
      switch (result.resultMsg) {
        case 'JsonWebTokenError: invalid signature':
          errCode = 1000;
          break;
        case 'Error: customer exist':
          errCode = 1001;
          break;
        case 'Error: email is not exist':
          errCode = 1002;
          break;
        default:
          break;
      }
      throw Object.assign(new Error('verify error'), { status: 401, errCode });
    }
  } catch (err) {
    throw err;
  }
};

export const verify = async ({ verifyToken }) => {
  try {
    const data = {
      verifyToken: verifyToken,
    };

    const result = await apiLib.fetchStoreApi('/order-app/auth/verify', data);
    if (result.resultFlag) {
      return true;
    } else {
      let errCode = 0;
      switch (result.resultMsg) {
        case 'JsonWebTokenError: invalid signature':
          errCode = 1000;
          break;
        case 'Error: token error.':
          errCode = 1001;
          break;
        case 'Error: account error.':
          errCode = 1002;
          break;
        default:
          break;
      }
      throw Object.assign(new Error('verify error'), { status: 401, errCode });
    }
  } catch (err) {
    throw err;
  }
};

export const checkEmailVerify = async (payload) => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/order-app/auth/check-email-verify', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw new Error('no verify');
    }
  } catch (err) {
    throw err;
  }
};

