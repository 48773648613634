import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { shopSelector } from '../../modules/shopSlice';

import RadioCheckbox from '../../components/RadioCheckbox';
import Button from '../../components/Button';

// import CafePaymentRez from './CafePaymentRez';
import CafePaymentDeli from './CafePaymentDeli';

import PaymentConfirmModal from './modal/PaymentConfirmModal';
import WarnPayReasonModal from './modal/WarnPayReasonModal';
import NoRezTimeModal from './modal/NoRezTimeModal';

import { isKakaoSpace, isPayReasonEnabled } from '../../utils/space';
import {isPickupShop, printTotal} from "../../utils/orderCommon";

const CafePayment = ({ totalInfoObj, orderProcess }) => {
  const goodsType = 'CAFE';

  const shopInfo = useSelector(shopSelector.shopInfo);

  const { t } = useTranslation();

  const [isPickup, setIsPickup] = useState(true);
  const [payReason, setPayReason] = useState('NORMAL');
  const [rezForm, setRezForm] = useState({
    pickupType: 'NOW',
    takeMsg: '',
  });
  const [deliForm, setDeliForm] = useState({
    deliType: 'REZ',
    takeMsg: '',
  });
  const [isOpenPaymentConfirmModal, setIsOpenPaymentConfirmModal] = useState(false);
  const [isOpenNoRezTimeModal, setIsOpenNoRezTimeModal] = useState(false);
  const [isOpenWarnPayReasonModal, setIsOpenWarnPayReasonModal] = useState(false);

  const paymentOrderItemList = useRef(JSON.parse(sessionStorage.getItem('paymentOrderItemList')));

  useEffect(() => {
    let startTime = dayjs().startOf('day');

    const nowTime = dayjs();

    const nowHour = Number(dayjs(nowTime).format('HH'));
    const nowMinute = Number(dayjs(nowTime).format('mm'));

    const celHour = nowHour * 2;
    const celMin = 0 <= nowMinute && nowMinute < 15 ? 0 : 15 <= nowMinute && nowMinute < 30 ? 1 : 30 <= nowMinute && nowMinute < 45 ? 2 : 3;

    const selectRezTimeIndex = celHour + celMin;

    let timeList = [];
    const division = 15; // 30분 마다 (정각으로 끊을꺼 예상)

    let addTime = 0;

    while (addTime < 1440) {
      timeList.push(startTime.add(addTime, 'minute').format('HH:mm'));

      addTime += division;
    }

    const isCheckedSaveLocalStorageTakeMsg = localStorage.getItem('isCheckedSaveLocalStorageTakeMsg') || false;
    const takeMsg = localStorage.getItem('takeMsg') || '';

    setRezForm(rezForm => ({ ...rezForm, timeList, selectTimeIndex: selectRezTimeIndex, isCheckedSaveLocalStorageTakeMsg, takeMsg }));

    const currentTime = dayjs().add(15, 'minute').format('HH:mm');

    setDeliForm(deliForm => ({
      ...deliForm,
      deliveryTimeList: shopInfo.deliveryTimeList?.filter(time => time >= currentTime),
      deliveryZoneList: shopInfo.deliveryZoneList,
      selectTimeIndex: 0,
      selectZoneIndex: 0,
      isCheckedSaveLocalStorageTakeMsg,
      takeMsg,
    }));
  }, [shopInfo]);

  // const changeRezForm = useCallback((key, value) => {
  //   setRezForm(rezForm => ({ ...rezForm, [key]: value }));
  // }, []);

  const changeDeliForm = useCallback((key, value) => {
    setDeliForm(deliForm => ({ ...deliForm, [key]: value }));
  }, []);

  const openPaymentConfirmModal = useCallback(() => {
    if (isPayReasonEnabled(shopInfo) && !payReason) {
      setIsOpenWarnPayReasonModal(true);
    } else {
      setIsOpenPaymentConfirmModal(true);
    }
  }, [payReason, shopInfo?.spaceId]);

  const closePaymentConfirmModal = useCallback(() => {
    setIsOpenPaymentConfirmModal(false);
  }, []);

  const openNoRezTimeModal = useCallback(() => {
    setIsOpenNoRezTimeModal(true);
  }, []);

  const closeNoRezTimeModal = useCallback(() => {
    setIsOpenNoRezTimeModal(false);
  }, []);

  const clickDeliveryRadio = useCallback(() => {
    if (deliForm.deliveryTimeList.length <= 0) {
      openNoRezTimeModal();
    } else {
      setIsPickup(false);
    }
  }, [openNoRezTimeModal, deliForm]);

  return (
    <Container>
      <div className="header">
        <div className="shop-name">{shopInfo.shopNm}</div>
        <div className="goods-name">
          {t('NAME', { krNm: paymentOrderItemList.current?.[0].itemFNm, enNm: paymentOrderItemList.current?.[0].itemEngFNm })}{' '}
          {printTotal(totalInfoObj, paymentOrderItemList.current)}
        </div>
      </div>
      <div className="body">
        <div className="take-type">
          <label className="radio-label">
            <RadioCheckbox checked={isPickup} onChange={() => setIsPickup(true)} />
            <span className="pickup">{t('STORE_PICK')}</span>
            <span className="pickup-desc">{t('STORE_PICK_MENT')}</span>
          </label>
          {isPickupShop(shopInfo) && (deliForm?.deliveryZoneList?.length > 0) && (
            <label className="radio-label">
              <RadioCheckbox checked={!isPickup} onChange={() => clickDeliveryRadio()} />
              <span className="pickup">{t('STORE_DELIVERY')}</span>
              <span className="pickup-desc">{t('STORE_DELIVERY_MENT')}</span>
            </label>
          )}
        </div>
        {isPayReasonEnabled(shopInfo) && (
          <div className="take-type">
            <span className="payreason">{t('PAY_REASON')}</span>
            <label className="radio-label">
              <RadioCheckbox checked={payReason === 'NORMAL'} onChange={() => setPayReason('NORMAL')} />
              <span style={{textDecoration: 'underline'}} className="pickup">{t('PERSONAL_RFID')}</span>
            </label>
            {
                shopInfo?.shopId === '167272477358b8ed2b' &&
                <label className="radio-label">
                  <RadioCheckbox checked={payReason === 'FOREIGN'} onChange={() => setPayReason('FOREIGN')} />
                  <span className="pickup">{t('COMMERCIAL_RFID')}</span>
                </label>
            }
            <label className="radio-label">
              <RadioCheckbox checked={payReason === 'INTERVIEW'} onChange={() => setPayReason('INTERVIEW')} />
              <span className="pickup">{t('ONEONONE_RFID')}</span>
            </label>
          </div>
        )}
        <div className="take-type-detail">
          {/* {isPickup
            ? (rezForm?.selectTimeIndex || rezForm?.selectTimeIndex === 0) && (
                <CafePaymentRez rezForm={rezForm} changeRezForm={changeRezForm} />
              )
            : (deliForm?.selectTimeIndex || deliForm?.selectTimeIndex === 0) && (
                <CafePaymentDeli deliForm={deliForm} changeDeliForm={changeDeliForm} />
              )} */}
          {!isPickup && (deliForm?.selectTimeIndex || deliForm?.selectTimeIndex === 0) && (
            <CafePaymentDeli deliForm={deliForm} changeDeliForm={changeDeliForm} />
          )}
        </div>
      </div>
      <div className="footer">
        {shopInfo.openState !== 'Y' ? (
          <Button size="large" color="disable" disable>
            {t('SHOP_CLOSE')}
          </Button>
        ) : (
          <Button size="large" color="select" onClick={() => openPaymentConfirmModal()}>
            <span className="item-length-circle">{totalInfoObj.totalQty}</span>
            {totalInfoObj.totalAmt?.toLocaleString()}
            {t('WON')} {t('STORE_CHECK_OUT')}
          </Button>
        )}
      </div>
      <PaymentConfirmModal
        isOpen={isOpenPaymentConfirmModal}
        closeModal={closePaymentConfirmModal}
        orderProcess={() => orderProcess({ deliForm, rezForm, isPickup, goodsType, payReason })}
        comment={`${t('NAME', { krNm: paymentOrderItemList.current?.[0].itemFNm, enNm: paymentOrderItemList.current?.[0].itemEngFNm })} ${
          printTotal(totalInfoObj, paymentOrderItemList.current)
        }`}
        isPickup={isPickup}
        deliveryTime={deliForm?.deliveryTimeList?.[deliForm?.selectTimeIndex]}
        deliveryZone={deliForm?.deliveryZoneList?.[deliForm?.selectZoneIndex]}
      />
      {isPayReasonEnabled(shopInfo) && (
        <WarnPayReasonModal isOpen={isOpenWarnPayReasonModal} closeModal={() => setIsOpenWarnPayReasonModal(false)} />
      )}
      {deliForm.deliveryTimeList?.length <= 0 && <NoRezTimeModal isOpen={isOpenNoRezTimeModal} closeModal={closeNoRezTimeModal} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background: inherit;

  & > .header {
    display: flex;
    flex-direction: column;
    min-height: 5.9375rem;
    max-height: 5.9375rem;
    padding: 1.25rem 1.25rem;
    border-bottom: 0.5rem solid ${({ theme }) => theme.line};
    .shop-name {
      font-weight: bold;
      font-size: 1.375rem;
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .goods-name {
      margin-top: auto;
      font-weight: 500;
      font-size: 0.9375rem;
      color: #000000;
    }
  }

  & > .body {
    flex: 1;
    padding: 1.25rem;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.line};
    overflow: hidden overlay;
    .take-type {
      display: flex;
      flex-direction: column;
      border-bottom: 0.0625rem solid ${({ theme }) => theme.line};
      padding-bottom: 2.5rem;

      .radio-label + .radio-label {
        margin-top: 2.1875rem;
      }
    }

    .take-type + .take-type {
      margin-top: 20px;
    }

    .payreason {
      font-size: 15px;
      line-height: 100%;
      display: flex;
      align-items: center;
      color: #333333;
      margin-bottom: 20px;
    }

    .radio-label {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      .pickup {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 0.625rem;
        font-size: 0.9375rem;
        color: #333333;
      }

      .pickup-desc {
        /* text-overflow: ellipsis;
        overflow: hidden; */
        // white-space: nowrap;
        width: 100%;
        position: absolute;
        bottom: -0.9375rem;
        // left: 32px;
        padding-left: 2rem;
        font-size: 0.8125rem;
        color: #999999;
      }

      .pickup-desc-morebottom {
        bottom: -1.875rem;
      }
    }

    .take-type-detail {
      padding: 1.5625rem 0;
    }
  }

  & > .footer {
    height: 5rem;
    padding: 0.9375rem 0.9375rem 0.9375rem;
    display: flex;
    & > button {
      flex: 1;
    }
    .item-length-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 50%;
      background: #ffffff;
      color: #333333;
      margin-right: 0.3125rem;
    }
  }
`;

export default React.memo(CafePayment);
