import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ItemOption {
  optFNm: string;
  optId: string;
  optNm: string;
  optPNm: string;
  optQty: number;
  optType: string;
  totalAmt: numebr;
  unitAmt: numebr;
}

interface OrderItem {
  categoryId: string;
  categoryNm: string;
  goodsId: string;
  goodsOpt: string;
  itemFNm: string;
  itemNm: string;
  itemPrice: number;
  itemQty: number;
  optionList: ItemOption[];
  stockId: string;
  totalAmt: number;
  totalEnr: number;
  totalOrg: number;
  totalSer: number;
  totalVat: number;
  unitAmt: number;
  unitEnr: number;
  unitOrg: number;
  unitSer: number;
  unitVat: number;
}

interface OrderPayment {
  aPoint: number;
  apprAmt: number;
  apprCd: string;
  apprDt: string;
  apprNet: number;
  apprNm: string;
  apprNo: string;
  apprSer: number;
  apprTm: string;
  apprVat: number;
  cardNm: string;
  cardNo: string;
  chnAmt: number;
  cPoint: number;
  customerId: string;
  customerNm: string;
  customerNo: string;
  maeipCd: string;
  maeipNm: string;
  orgAmt: number;
  payAmt: number;
  paymentDt: string;
  paymentId: string;
  paymentKey: string;
  paymentNm: string;
  paymentType: string;
  rmnAmt: number;
  tPoint: number;
}

interface RatingInfo {
  beforeBillNo: null | string;
  billDt: string;
  billNo: number;
  billTm: string;
  billType: 'WAIT' | 'REG' | 'TAKE_REQ' | 'SALE' | 'CANCEL' | 'RETURN';
  brandId: string;
  calcDoneYn: string;
  customerId: string;
  customerNm: string;
  customerNo: string;
  deviceId: string;
  deviceNm: string;
  itemList: OrderItem[];
  orderCancelDt: null | string;
  orderDt: string;
  orderId: string;
  orderRegDt: null | string;
  orderReturnDt: null | string;
  orderSaleDt: null | string;
  orderTakeReqDt: null | string;
  orderWaitDt: null | string;
  paymentList: OrderPayment[];
  placeId: string;
  regDt: string;
  shopId: string;
  spaceId: string;
  takeMsg: null | string;
  takeType: 'IN' | 'OUT' | 'DELI' | 'REZ' | 'DELI_REZ' | 'ROBOT';
  totalAmt: number;
  totalEnr: number;
  totalOrg: number;
  totalQty: number;
  totalSer: number;
  totalVat: number;
  waitNm: string;
  waitNo: number;
  orderType: string;
  robotDeliveryStatus: string;
}

interface RatingState {
  ratingList: OrderInfo[];
  ratingDetail: OrderInfo;
  selectedOrderId: string;
  isOrderWithBasket: {};
  actionResult: string;
  isLoading: boolean;
  error: null | string;
}

const ratingInitialState: RatingState = {
  orderList: [],
  orderDetail: {},
  selectedOrderId: '',
  isOrderWithBasket: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: RatingState) => {
    state.actionResult = '';
  },
  list: (state: RatingState, { payload }: PayloadAction<RatingState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: RatingState, { payload }: PayloadAction<RatingState>) => {
    state.ratingList = payload.ratingList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: RatingState, { payload }: PayloadAction<RatingState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  rating: (state: RatingState, { payload }: PayloadAction<RatingState>) => {
    state.isLoading = true;
    state.actionResult = 'RATING_REQ';
    state.error = '';
  },
  ratingSuccess: (state: RatingState, { payload }: PayloadAction<RatingState>) => {
    state.isLoading = false;
    state.actionResult = 'RATING_OK';
    state.error = '';
  },
  ratingFailure: (state: RatingState, { payload }: PayloadAction<RatingState>) => {
    state.isLoading = false;
    state.actionResult = 'RATING_ERR';
    state.error = payload.error;
  },
};

const slice = createSlice({
  name: 'rating',
  initialState: ratingInitialState,
  reducers: reducers,
});

const selectRatingList = createDraftSafeSelector(
  (state: RatingState) => state.ratingList,
  ratingList => ratingList,
);
const selectStatus = createDraftSafeSelector(
  (state: RatingState) => state.actionResult,
  (state: RatingState) => state.isLoading,
  (state: RatingState) => state.error,
  (state: RatingState) => state.isOrderWithBasket,
  (actionResult, isLoading, error, isOrderWithBasket) => ({ actionResult, isLoading, error, isOrderWithBasket }),
);

export const ratingSelector = {
  list: state => selectRatingList(state[RATING]),
  status: state => selectStatus(state[RATING]),
};

export const RATING = slice.name;
export const ratingReducer = slice.reducer;
export const ratingAction = slice.actions;
