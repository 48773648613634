import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Modal from '../../../components/Modal';

import { shopSelector } from '../../../modules/shopSlice';

const PaymentConfirmModal = ({ isOpen, closeModal, onConfirm, orderProcess, comment, isPickup, deliveryTime, deliveryZone }) => {
  const shopInfo = useSelector(shopSelector.shopInfo);
  const { t } = useTranslation();

  return (
    <Modal
      visible={isOpen}
      onClose={closeModal}
      cancelText={t('NO')}
      confirmText={t('YES')}
      onConfirm={() => {
        closeModal();
        orderProcess();
      }}
      onCancel={() => closeModal()}
    >
      <Container>
        <div className="payment-modal-title">
          {shopInfo.shopType === 'FOODCOURT' ? <>{t('ORDER_SHOP_MENT')}</> : <>{t('ORDER_CAFE_MENT')}</>}
        </div>
        <div className="payment-modal-comment">
          {t('ORDER_STORE')} : {shopInfo.shopNm}
        </div>
        <div className="payment-modal-comment">
          {t('ORDER_MENU')} : {comment}
        </div>
        {isPickup ? (
          <div className="payment-modal-comment">{t('STORE_PICK')}</div>
        ) : (
          <>
            <div className="payment-modal-comment">
              {t('DELIVERY_TIME')} : {deliveryTime}
            </div>
            <div className="payment-modal-comment">
              {t('DELIVERY_PLACE')} : {deliveryZone}
            </div>
          </>
        )}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.25rem 0 2.1875rem;
  display: flex;
  flex-direction: column;

  & > .payment-modal-title {
    margin: 0 auto 0.75rem;
    text-align: center;
    line-height: 130%;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 130%;
    color: #000000;
  }

  & > .payment-modal-comment {
    margin: 0 auto;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 140%;
    text-align: center;
    color: #666666;
  }
`;

export default React.memo(PaymentConfirmModal);
