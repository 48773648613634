import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import Background from '../background/Background';

import ListHeader from '../common/ListHeader';
import {detail} from "../../lib/orderApi";
import {list} from "../../lib/ratingApi";

const OrderListLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [orderInfo, setOrderInfo] = useState(null);
    const [showPage, setShowPage] = useState(false);

  useEffect(() => {
      if(location.pathname === '/order/rating'){
          //만족도 조사인 경우
          const orderId = location.state?.orderId;
          if(orderId){
              detail({orderId}).then(result => {
                  setOrderInfo(result);
              }).catch(error => {
                  console.log(error);
              })
          }
      }else{
          setShowPage(true);
      }
  },[]);

  useEffect(() => {
      if(orderInfo){
          const param = {
              customerId: orderInfo.customerId,
              shopId: orderInfo.shopId
          }
          list(param).then(result => {
              const ratingResult = result?.find(join => join.orderId === orderInfo.orderId);
              if(ratingResult){
                  navigate('/order/list');
              }else{
                  setShowPage(true);
              }
          }).catch(error => {
              console.log(error);
          })
      }
  }, [orderInfo])

  return (
    <Wrap>
      <Background>
        <ListHeader />
        <Body>
            {showPage && <Outlet/>}
        </Body>
      </Background>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.subBackground};
  z-index: 99;
`;

const Body = styled.div`
  width: 100%;
  flex: 1;
  padding-top: 3.125rem;
  display: flex;
  flex-direction: column;
  overflow: hidden overlay;
`;

export default React.memo(OrderListLayout);
