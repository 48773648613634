import * as apiLib from './api';

export const list = async ({ customerId, shopId }) => {
  try {
    const data = {
      customerId,
      shopId,
    };

    const result = await apiLib.fetchStoreApi('/order-app/pushLog/list', data);

    if (result.resultFlag) {
      return result.pushLog;
    } else {
      throw Object.assign(new Error('App::Category List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
export const cnt = async ({ customerId, shopId }) => {
  try {
    const data = {
      customerId,
      shopId,
    };

    const result = await apiLib.fetchStoreApi('/order-app/pushLog/viewYnCnt', data);

    if (result.resultFlag) {
      return result.viewYnCnt;
    } else {
      throw Object.assign(new Error('App::Category List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
export const update = async ({ customerId, shopId }) => {
  try {
    const data = {
      customerId,
      shopId,
    };
    console.log(data);
    const result = await apiLib.fetchStoreApi('/order-app/pushLog/delete', data);

    if (result.resultFlag) {
      return result.pushLog;
    } else {
      throw Object.assign(new Error('App::Category List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
