import React from 'react';
import { Reset } from 'styled-reset';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import Routes from './Routes';
import { getTheme } from '../config/theme';

import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { configSelector } from '../modules/configSlice';

const GlobalStyle = createGlobalStyle`
  html {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', 'Noto Sans KR', sans-serif !important;
    font-size: 0.8rem;
    font-weight: 500;
    box-sizing: border-box;
    background: ${({ theme }) => theme.mainBackground};

    #root {
      width: 100%;
      height: 100%;
    }
    &.cursor-none {
      cursor: none;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    border: none;
    padding: 0;
    &:focus {
      outline: none;
    }
  }

  button, input, textarea {
    font: inherit;
  } 

  * {
    box-sizing: inherit;

    /* 터치 음영 숨김 */
    -webkit-tap-highlight-color:rgba(255,255,255,0);

    /* long touch 비활성 */
    -webkit-touch-callout: none;
    /*재빌드용 주석*/
  }
`;

const App = () => {
  const theme = useSelector(configSelector.theme);

  return (
    <ThemeProvider theme={() => getTheme(theme)}>
      <Reset />
      <GlobalStyle />
      <Routes />
    </ThemeProvider>
  );
};

export default App;
