import * as apiLib from './api';

export const list = async ({ customerId, shopId}) => {
  try {
    const data = {
      customerId,
      shopId,
    };
  
    const result = await apiLib.fetchStoreApi('/survey/list', data);

    if (result.resultFlag) {
      return result.surveyList;
    } else {
      throw Object.assign(new Error('list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const item = async ({surveyId}) => {
  
  try {
    const data = {
      surveyId,
    };
    // console.log(data);
    const result = await apiLib.fetchStoreApi('/survey/item', data);

    if (result.resultFlag) {
      return result.surveyItem;
    } else {
      throw Object.assign(new Error('list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const rating = async ({ evaluationData }) => {
  try {
    const data = {
      shopId : evaluationData.shopId,
      customerId : evaluationData.customerId,
      evaluationData
    };

    const result = await apiLib.fetchStoreApi('/survey/customer-add', data);
    if (result.resultFlag) {
      return result.surveyList;
    } else {
      throw Object.assign(new Error('rating error'),{ status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};