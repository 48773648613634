import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface ShopInfo {
  autoEndTime: string;
  autoOpenState: string;
  autoOpenTime: string;
  autoWorkDay: {};
  beforeEndDt: string;
  beforeOpenDt: string;
  brandId: string;
  currentOpenDt: string;
  delDt: null;
  delYn: string;
  imagePath: string;
  imageSize: number;
  modDt: string;
  modId: string;
  openState: string;
  placeId: string;
  regDt: string;
  regId: string;
  sendCloseYn: string;
  shopId: string;
  shopManagerNm: string;
  shopManagerTel: string;
  shopNm: string;
  shopOrderIndex: number;
  shopType: string;
  spaceId: string;
  useYn: string;
}

interface ShopState {
  shopInfo: ShopInfo;
  shopList: ShopInfo[];
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const shopInitialState: ShopState = {
  shopInfo: {},
  shopList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: ShopState) => {
    state.actionResult = '';
  },
  shopListClear: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopList = [];
    state.actionResult = '';
    state.isLoading = false;
    state.error = null;
  },
  list: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopList = payload.shopList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  info: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.isLoading = true;
    state.actionResult = 'INFO_REQ';
    state.error = '';
  },
  infoSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.isLoading = false;
    state.actionResult = 'INFO_OK';
    state.error = '';
  },
  infoFailure: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.isLoading = false;
    state.actionResult = 'INFO_ERR';
    state.error = payload.error;
  },
};

const slice = createSlice({
  name: 'shop',
  initialState: shopInitialState,
  reducers: reducers,
});

const shopList = createDraftSafeSelector(
  (state: ShopState) => state.shopList,
  shopList => {
    const mobileShopList = shopList.filter(shop => shop.useMobileYn  === 'Y' );

    return mobileShopList;
  }
);

const shopInfo = createDraftSafeSelector(
  (state: ShopState) => state.shopInfo,
  shopInfo => shopInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: ShopState) => state.actionResult,
  (state: ShopState) => state.isLoading,
  (state: ShopState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const shopSelector = {
  shopInfo: state => shopInfo(state[SHOP]),
  shopList: state => shopList(state[SHOP]),
  status: state => selectStatus(state[SHOP]),
};

export const SHOP = slice.name;
export const shopReducer = slice.reducer;
export const shopAction = slice.actions;
