import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface RatingInfo {
  brandId: string;
  categoryId: string;
  ratingId: string;
}

interface RatingState {
  ratingTagList: Array<>;
  ratingInfo: RatingInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: RatingState = {
  ratingList: [],
  ratingInfo: {},
  actionResult: '',
  currentActionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: RatingState) => {
    state.actionResult = '';
  },
  goodsInfoClear: (state: RatingState) => {
    state.ratingInfo = {};
  },
  goodsStateClear: (state: RatingState) => {
    state.ratingList = [];
    state.rationgInfo = {};
    state.actionResult = '';
    state.currentActionResult = '';
    state.isLoading = false;
    state.error = null;
  },
  ratingTagList: (state: RatingState, { payload }: PayloadAction<RatingState>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
    state.ratingTagList = [];
  },
  ratingTagListSuccess: (state: RatingState, { payload }: PayloadAction<RatingState>) => {
    state.ratingTagList = payload.tagList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  ratingTagListFailure: (state: RatingState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = payload;
    state.ratingTagList = [];
  },
};

const slice = createSlice({
  name: 'ratingTag',
  initialState,
  reducers,
});

const selectRatingTagList = createDraftSafeSelector(
  (state: RatingState) => state.ratingTagList,
  ratingTagList => ratingTagList,
);

const selectStatus = createDraftSafeSelector(
  (state: RatingState) => state.actionResult,
  (state: RatingState) => state.isLoading,
  (state: RatingState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const ratingTagSelector = {
  ratingTagList: state => selectRatingTagList(state[RATINGTAG]),
  status: state => selectStatus(state[RATINGTAG]),
};

export const RATINGTAG = slice.name;
export const ratingTagReducer = slice.reducer;
export const ratingTagAction = slice.actions;
